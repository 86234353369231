import React from 'react';
import { I18 } from '../../../../languages/I18';
import { useTranslate } from '../../../../i18translate/Hooks';
import { ProductInfo } from '../../Efficiency/Components/ProductInfoData';

const ProductInfoData = ({ pdsEfficiencyDetails }: any) => {
  const { t } = useTranslate();

  const pdsDetails = pdsEfficiencyDetails?.standardPoints.filter(
    (a: { speed: string; torque: string }) => {
      if (a.speed === '90' && a.torque === '100') return a;
    }
  );
  const pdsData = {
    title: t(I18.efficiency_losses_data_pds_heading),
    subText1: t(I18.efficiency_losses_data_pds_subtext1),
    subText2: t(I18.efficiency_losses_data_pds_subtext2),
    icon: './assets/icon/pdsFrame.svg',
    ieClass: pdsEfficiencyDetails?.iesClass.ies,
    ieClassLabel: t(I18.efficiency_losses_data_pds_ieclass_heading),
    efficiencyLabel: t(I18.efficiency_losses_data_pds_efficiency_label),
    efficiency:
      pdsDetails && pdsDetails?.length > 0 ? pdsDetails[0].efficiency : '0',
    description: t(I18.efficiency_losses_data_pds_description),
    descriptionStr: t(I18.efficiency_losses_data_pds_description_strong),
    absoluteLoss:
      pdsDetails && pdsDetails?.length > 0
        ? pdsDetails[0].absoluteLoss + ' ' + pdsDetails[0].absoluteLossUnit
        : '0',
    relativeLoss:
      pdsDetails && pdsDetails?.length > 0
        ? pdsDetails[0].relativeLoss + ' ' + pdsDetails[0].relativeLossUnit
        : '0',
    ratedPower:
      pdsEfficiencyDetails?.motorTechnicalData?.output +
      ' ' +
      pdsEfficiencyDetails?.motorTechnicalData?.outputUnit,
  };

  const cdmData = {
    title: t(I18.efficiency_losses_data_cdm_header),
    subText1: t(I18.efficiency_losses_data_cdm_subtext1),
    subText2: t(I18.efficiency_losses_data_cdm_subtext2),
    icon: './assets/icon/Drive-Frame.svg',
    ieClass: pdsEfficiencyDetails?.pdsDriveDetails.ieClass,
    ieClassLabel: t(I18.efficiency_losses_data_cdm_ieclass),
    efficiencyLabel: t(I18.efficiency_losses_data_cdm_efficiency_label),
    efficiency: pdsEfficiencyDetails?.pdsDriveDetails.efficiency,
    description: t(I18.efficiency_losses_data_cdm_description),
    descriptionStr: t(I18.efficiency_losses_data_cdm_description_strong),
    absoluteLoss: pdsEfficiencyDetails?.pdsDriveDetails.absoluteLoss,
    relativeLoss: pdsEfficiencyDetails?.pdsDriveDetails.relativeLoss,
    typecode: pdsEfficiencyDetails?.pdsDriveDetails.driveTypeCode,
    ratedApparentPower:
      pdsEfficiencyDetails?.pdsDriveDetails.nominalApparentPower,
    ratedCurrent: pdsEfficiencyDetails?.pdsDriveDetails.nominalCurrent,
  };
  const motorData = {
    title: t(I18.efficiency_losses_data_motor_header),
    subText1: t(I18.efficiency_losses_data_motor_subtext1),
    subText2: t(I18.efficiency_losses_data_motor_subtext2),
    icon: './assets/icon/Motor-Frame.svg',
    ieClass: pdsEfficiencyDetails?.motorTechnicalData?.efficiencyClass,
    ieClassLabel: t(I18.efficiency_losses_data_motor_ieclass),
    efficiencyLabel: t(I18.efficiency_losses_data_motor_efficiency_label),
    efficiency: pdsEfficiencyDetails?.motorTechnicalData?.efficiency,
    description: t(I18.efficiency_losses_data_motor_description),
    descriptionStr: t(I18.efficiency_losses_data_motor_description_strong),
    speed:
      pdsEfficiencyDetails?.motorTechnicalData?.speed +
      ' ' +
      pdsEfficiencyDetails?.motorTechnicalData?.speedUnit,
    typeDesignation: pdsEfficiencyDetails?.motorTechnicalData?.typeDesignation,
    ratedPower:
      pdsEfficiencyDetails?.motorTechnicalData?.output +
      ' ' +
      pdsEfficiencyDetails?.motorTechnicalData?.outputUnit,
  };
  return (
    <div className="disp-flex">
      <ProductInfo productInfo={pdsData} />
      <ProductInfo productInfo={cdmData} />
      <ProductInfo productInfo={motorData} />
    </div>
  );
};

export default ProductInfoData;
