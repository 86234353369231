import { CommonuxLoadingIndicator } from '@abb-motion-ux/commonux-web-components-react';

const CommonLoader = (props: {
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  indicatorType: 'circular' | 'linear' | undefined;
  progressType: 'indeterminate' | 'determinate' | undefined;
  progressValue: number | undefined;
  type: 'primary-red' | 'primary-black' | 'primary-blue' | undefined;
}) => {
  return (
    <div className="loader-container" data-testid={'project-loader'}>
      <CommonuxLoadingIndicator
        indicatorType={props.indicatorType}
        progressType={props.progressType}
        size={props.size}
        type={props.type}
        progressValue={props.progressValue}
      />
    </div>
  );
};

export default CommonLoader;
