import React, { PureComponent } from 'react';

interface OutsideClickHandlerProps {
  onClickOutside?: () => void;
  children?: any;
}

export default class OutsideClickHandler extends PureComponent<OutsideClickHandlerProps> {
  ref: React.RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);
    this.ref = React.createRef<HTMLDivElement>();
    this.handleOnClickOutside = this.handleOnClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOnClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOnClickOutside);
  }

  handleOnClickOutside(event: Event) {
    if (
      event.target instanceof HTMLElement &&
      this.ref &&
      this.ref.current &&
      !this.ref.current.contains(event.target)
    ) {
      if (this.props.onClickOutside) this.props.onClickOutside();
    }
  }

  render() {
    return (
      <div ref={this.ref} className="click-handler-container">
        {this.props?.children}
      </div>
    );
  }
}
