import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { initialResultData } from '../models/TransformerDefaultValues';
import { TransformerSelectionResultProps } from '../models/TransformerSelectionModel';
import CommonLoader from '../../../../components/Loader';
import ErrorAndWarningDialog from './ErrorAndWarningDialog';
import Button from '../../../../components/Form/Button';
import Icon from '../../../../components/Icon';
import { setCurrentApplication } from '../../store/WorkspaceReducer';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';

const TransformerSelectionResult = ({
  isLoading,
  handleRetryCalculate,
  isReselectLoading,
}: TransformerSelectionResultProps) => {
  const fetchedTransformerResult = useSelector(
    (state: RootState) =>
      state.workspace.transformerDetails.fetchedTransformerResult
  );

  const hasTransformerSizingErrorResponse = useSelector(
    (state: RootState) => state.workspace.hasTransformerSizingErrorResponse
  );

  let fetchedResultData =
    fetchedTransformerResult?.transformer?.transformerResult;

  const dispatch = useDispatch();
  const { t } = useTranslate();

  return (
    <>
      <div>
        {isLoading && (
          <div className="transformer-result-loader-container">
            <div>
              <CommonLoader
                size="small"
                indicatorType={'circular'}
                progressType={'indeterminate'}
                progressValue={50}
                type={'primary-black'}
              />
            </div>
            <div className="transformer-result-loader-message">
              {isReselectLoading
                ? 'Reselecting applications & Calculating supply data..... '
                : 'Calculating supply data...'}
            </div>
          </div>
        )}

        {hasTransformerSizingErrorResponse && !isLoading && (
          <div>
            <div className="transformer-result-error-card">
              <ErrorAndWarningDialog
                isError={true}
                isWarning={false}
                data={
                  'Failed to calculate supply data. Please retry the calculation after some time.'
                }
              />
            </div>
            <div className="button-retry">
              <Button type="primary-black" onClick={handleRetryCalculate}>
                {
                  <Icon
                    slot="icon"
                    size="small"
                    name="synchronize"
                    theme="light"
                  />
                }
                {'Retry calculating'}
              </Button>
            </div>
          </div>
        )}

        <div className="transormer-result-container">
          <div className="transformer-result-title">
            <div>{t(I18.motor_selection_result_selection_data_label)}</div>
            <Button
              type="primary-black"
              size="xsmall"
              onClick={() => {
                dispatch(setCurrentApplication(0));
              }}
            >
              {t(I18.goto_application_button)}
            </Button>
          </div>
          <div className="selection-data-table-container">
            <table className="selection-data-table">
              <tbody className="selection-data-table-rows">
                {fetchedResultData?.[0]?.selectionData?.length > 0
                  ? fetchedResultData?.map((data, idx) => {
                      if (idx >= 0) {
                        return data?.selectionData?.map((resultData) => {
                          return (
                            <tr
                              key={idx}
                              className="transformer-result-selection-data-table-row"
                            >
                              <th className="transformer-result-selection-data-table-name">
                                {resultData?.label}
                              </th>
                              <td className="transformer-result-selection-data-table-value">
                                {isLoading ? (
                                  <div className="catalogue-commonLoader">
                                    <CommonLoader
                                      size="xsmall"
                                      indicatorType={'circular'}
                                      progressType={'indeterminate'}
                                      progressValue={0}
                                      type={'primary-blue'}
                                    />
                                  </div>
                                ) : (
                                  resultData?.value ?? '-'
                                )}
                              </td>
                            </tr>
                          );
                        });
                      } else {
                        return null;
                      }
                    })
                  : initialResultData?.map((i, idx) => {
                      return (
                        <tr
                          key={idx}
                          className="transformer-result-selection-data-table-row"
                        >
                          <th className="transformer-result-selection-data-table-name">
                            {i?.label}
                          </th>
                          <td className="transformer-result-selection-data-table-value">
                            {isLoading ? (
                              <div className="catalogue-commonLoader">
                                <CommonLoader
                                  size="xsmall"
                                  indicatorType={'circular'}
                                  progressType={'indeterminate'}
                                  progressValue={0}
                                  type={'primary-blue'}
                                />
                              </div>
                            ) : (
                              i?.value ?? '-'
                            )}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransformerSelectionResult;
