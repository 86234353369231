import { CommonuxSelect } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';

interface SelectProps extends JSX.CommonuxSelect {
  className?: string;
  class?: string;
  slot?: string;
  onSelectChange?: (event: CustomEvent<any>) => void;
}

function Select(props: PropsWithChildren<SelectProps>) {
  const { onSelectChange } = props;

  const handleSelectChange = (event: CustomEvent<any>) => {
    if (!onSelectChange) return;

    onSelectChange(event);
  };

  return (
    <CommonuxSelect {...props} onSelectChange={handleSelectChange}>
      {props?.children}
    </CommonuxSelect>
  );
}

export default Select;
