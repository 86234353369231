import CommonLoader from '../../../../components/Loader';
import { DriveCatalogueDataProps } from '../models/MotorSelectionModels';

const DriveCatalogueData = ({
  catalogueTableData,
  isLoading,
  handleSelectedMethodLongText,
}: DriveCatalogueDataProps) => {
  return (
    <div className="catalogue-table-container">
      <table className="catalogue-table">
        <tbody>
          {catalogueTableData?.map((row, idx) => {
            if (
              row.name.toString().trim() === 'Selection' ||
              row.name.toString().trim() === 'Voltage' ||
              row.name.toString().trim() === 'Nominal power' ||
              row.name.toString().trim() === 'Nominal current' ||
              row.name.toString().trim() === 'Imax' ||
              row.name.toString().trim() === 'Ihd' ||
              row.name.toString().trim() === 'Frame type' ||
              row.name.toString().trim() === 'CDM IE class' ||
              row.name.toString().trim() === 'Stand-by loss'
            ) {
              return (
                <tr className="catalogue-row" key={`${(row?.name, idx)}`}>
                  <td className="catalogue-col">{row?.name ?? ''}</td>
                  <td className="catalogue-value-col">
                    {isLoading ? (
                      <div className="catalogue-commonLoader">
                        <CommonLoader
                          size="xsmall"
                          indicatorType={'circular'}
                          progressType={'indeterminate'}
                          progressValue={0}
                          type={'primary-blue'}
                        />
                      </div>
                    ) : row?.value !== '' ? (
                      row?.value + ' ' + row?.unit
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DriveCatalogueData;
