import React from 'react';
import {
  standardPointsHeaders,
} from '../Utils/PreDefinedLoadPoints';
import { MotorStandardPoints } from '../../models/EfficiencyModel';

interface TableProps {
  data: MotorStandardPoints[]
}
const StandardPointsTable = (props: TableProps) => {
  let stdPoints = props.data;
  return (
    <div className='set-table-overflow' data-rf="motor-losses-table">
      <table className="energy-losses-table standard-points-table">
        <thead>
          <tr>
            {standardPointsHeaders.map((data, idx) => {
              return (
                <th key={data} id={data + idx}>
                  {data}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {
            stdPoints && stdPoints.length > 0 && 
            stdPoints.map((val, index) => {
              let ind = index + 1;
              let imp = ind < 4 ? "*" + ind : ind
              return (
                <tr key={val.absoluteLoss} id={val.absoluteLoss + index}>
                  <td>{imp}</td>
                  <td>{val.speed+" "} %</td>
                  <td>{val.torque+" "} %</td>
                  <td>{val.absoluteLoss +" "+ val.absoluteLossUnit}</td>
                  <td>{val.relativeLoss +" "+ val.relativeLossUnit}</td>
                  <td>{val.efficiency +" "+ val.efficiencyUnit}</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export default StandardPointsTable;
