import { useEffect, useState } from 'react';
import Button from '../../../../components/Form/Button';
import Radio from '../../../../components/Form/Radio';
import RadioGroup from '../../../../components/Form/RadioGroup';
import Modal from '../../../../components/Modal';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import {
  ManualSelectionModalDriveProps,
  RecommendedDrives,
} from '../models/MotorSelectionModels';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { selectDrive } from '../../store/WorkspaceReducer';
import ManualSelectionConfirmationModal from './ManualSelectionConfirmationModal';
import CommonLoader from '../../../../components/Loader';

const ManualSelectionModalDrive = ({
  modalOpen,
  onClose,
  onSelectClick,
  isManualSelectionLoading,
}: ManualSelectionModalDriveProps) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const [currentSelectedRowIndex, setCurrentRowIndex] = useState(0);
  const [isDriveManuallySelected, setIsDriveManuallySelected] = useState(false);
  const [driveDetailsSelected, setDriveDetailsSelected] = useState<
    RecommendedDrives | undefined
  >(undefined);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const manualSelectionDriveData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.manualSelectionDriveData
  );

  const driveTypeCode = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.productTypeCode.driveTypeCode
  );

  const [dimensioningDataResult] = useState<any>(undefined);

  const handleSelectClick = () => {
    setIsConfirmationModalOpen(true);
  };

  useEffect(() => {
    if (isDriveManuallySelected) {
      onSelectClick('Drive', driveDetailsSelected?.index ?? '');
      setIsDriveManuallySelected(false);
    }
  }, [isDriveManuallySelected]);

  useEffect(() => {
    if (dimensioningDataResult) {
      dispatch(selectDrive(dimensioningDataResult));
    }
  }, [dimensioningDataResult, dispatch]);

  const handleConfirmationSelectClick = () => {
    setIsDriveManuallySelected(true);
    setDriveDetailsSelected(
      manualSelectionDriveData?.drivesList?.recommendedDrives[
        currentSelectedRowIndex
      ]
    );
    onClose();
  };

  const handleDriveRowSelectClick = (rowIndex: number) => {
    if (rowIndex !== currentSelectedRowIndex) {
      setCurrentRowIndex(rowIndex);
    }
  };

  const onConfirmationModalClose = () => {
    setIsConfirmationModalOpen(false);
    onClose();
  };

  useEffect(() => {
    //get the motordetails and in that number value that object is to be set here
    if (
      currentSelectedRowIndex === 0 &&
      manualSelectionDriveData?.drivesList?.recommendedDrives &&
      manualSelectionDriveData?.drivesList?.recommendedDrives?.length > 0
    ) {
      let driveSelected =
        manualSelectionDriveData?.drivesList?.recommendedDrives.filter(
          (drive, index) =>
            drive?.type === driveTypeCode && drive?.number === '0'
        );
      driveSelected.length > 0 &&
        setCurrentRowIndex(parseInt(driveSelected[0]?.index) + 1);
    }
  }, [
    currentSelectedRowIndex,
    manualSelectionDriveData?.drivesList?.recommendedDrives,
    driveTypeCode,
  ]);

  return (
    <>
      <Modal
        open={modalOpen}
        onModalClose={onClose}
        modalTitle={'Manual selection'}
        showCloseButton={true}
        type="default"
        showHederSeparator="true"
        show-footer-separator="true"
        backdropClick={false}
        className="manual-selection-modal"
      >
        <div
          id="manual-selection-drive-modal-content"
          className="manual-selection-drive-modal-content"
        >
          {isManualSelectionLoading && (
            <div className="form-loader">
              <CommonLoader
                size="xlarge"
                indicatorType={'circular'}
                progressType={'indeterminate'}
                progressValue={50}
                type={'primary-black'}
              />
            </div>
          )}
          <table className="manual-selection-table">
            <RadioGroup value={currentSelectedRowIndex.toString()}>
              <tbody>
                {manualSelectionDriveData?.drivesList?.recommendedDrives?.map(
                  (row, rowIndex) => {
                    return (
                      <tr
                        className={
                          currentSelectedRowIndex === rowIndex
                            ? 'tr-selected'
                            : ''
                        }
                      >
                        <td>
                          {rowIndex !== 0 && (
                            <Radio
                              value={rowIndex.toString()}
                              onRadioFocus={() =>
                                handleDriveRowSelectClick(rowIndex)
                              }
                            ></Radio>
                          )}
                        </td>
                        <td>{row.number}</td>
                        <td>{row.type}</td>
                        <td>{row.pnNominal}</td>
                        <td>{row.inNominal}</td>
                        <td>{row.inRequired}</td>
                        <td>{row.inAvailable}</td>
                        <td>{row.inAvailableMargin}</td>
                        <td>{row.imaxRequired}</td>
                        <td>{row.imaxAvailable}</td>
                        <td>{row.imaxAvailableMargin}</td>
                        <td>{row.selectionMethod}</td>
                        <td>{row.tempMargin}</td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </RadioGroup>
          </table>
        </div>
        <div className="report-graph-modal-footer" slot="footer">
          <Button
            type="secondary"
            size="small"
            onClick={onClose}
            className="create-project-cancel-button"
          >
            {t(I18.motor_drive_selection_modal_cancel_button)}
          </Button>
          <Button type="primary-black" size="small" onClick={handleSelectClick}>
            {'Select'}
          </Button>
        </div>
      </Modal>
      <ManualSelectionConfirmationModal
        modalOpen={isConfirmationModalOpen}
        onClose={onConfirmationModalClose}
        onSelectClick={handleConfirmationSelectClick}
        type={'drive'}
      />
    </>
  );
};

export default ManualSelectionModalDrive;
