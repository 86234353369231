import { PropsWithChildren } from 'react';
import { CommonuxDialog } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';

interface ModalProps extends JSX.CommonuxDialog {
  open: boolean | undefined;
  onModalClose: (() => void) | undefined;
  slot?: string;
  className?: string;
  type?: 'default' | 'discreet';
  onBack?: (event: any) => void;
  showHederSeparator?: string;
  backdropClick?: boolean;
}

function Modal(props: PropsWithChildren<ModalProps>) {
  const { onBack, type = 'default', showHederSeparator, backdropClick } = props;

  if (props.open) {
    document.body.style.overflow = 'hidden';
  } else if (!props.open) {
    document.body.style.overflow = 'unset';
  }

  const handleModalBack = (event: CustomEvent<any>) => {
    if (!onBack) return;
    document.body.style.overflow = 'unset';
    onBack(event);
  };

  const handleModalClose = () => {
    document.body.style.overflow = 'unset';
    props.onModalClose?.();
  };

  return (
    <CommonuxDialog
      {...props}
      onModalClose={handleModalClose}
      open={props.open ?? false}
      onModalBack={handleModalBack}
      show-header-separator={showHederSeparator}
      backdropClick={backdropClick}
      data-rf={props.className}
      type={type}
    >
      {props?.children}
    </CommonuxDialog>
  );
}

export default Modal;
