export enum MotorType {
  existinginduction = 0,
  iec34motor = 1,
  marinemotors = 6,
  nemamotors = 7,
  flameproof = 9,
  increasedsafetyexec = 21,
  dustignitionprotection = 11,
  synrm = 19,
  synrmwatercooled = 24,
  synrmincreasedsafety = 22,
  synrmdustignitionproof = 23,
}

export enum MarineClassification {
  NotSpecified = 0,
  ABS = 1,
  BV = 2,
  CCS = 3,
  DNV = 5,
  GL = 6,
  KR = 7,
  LR = 8,
  NK = 9,
  RINA = 10,
  RS = 11,
}

export enum MEPS {
  NotSpecified = 0,
  AustraliaHEMEPS = 1,
  AustraliaMEPS = 2,
  BrazilNBRIR3 = 3,
  CanadaNRCAN = 4,
  ChinaMEPS = 5,
  EUMEPS = 6,
  IndiaMEPS = 7,
  KoreaMEPS = 8,
  SaudiArabiaMEPS = 9,
  SingaporeMEPS = 10,
  USIHP = 11,
}

export enum FrameMaterial {
  NotSpecified = 0,
  Aluminum = 1,
  CastIron = 2,
}

export enum Family {
  NotSpecified = 0,
  M3BP = 1,
  M3AA = 2,
  M2QAP = 3,
  M2BAX = 4,
  M2BAF = 5,
  M2BA = 6,
  M3KP = 7,
  M3JP = 8,
  M3GP = 9,
}

export enum Polenumber {
  Automatic = 0,
  Two = 2,
  Four = 4,
  Six = 6,
  Eight = 8,
  Ten = 10,
  Twelve = 12,
}

export enum Efficiency {
  NotSpecified = 0,
  IE1 = 1,
  IE2 = 2,
  IE3 = 3,
  IE4 = 4,
}

export enum Winding {
  StandardAndRestamp = 0,
  AllWinding = 1,
}

export enum Design {
  NotSpecified = 0,
  CENELEC = 1,
  HighOutput = 2,
}

export enum Connection {
  NotSpecified = 0,
  Y = 1,
  D = 2,
}

export enum IPclass {
  IP55 = 2,
}

export enum ICclass {
  IC411SelfVentilated = 0,
  IC416ForceVentilated = 1,
}

export enum IMclass {
  IM1001B3Foot = 0,
  IM1011V5Foot = 1,
  IM1031V6Foot = 2,
  IM1051B6Foot = 3,
  IM1061V7Foot = 4,
  IM1071V8Foot = 5,
  IM3001B5Flange = 6,
  IM3011V1Flange = 7,
  IM3031V3Flange = 8,
  IM3601B14Flange = 9,
  IM3611V18Flange = 10,
  IM3631V19Flange = 11,
}

export enum MaxSpeedRule {
  Standard = 100,
  MetalFan = 200,
}

export enum TempRiseClass {
  NotSpecified = 0,
}

export const LoadTypeEnum = {
  ConstantPower: { value: '0', label: 'Constant power' },
  ConstantTorque: { value: '1', label: 'Constant torque' },
  ConstantPowerTorque: { value: '2', label: 'Const. torque/power' },
  PumpFanLoad: { value: '3', label: 'Pump/fan load' },
};

export const LoadTypeReportEnum = {
  '0': 'Constant power',
  '1': 'Constant torque',
  '2': 'Const. torque/power',
  '3': 'Pump/fan load',
};

export const OverloadLoadTypeReportEnum = {
  '2': 'One-time at start',
  '0': 'Simple Cyclic',
  '1': 'Multiform Cyclic',
};

export const ProjectTypeEnum = {
  '3': 'Industrial drives',
  '4': 'Industrial multidrives',
  '5': 'General purpose drives',
  '6': 'Drives for HVAC and Water',
  '7': 'Machinery drives',
  '8': 'Single drive combination',
};
export const ICClassEnum = {
  '0': 'IC411 self ventilated',
  '1': 'IC416 force ventilated',
};
export const IMClassEnum = {
  '0': 'IM1001, B3(foot)',
  '1': 'IM1011, V5(foot)',
  '2': 'IM1031, V6(foot)',
  '3': 'IM1051, B6(foot)',
  '4': 'IM1061, V7(foot)',
  '5': 'IM1071, V8(foot)',
  '6': 'IM3001, B5(flange)',
  '7': 'IM3011, V1(flange)',
  '8': 'IM3031, V3(flange)',
  '9': 'IM3601, B14(flange)',
  '10': 'IM3611, V18(flange)',
  '11': 'IM3631, V19(flange)',
};
