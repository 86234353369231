import { useDispatch, useSelector } from 'react-redux';
import { useGetApplicationsQuery } from '../../services/APISelectionService';
import { RootState } from '../../../../store/rootReducer';
import CommonLoader from '../../../../components/Loader';
import { Application } from '../../models/SelectionModel';
import { setApplicationType } from '../../store/WorkspaceReducer';

interface ApplicationSelectionProps {
  onApplicationCardClick: (data: string) => void;
}

const ApplicationSelection = (props: ApplicationSelectionProps) => {
  const dispatch = useDispatch();
  const industryId = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      .selectionDetails.industryId;
  });

  const { data: ApplicationData, isLoading } = useGetApplicationsQuery({
    locale: 'en-US',
    industryId,
  });

  const handleIndustryClick = (application: Application) => {
    dispatch(setApplicationType({
      applicationId: application.id,
      applicationName:application.name}));
    props.onApplicationCardClick(application.name);
  };

  if (isLoading) {
    return (
      <CommonLoader
        size="xlarge"
        indicatorType={'circular'}
        progressType={'indeterminate'}
        progressValue={50}
        type={'primary-black'}
      />
    );
  }

  return (
    <div>
      <span
        className="industry-selection-header"
        data-rf="application-selection-header"
      >
        Choose your application
      </span>
      <div className="industry-container">
        {ApplicationData?.slice()
          .sort((a, b) => a.rank - b.rank)
          .map((application) => {
            return (
              <div
                key={application.id}
                data-rf={application.name + '-application'}
                className="industry-details-container"
                onClick={() => handleIndustryClick(application)}
              >
                <div className="industry-image-container">
                  <img
                    src={application.icon?.url}
                    alt={application.title}
                    title={application.name}
                  />
                </div>

                <div className="industry-label-container">
                  <span className="industry-label">{application.name}</span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ApplicationSelection;
