import React from 'react';
import Card from '../../../components/Card';
import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';
import ConfirmationModal from './ConfirmationModal';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import { generateKey } from '../../../utils/GeneralUtils';
import { useSession } from '../../../utils/Auth';

function SelectionCards() {
  const [isConfirmationModalOpen, setConfirmationModalOpen] =
    React.useState(false);
  const [modalName, setModalName] = React.useState('');
  const { t } = useTranslate();
  const { isMobile } = useDeviceDetect();
  const { session } = useSession();

  const onConfirmationModalClose = () => {
    setConfirmationModalOpen(false);
  };

  const selectionCards = [
    {
      onClick: () => {
        setConfirmationModalOpen(true);
        setModalName(t(I18.modal_drives_motors_title));
      },
      imgsrc: 'dms.svg',
      alt: t(I18.home_selection_tab_selection_card),
      title: t(I18.home_selection_tab_selection_card),
      content: t(I18.home_selection_card_selection_description),
    },
    {
      onClick: () => {
        setConfirmationModalOpen(true);
        setModalName(t(I18.modal_drive_size_title));
      },
      imgsrc: 'drivesize.svg',
      alt: t(I18.home_selection_tab_dimension_card),
      title: t(I18.home_selection_tab_dimension_card),
      content: t(I18.home_selection_card_dimension_description),
    },
    {
      onClick: () => {
        setConfirmationModalOpen(true);
        setModalName(t(I18.modal_drive_upgrade_title));
      },
      imgsrc: 'driveupgrade.svg',
      alt: t(I18.home_selection_tab_upgrade_card),
      title: t(I18.home_selection_tab_upgrade_card),
      content: t(I18.home_selection_card_upgrade_description),
    },
    {
      onClick: () => {
        setConfirmationModalOpen(true);
        setModalName(t(I18.modal_echo_design_title));
      },
      imgsrc: 'ecodesign.svg',
      alt: t(I18.home_selection_tab_efficiency_card),
      title: t(I18.home_selection_tab_efficiency_card),
      content: t(I18.home_selection_card_efficiency_description),
    },
    {
      onClick: () => {
        setConfirmationModalOpen(true);
        setModalName(t(I18.modal_harmonic_estimator_title));
      },
      imgsrc: 'hme.svg',
      alt: t(I18.home_selection_tab_hme_card),
      title: t(I18.home_selection_tab_hme_card),
      content: t(I18.home_selection_card_hme_description),
    },
    {
      onClick: () => {
        setConfirmationModalOpen(true);
        setModalName(t(I18.modal_energy_save_title));
      },
      imgsrc: 'energysave.svg',
      alt: t(I18.home_selection_tab_energysave_card),
      title: t(I18.home_selection_tab_energysave_card),
      content: t(I18.home_selection_card_energysave_description),
    },
  ];

  const modalInfo = [
    {
      modalTitle: t(I18.modal_drives_motors_title),
      modalContentText: t(I18.modal_drives_motors_description),
      onClick: () => {
        window.open('https://selector.drivesmotors.abb.com/');
      },
      dataRf: 'dms-tool-open-modal',
    },
    {
      modalTitle: t(I18.modal_drive_size_title),
      modalContentText: t(I18.modal_drive_size_description),
      onClick: () => {
        window.open('https://www.drivesize.abb-drives.com/');
      },
      dataRf: 'drivesize-tool-open-modal',
    },
    {
      modalTitle: t(I18.modal_drive_upgrade_title),
      modalContentText: t(I18.modal_drive_upgrade_description),
      onClick: () => {
        window.open('https://driveupgrade.abb-drives.com/');
      },
      dataRf: 'driveupgrade-tool-open-modal',
    },
    {
      modalTitle: t(I18.modal_echo_design_title),
      modalContentText: t(I18.modal_echo_design_description),
      onClick: () => {
        window.open('https://ecodesign.drivesmotors.abb.com/');
      },
      dataRf: 'ecodesign-tool-open-modal',
    },
    {
      modalTitle: t(I18.modal_harmonic_estimator_title),
      modalContentText: t(I18.modal_harmonic_estimator_description),
      onClick: () => {
        window.open('https://harmonicestimator.motion.abb.com/');
      },
      dataRf: 'harmonicestimator-tool-open-modal',
    },
    {
      modalTitle: t(I18.modal_energy_save_title),
      modalContentText: t(I18.modal_energy_save_description),
      onClick: () => {
        window.open('https://energysave.abb-drives.com/');
      },
      dataRf: 'energysave-tool-open-modal',
    },
  ];

  const modalItem =
    modalInfo.find((modal) => modal.modalTitle === modalName) ?? modalInfo[0];

  return (
    <div>
      <div className="card-container">
        {selectionCards.map((selectionCard, idx) => {
          if (!session && selectionCard.imgsrc === 'hme.svg') {
            return null;
          } else
            return (
              <Card
                key={(selectionCard.title, idx)}
                className="option-card"
                size={isMobile ? 'default' : 'larger-padding'}
                onClick={selectionCard.onClick}
                type="discreet"
                data-rf={`${selectionCard?.title?.toLocaleLowerCase()}-selection-card`}
              >
                <div slot="header" className="card-header">
                  <img src={selectionCard.imgsrc} alt={selectionCard.alt} />
                  <div className="option-card-header">
                    {selectionCard.title}
                  </div>
                </div>
                <div slot="contents" id="content">
                  {selectionCard.content}
                </div>
              </Card>
            );
        })}
      </div>
      <div
        key={generateKey(modalItem.modalTitle)}
        id="open-modal"
        data-rf={modalItem.dataRf}
      >
        <ConfirmationModal
          modalOpen={isConfirmationModalOpen}
          onClose={onConfirmationModalClose}
          modalTitle={modalItem.modalTitle}
          modalContentText={modalItem.modalContentText}
          onClick={modalItem.onClick}
        />
      </div>
    </div>
  );
}

export default SelectionCards;
