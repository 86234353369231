import { c30kPerPI } from '../../../../utils/Constants';
import {
  LoadDataMotorSelection,
  RMSValues,
  DutyCycleMotorLoadResult,
} from './DutyCycleModels';
import { Dimension, MotorLoadData } from './MotorSelectionModels';

export const getNCurrent = (
  motorLoadData: MotorLoadData,
  motorRmsValues: RMSValues,
  selectMotorResult: Dimension
) => {
  let nCurrent: number[] = [];
  let rms10Value = parseFloat(motorRmsValues.RMS10) / 100;
  let unitLoadOLBase = parseFloat(motorLoadData?.olBaseInput) / rms10Value;
  let unitOLMax = parseFloat(motorLoadData?.olMaxInput) / rms10Value;
  let nbp = (unitOLMax / unitLoadOLBase) * parseFloat(motorLoadData?.nMax);
  let resultSpeed = parseFloat(
    selectMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
      ?.motorUnit?.results?.[8]?.value
  );
  nCurrent[0] = parseFloat(motorLoadData?.nBase);
  switch (motorLoadData?.loadType) {
    case '0':
    case '2':
      nCurrent[1] = parseFloat(motorLoadData?.nMax);
      nCurrent[2] = nbp;
      if (parseFloat(motorLoadData?.nMax) >= resultSpeed) {
        nCurrent[3] = resultSpeed;
      }
      break;
    case '1':
    case '3':
    case '4':
      nCurrent[1] = 0;
      nCurrent[2] = 0;
      if (parseFloat(motorLoadData?.nBase) >= resultSpeed) {
        nCurrent[3] = resultSpeed;
      }
  }
  return nCurrent;
};

export const getMaxPowerItem = (
  dutyCycleMotorRow: LoadDataMotorSelection,
  nCurrent: number[],
  doLoop: boolean,
  motorLoadData: MotorLoadData,
  motorRmsValues: RMSValues
): DutyCycleMotorLoadResult => {
  let testPoints = 0;
  let dimTorque = 0;
  let result = 0;
  let updatedMotorLoad: MotorLoadData = { ...motorLoadData };
  let rms10Value = parseFloat(motorRmsValues.RMS10) / 100;
  if (parseFloat(motorLoadData?.nBase) > 0) {
    dimTorque =
      (c30kPerPI * parseFloat(motorLoadData?.power)) /
      parseFloat(motorLoadData?.nBase);
    testPoints = 4;
    //to do:olmin= dutyCycleMotorRow?.load?.toString();

    updatedMotorLoad.olBaseInput = dutyCycleMotorRow?.load?.toString();
    updatedMotorLoad.olMaxInput = dutyCycleMotorRow?.load?.toString();
    if (
      !(dutyCycleMotorRow.minSpeed === 0 && dutyCycleMotorRow.maxSpeed === 0)
    ) {
      nCurrent[4] = dutyCycleMotorRow?.minSpeed;
      nCurrent[5] = dutyCycleMotorRow?.maxSpeed;
      testPoints = 5;
    }

    if (doLoop) {
      result = testPoints;
    } else {
      // to do:in future
    }
  }

  updatedMotorLoad.olBaseInput = (
    dutyCycleMotorRow?.load / rms10Value
  ).toString();
  updatedMotorLoad.olMaxInput = (
    dutyCycleMotorRow?.load / rms10Value
  ).toString();
  let testMotorLoadResult: DutyCycleMotorLoadResult = {
    result: result,
    motorloadData: updatedMotorLoad,
    nCurrent: nCurrent,
  };
  return testMotorLoadResult;
};
