import { CommonuxInput } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';

interface InputProps extends JSX.CommonuxInput {
  className?: string;
  id?: string;
  valid?: boolean;
  dataRf?: string;
}

function Input(props: PropsWithChildren<InputProps>) {
  const { valid, dataRf } = props;
  return (
    <CommonuxInput {...props} valid={valid} data-rf={dataRf}>
      {props?.children}
    </CommonuxInput>
  );
}

export default Input;
