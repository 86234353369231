import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';

const ProjectData = () => {
  const applicationDetails = useSelector((state: RootState) => state.workspace);

  return (
    <div id="pdf-section">
      <div className="segment-container">
        <div id="project-details" className="segment-header">
          2. Project data
        </div>
        <div className="project-data-container">
          <div className="project-data-content-container">
            <div className="segment-title">
              <div>Mains</div>
            </div>
            <div className="segment-content">
              <div>Primary Voltage</div>
              <div>
                {applicationDetails.transformerDetails.selectedTransformerDatas
                  .transformerInput.primaryVoltage ?? '-'}{' '}
                V
              </div>
            </div>
            <div className="segment-content">
              <div>Secondary Voltage</div>
              <div>
                {applicationDetails.transformerDetails.selectedTransformerDatas
                  .transformerInput.secondaryVoltage ?? '-'}{' '}
                V
              </div>
            </div>
            <div className="segment-content">
              <div>Frequency</div>
              <div>
                {applicationDetails.transformerDetails.selectedTransformerDatas
                  .transformerInput.frequency ?? '-'}{' '}
                Hz
              </div>
            </div>
            <div className="segment-content">
              <div>Winding</div>
              <div>
                {applicationDetails.transformerDetails.selectedTransformerDatas
                  .transformerInput.trafoWindingNo ?? '-'}
              </div>
            </div>
            <div className="segment-content">
              <div>Transformer power</div>
              <div>
                {applicationDetails?.transformerDetails
                  ?.fetchedTransformerResult?.transformer
                  ?.transformerResult?.[0]?.selectionData?.[0]?.value ??
                  '-'}{' '}
                KVA
              </div>
            </div>
          </div>
          <div className="project-data-content-container">
            <div className="segment-title">
              <div>Ambient conditions</div>
            </div>
            <div className="segment-content">
              <div>Altitude</div>
              <div>
                {applicationDetails?.ambientConditionValues.altitude ?? '-'} m
              </div>
            </div>
            <div className="segment-content">
              <div>Motor temperature</div>
              <div>
                {applicationDetails?.ambientConditionValues.motorTemparature ??
                  '-'}{' '}
                deg
              </div>
            </div>
            <div className="segment-content">
              <div>Drive temperature</div>
              <div>
                {applicationDetails?.ambientConditionValues.driveTemparature ??
                  '-'}{' '}
                deg
              </div>
            </div>
            {/* <div className="segment-content">
              <div>Transformer  temperature</div>
              <div>
                {applicationDetails?.transformerDetails.fetchedTransformerResult?.transformer?.transformerResult?.[0]?.selectionData?.[0]?.value ??
                  '-'}{' '}
                deg
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectData;
