import React from 'react';
import {
  PDSAbsoluteLossRange,
  PDSEfficiencyRange,
  pdsPartloadMaxValues,
} from '../Utils/PDSCommon';
import '../Styles/LossesChart.scss';
import _ from 'lodash';
import { StandardPoints } from '../../models/EfficiencyModel';
import { D3 } from '../../../../components/D3';
import { renderToString } from 'react-dom/server';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';

const STD_SHAPE = 'square';
const PRE_SHAPE = 'circle';
const PART_LOAD_SHAPE = 'diamond';
const STD_SHAPE_COLOR = 'rgba(27, 122, 194, 1)';
const PRE_SHAPE_COLOR = 'rgba(237, 87, 57, 1)';
const PART_LOAD_SHAPE_COLOR = 'rgba(181, 170, 13, 1)';

interface ICoordinate {
  index?: number;
  x: number;
  y: number;
  efficiency?: string;
  efficiencyUnit?: string;
  absoluteLoss?: string;
  absoluteLossUnit?: string;
  shape?: string;
  tooltipContent?: string;
  pointLabel?: string;
  pointColor?: string;
  pointLabelFontSize?: string;
  customShape?: any;
}

const isWithinRange = (val?: any, min?: any, max?: any) => {
  const newVal = Number(val);
  if (
    isNaN(newVal) ||
    newVal === undefined ||
    newVal === null ||
    newVal > max ||
    newVal < min
  ) {
    return false;
  } else {
    return true;
  }
};

const getTooltip = (point: StandardPoints, shape: string) => {
  const ChartToolColorClass =
    shape === STD_SHAPE
      ? 'chart-legend-color-tooltip'
      : shape === PART_LOAD_SHAPE
      ? 'diamond-narrow-tooltip'
      : shape === PRE_SHAPE
      ? 'circle-toolTip'
      : '';
  const ChartToolContentClass =
    shape === STD_SHAPE
      ? 'chart-legend-content'
      : shape === PART_LOAD_SHAPE
      ? 'diamond-tool-content'
      : shape === PRE_SHAPE
      ? 'circle-tool-content'
      : '';
  const absoluteLoss =
    point.absoluteLoss && point.absoluteLoss !== '-' ? (
      <span
        style={
          isWithinRange(
            point.absoluteLoss,
            PDSAbsoluteLossRange.Min,
            PDSAbsoluteLossRange.Max
          )
            ? {}
            : { color: '#F03040' }
        }
      >
        {point.absoluteLoss + point.absoluteLossUnit}
      </span>
    ) : (
      <>-</>
    );
  const efficiency =
    point.efficiency && point.efficiency !== '-' ? (
      <span
        style={
          isWithinRange(
            point.efficiency,
            PDSEfficiencyRange.Min,
            PDSEfficiencyRange.Max
          )
            ? {}
            : { color: '#F03040' }
        }
      >
        {point.efficiency + point.efficiencyUnit}
      </span>
    ) : (
      <>-</>
    );

  return `  
  <div class="chart-legends-tool">
    <div class= ${ChartToolColorClass}></div>
    <div class="${ChartToolContentClass}">
      <div class="content1">Speed ${point.speed}%; Torque ${point.torque}%</div>	
      <div class="content2">Absolute losses ${renderToString(
        absoluteLoss
      )}</div>	
      <div class="content3">Efficiency  ${renderToString(efficiency)}</div>
    </div>
</div>`;
};

const getPointLabel = (point: StandardPoints) => {
  const efficiency =
    point.efficiency && point.efficiency !== '-'
      ? point.efficiency + point.efficiencyUnit
      : '-';
  return efficiency;
};

// Partload shape(Diamond)
const partLoadShape = {
  draw: function (context: any, size: any) {
    context.moveTo(0, -size / 11);
    context.lineTo(size / 11, 0);
    context.lineTo(0, size / 11);
    context.lineTo(-size / 11, 0);
    context.lineTo(0, -size / 11);
    context.closePath();
  },
};

const getSpeed = (shape: string, speed: number, fZero?: string) => {
  if (shape === STD_SHAPE) {
    if (speed === 0 && !isNaN(Number(fZero))) {
      return Number(fZero);
    }
  }
  return speed;
};

const formatPointsForChart = (
  points: StandardPoints[],
  shape: string,
  type: string,
  count: number,
  nominalFrequency?: string
): ICoordinate[] => {
  return points.map((point, index) => ({
    index: count + index + 1,
    shape,
    x: getSpeed(shape, parseInt(point.speed), nominalFrequency),
    y: parseInt(point.torque),
    efficiency: point.efficiency,
    efficiencyUnit:
      point.efficiency && point.efficiency !== '-' ? point.efficiencyUnit : '',
    absoluteLoss: point.absoluteLoss,
    absoluteLossUnit:
      point.absoluteLoss && point.absoluteLoss !== '-'
        ? point.absoluteLossUnit
        : '',
    tooltipContent: getTooltip(point, shape),
    pointLabel: getPointLabel(point),
    pointLabelFontSize: type === 'small' ? '10' : '14',
    pointColor:
      shape === STD_SHAPE
        ? STD_SHAPE_COLOR
        : shape === PART_LOAD_SHAPE
        ? PART_LOAD_SHAPE_COLOR
        : shape === PRE_SHAPE
        ? PRE_SHAPE_COLOR
        : '',
    customShape: shape === PART_LOAD_SHAPE ? partLoadShape : '',
  }));
};

const getArrayForRange = (
  primaryArray: any[],
  maxValue: number,
  unitScale: number
) => {
  return primaryArray.filter((element) => element <= maxValue + unitScale);
};
interface IPDSLossChart {
  partloadPoints?: StandardPoints[];
  standardPoints?: StandardPoints[];
  preDefinedPoints?: StandardPoints[];
  fZero?: string;
  loading?: boolean;
  type: 'small' | 'large';
  resultsLoading?: boolean;
  height: number;
  width?: number;
  id?: string;
}

export const PDSLossesChart: React.FC<IPDSLossChart> = ({
  standardPoints,
  preDefinedPoints,
  partloadPoints,
  fZero,
  loading,
  type,
  resultsLoading,
  height,
  width,
  id,
}) => {
  const isReportModalOpen = useSelector(
    (state: RootState) => state.workspace.isReportModalOpen
  );
  const data1Color = STD_SHAPE_COLOR;
  const standardPointsSorted = standardPoints
    ?.slice()
    .sort(
      (point1, point2) =>
        parseInt(point1.speed) - parseInt(point2.speed) ||
        parseInt(point1.torque) - parseInt(point2.torque)
    );
  const preDefinedPointsSorted = preDefinedPoints
    ?.slice()
    .sort(
      (point1, point2) =>
        parseInt(point1.speed) - parseInt(point2.speed) ||
        parseInt(point1.torque) - parseInt(point2.torque)
    );
  const formattedStandardPoints = standardPoints
    ? formatPointsForChart(
        standardPointsSorted ? standardPointsSorted : [],
        STD_SHAPE,
        type,
        0,
        fZero
      )
    : [];

  const formattedPreDefinedPoints = preDefinedPoints
    ? formatPointsForChart(
        preDefinedPointsSorted ? preDefinedPointsSorted : [],
        PRE_SHAPE,
        type,
        8
      )
    : [];
  const formattedPartloadPoints = partloadPoints
    ? formatPointsForChart(partloadPoints, PART_LOAD_SHAPE, type, 0)
    : [];

  let coordinates = formattedPreDefinedPoints
    ? formattedStandardPoints?.concat(formattedPreDefinedPoints)
    : formattedStandardPoints;

  coordinates = formattedPartloadPoints
    ? coordinates?.concat(formattedPartloadPoints)
    : coordinates;

  const maxSpeedValue =
    _.maxBy(coordinates, (coordinate) => coordinate.x)?.x || 100;
  const maxTorqueValue =
    _.maxBy(coordinates, (coordinate) => coordinate.y)?.y || 100;

  const outOfRangeSpeedTicks = [
    0, 20, 40, 60, 80, 100, 120, 140, 160, 180, 200,
  ];
  const outOfRangeTorqueTicks = [0, 25, 50, 75, 100, 125, 150];
  const outOfRangeSpeedGridLinesDark = [25, 50, 100, 200];
  const outOfRangeTorqueGridLinesDark = [25, 50, 100, 125, 150];
  const outOfRangeSpeedGridLinesLight = [0, 20, 40, 60, 80, 120, 140, 160, 180];
  const outOfRangeTorqueGridLinesLight = [75, 150];

  return (
    <div
      className="scatter-chart-container"
      style={{ height: `${isReportModalOpen ? `${height}px` : 'initial'}` }}
    >
      <div className="chart-top-section">
        <div className="chart-legends">
          <div>
            <div
              style={{ backgroundColor: data1Color }}
              className="chart-legend-color"
            ></div>
            Standard Points
          </div>
          {/* <div>
                        <div
                            style={{ backgroundColor: PRE_SHAPE_COLOR }}
                            className="circle"
                        ></div>
                        Predefined Points
                    </div> */}
          {formattedPartloadPoints.length > 0 ? (
            <div>
              <div className="diamond-narrow"></div>
              Partial Load Points
            </div>
          ) : null}
        </div>
      </div>
      {!loading && !resultsLoading && (
        <D3
          coordinates={coordinates ? coordinates : []}
          xAxisLabel={'Speed (%)'}
          yAxisLabel={'Torque (%)'}
          id={id ? id : 'PDS-chart'}
          height={
            maxTorqueValue > 100
              ? (height * pdsPartloadMaxValues.torque) / 100
              : height
          }
          width={width}
          isPDS={true}
          xAxisTicks={
            maxSpeedValue > 100
              ? getArrayForRange(outOfRangeSpeedTicks, maxSpeedValue, 20)
              : [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
          }
          yAxisTicks={
            maxTorqueValue > 100
              ? getArrayForRange(outOfRangeTorqueTicks, maxTorqueValue, 25)
              : [0, 25, 50, 75, 100]
          }
          darkGridLines={{
            xAxis:
              maxSpeedValue > 100
                ? getArrayForRange(
                    outOfRangeSpeedGridLinesDark,
                    maxSpeedValue,
                    20
                  )
                : [25, 50, 100],
            yAxis:
              maxTorqueValue > 100
                ? getArrayForRange(
                    outOfRangeTorqueGridLinesDark,
                    maxTorqueValue,
                    25
                  )
                : [25, 50, 100],
          }}
          lightGridLines={{
            xAxis:
              maxSpeedValue > 100
                ? getArrayForRange(
                    outOfRangeSpeedGridLinesLight,
                    maxSpeedValue,
                    20
                  )
                : [0, 10, 20, 30, 40, 60, 70, 80, 90, 100],
            yAxis:
              maxTorqueValue > 100
                ? getArrayForRange(
                    outOfRangeTorqueGridLinesLight,
                    maxTorqueValue,
                    25
                  )
                : [75],
          }}
        />
      )}
    </div>
  );
};
