import { ProjectsState, ProjectActionTypes } from '../../models/homepageModel';
import { ActionTypes } from '../actions/actionTypes';

const initialState: ProjectsState = {
  projectData: {
    projectId: '',
    projectName: '',
    createdDate: '',
    modifiedDate: '',
    destinationCountry: '',
    countryName: '',
  },
};

const projectId = sessionStorage.getItem('id');
const projectName = sessionStorage.getItem('name');

const homepagereducer = (
  state = initialState,
  action: ProjectActionTypes
): ProjectsState => {
  switch (action.type) {
    case ActionTypes.CREATE_PROJECT:
      return {
        projectData: {
          ...action.payload,
          projectId: action?.payload.projectId ?? projectId,
          projectName: action?.payload.projectName ?? projectName,
          createdDate:
            action?.payload?.createdDate ?? state?.projectData?.createdDate,
          modifiedDate:
            action?.payload?.modifiedDate ?? state?.projectData?.modifiedDate,
        },
      };
    default:
      return state;
  }
};

export default homepagereducer;
