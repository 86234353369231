import { ActionTypes } from "../action/actionTypes";
import { PDSResults, UserDefinedLoadPoints, EfficiencyUDPDetails, MotorResults, EffDriveresults } from "../../../models/EfficiencyModel";

interface ProjectActionTypes {
    type: string;
    payload: any;
}
interface efficiencyState {
    udpLossesData: UserDefinedLoadPoints[],
    pdsData: PDSResults,
    udpData: EfficiencyUDPDetails,
    motorEfficiencyData: MotorResults,
    driveEfficiencydata: EffDriveresults
}
const initialState: efficiencyState = {
    udpLossesData: [],
    pdsData: {} as PDSResults,
    udpData:{} as EfficiencyUDPDetails,
    motorEfficiencyData:{} as MotorResults,
    driveEfficiencydata:{} as EffDriveresults
}

const efficiencyReducer = (
    state = initialState,
    action: ProjectActionTypes): efficiencyState => {
    switch (action.type) {
        case ActionTypes.USER_DEFINED_POINTS:
            return {
                ...state,
                udpLossesData: action.payload,
            };
        case ActionTypes.PDS_RESULTS:
            return {
                ...state,
                pdsData: action.payload
            }
        case ActionTypes.UDP_DATA:
            return {
                ...state,
                udpData:action.payload
            }
        case ActionTypes.MOTOR_EFFICIENCY_RESULTS:
            return {
                ...state,
                motorEfficiencyData: action.payload
            }
        case ActionTypes.DRIVE_EFFICIENCY_RESULTS:
                return{
                    ...state,
                    driveEfficiencydata: action.payload
                }
        default:
            return state;
    }
}

export default efficiencyReducer;