import TabContent from '../../../components/TabContent';
import TabGroup from '../../../components/TabGroup';
import TabItem from '../../../components/TabItem';
import { useTranslate } from '../../../i18translate/Hooks';
import LearningTabs, { LearningTabsProps } from './LearningTabs';
import Tools from './Tools';
import { I18 } from '../../../languages/I18';

function Tab({ articlesData }: LearningTabsProps) {
  const { t } = useTranslate();

  return (
    <>
      <TabGroup type="primary-black" selectedTabId="learning" size="small">
        <TabItem
          label={t(I18.home_learnings_tab)}
          closable={false}
          tabId="learning"
          icon="video-16"
          data-rf="learning-tab"
        ></TabItem>
        <TabItem
          label={t(I18.home_tools_tab)}
          closable={false}
          tabId="tools"
          icon="controls-and-settings-16"
          data-rf="Tools-tab"
        ></TabItem>
        <div slot="container">
          <TabContent containerId={'learning'}>
            <LearningTabs articlesData={articlesData} />
          </TabContent>
          <TabContent containerId={'tools'}>
            <Tools />
          </TabContent>
        </div>
      </TabGroup>
    </>
  );
}

export default Tab;
