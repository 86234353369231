import React from 'react';
import {
  loadPointsHeaders,
} from '../Utils/PreDefinedLoadPoints';
import { StandardPoints } from '../../models/EfficiencyModel';

interface TableProps {
  data: StandardPoints[]
}
const StandardAndPredefinedPointsTable = (props: TableProps) => {
  let stdPoints = props.data;
  return (
    <div className='set-table-overflow' data-rf="pds-losses-table">
      <table className="energy-losses-table color-table-border">
        <thead>
          <tr>
            {loadPointsHeaders.map((data, idx) => {
              return (
                <th key={data} id={data + idx}>
                  {data}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {
            stdPoints && stdPoints.length > 0 && 
            stdPoints.map((val, index) => {
              let ind = index + 1;
              let imp = ind < 4 ? "*" + ind : ind
              return (
                <tr key={val.absoluteLoss} id={val.absoluteLoss + index}>
                  <td>{imp}</td>
                  <td>{val.speed+" "}%</td>
                  <td>{val.torque+" "}%</td>
                  <td>{val.driveAbsoluteLoss +" "+ val.absoluteLossUnit}</td>
                  <td>{val.motorAbsoluteLoss +" "+ val.absoluteLossUnit}</td>
                  <td>{val.absoluteLoss +" "+ val.absoluteLossUnit}</td>
                  <td>{val.relativeLoss +" "+ val.relativeLossUnit}</td>
                  <td>{val.efficiency +" "+ val.efficiencyUnit}</td>
                </tr>
              )
            })
          }

          {/* removed as part of new UI, uncomment if predefined points are reimplimented */}
          {/* {
            props.data &&
            props.data?.preDefinedPoints.map((val, index) => {
              return (
                <tr key={val.absoluteLoss} id={val.absoluteLoss + index}>
                  <td>{index + 9}</td>
                  <td>{val.speed}%</td>
                  <td>{val.torque}%</td>
                  <td>{val.driveAbsoluteLoss + val.absoluteLossUnit}</td>
                  <td>{val.motorAbsoluteLoss + val.absoluteLossUnit}</td>
                  <td>{val.absoluteLoss + val.absoluteLossUnit}</td>
                  <td>{val.relativeLoss + val.relativeLossUnit}</td>
                  <td>{val.efficiency + val.efficiencyUnit}</td>
                </tr>
              )
            })
          } */}
        </tbody>
      </table>
    </div>
  );
};

export default StandardAndPredefinedPointsTable;
