import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
  lazy,
  Suspense,
} from 'react';
import SidebarPanel from './SidebarPanel';
import WorkspaceTabs from './WorkspaceTabs';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import {
  useNavigate,
  useActionData,
  useBlocker,
  BlockerFunction,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import Button from '../../../components/Form/Button';
import Icon from '../../../components/Icon';
import Reports from '../Reports/Reports';
import {
  useGetProjectByIdMutation,
  useSaveProjectMutation,
  useUpdateProjectMutation,
} from '../../Home/services/APIMainService';
import { useSession } from '../../../utils/Auth';
import { createProject } from '../../Home/store/actions/homeActions';
import notificationManager from '../../../utils/NotificationManager';
import {
  Dimension,
  MotorLoadData,
  MotorSelectionData,
} from '../Dimensioning/models/MotorSelectionModels';
import {
  CommonuxMenu,
  CommonuxMenuItem,
} from '@abb-motion-ux/commonux-web-components-react';
import { CommonuxButtonCustomEvent } from '@abb-motion-ux/commonux-web-components';
import BOMReport from '../Reports/BOMReport';
import {
  addSavedApplication,
  dimensionMotorInputData,
  dimensionOutputData,
  driveFormDataSave,
  driveLoadDataSave,
  motorFormDataSave,
  motorLoadDataSave,
  productAndTypeCodeSave,
  projectAndMotorTypeSelected,
  saveDriveErrorResponse,
  saveMotorErrorResponse,
  selectDrive,
  selectMotor,
  setActiveComponent,
  setIsProjectTemp,
  setCurrentApplication,
  setHasDimensionDataFetched,
  setHasMultipleOverload,
  setPower,
  resetAllApplication,
  setIsReportModalOpen,
  selectDriveCatalog,
  selectMotorCatalog,
  setTransformerInputData,
  setFetchedTransformerResult,
  setAmbientConditionValues,
  setHasTransformerReselected,
  setApplicationandindustryName,
  setRetrievedMotorFormData,
} from '../store/WorkspaceReducer';
import {
  CatalogProductData,
  GeneralProjectDetails,
} from '../../Home/models/homepageModel';
import {
  DriveLoadData,
  DriveSelectionData,
} from '../Dimensioning/models/DriveSelectionModels';
import CommonLoader from '../../../components/Loader';
import { ApplicationDetail, BOMData } from '../../Home/models/projectDataModel';
import { store } from '../../../store/store';
import {
  AmbientConditionValues,
  DimensionSavedData,
} from '../models/WorkspaceModels';
import CustomNavigationModal from '../../../components/BlockNavigationModal';
import AmbientConditionEditModal from './AmbientConditionEditModal';
import ReselectionConfirmationModal from '../Dimensioning/Components/ReselectConfirmationModal';
import { useReselectDimensionDataMutation } from '../Dimensioning/services/APIDimensioningService';
import {
  useGetCatalogReportMutation,
  useGetPISDataMutation,
} from '../../Home/services/APICommonService';
import {
  initialApplicationData,
  initialResultData,
  transformerDefaultInputValues,
} from '../Dimensioning/models/TransformerDefaultValues';
import {
  TransformerData,
  TransformerSelectionInput,
} from '../Dimensioning/models/TransformerSelectionModel';
import { ProjectTechnicalDataGeneration } from '../Reports/services/ProjectTechnicalData';
import { handleLoadTableChanges } from '../Dimensioning/models/MotorDefaultValues';
const ReportModal = lazy(() => import('./ReportModal'));

interface WorkspaceContainerProps {
  onSidebarItemClick?: (index: number) => void;
}

const WorkspaceContainer = (
  props: PropsWithChildren<WorkspaceContainerProps>
) => {
  const { isMobile } = useDeviceDetect();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openReports, setOpenReports] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [reportType, setReportType] = useState('all');
  const [viewAmbientEditModal, setViewAmbientEditModal] = useState(false);
  const [hasAnyApplicationSized, setHasAnyApplicationSized] = useState(false);
  const [viewReselectModal, setViewReselectModal] = useState(false);
  const [catalogInput, setCatalogInput] = useState<CatalogProductData[]>([]);

  const navigate = useNavigate();

  const projectDetails = useSelector(
    (state: RootState) => state.home.projectData
  );

  let actionData = useActionData() as { ok: boolean } | undefined;

  const [reselectDimensioningData] = useReselectDimensionDataMutation({
    fixedCacheKey: 'shared-dimension-post',
  });

  const [updateProject, updateStatus] = useUpdateProjectMutation({
    fixedCacheKey: 'shared-update-post',
  });

  const [catalogReport, status] = useGetCatalogReportMutation({
    fixedCacheKey: 'shared-dimension-post',
  });

  const [catalogueData, catalogStatus] = useGetPISDataMutation({
    fixedCacheKey: 'shared-catalogue-post',
  });

  const projectData = useSelector((state: RootState) => state.home.projectData);
  const selectedTransformerDatas = useSelector(
    (state: RootState) =>
      state.workspace.transformerDetails.selectedTransformerDatas
        ?.transformerInput
  );

  const transformerDatas = useSelector(
    (state: RootState) =>
      state.workspace.transformerDetails.selectedTransformerDatas
  );

  const ambientConditionValues = useSelector(
    (state: RootState) => state.workspace.ambientConditionValues
  );

  const currentAppRank = useSelector(
    (state: RootState) => state.workspace.currentAppRank
  );

  const applicationDetails = useSelector((state: RootState) => {
    return state.workspace.applicationDetails;
  });

  const reportData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank];
  });
  const isSidebarOpen = useSelector(
    (state: RootState) => state.workspace.sidebarOpen
  );

  const isProjectTemp = useSelector(
    (state: RootState) => state.workspace.isProjectTemp
  );

  const dispatch = useDispatch();
  const { session, loading: sessionLoading } = useSession();
  const [getProjectById] = useGetProjectByIdMutation();
  const [saveProjectById] = useSaveProjectMutation();

  let shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      if (!session) {
        return (
          currentLocation.pathname !== nextLocation.pathname ||
          currentLocation.search !== nextLocation.search
        );
      } else if (session) {
        return false;
      } else {
        return false;
      }
    },
    [session]
  );

  let blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (actionData?.ok) {
      dispatch(setPower());
    }
  }, [actionData]);

  useEffect(() => {
    const handlePopstate = () => {};

    window.addEventListener('popstate', handlePopstate);

    return () => window.removeEventListener('popstate', handlePopstate);
  }, [navigate]);

  useEffect(() => {
    const handlebeforeunload = () => {
      if (session && (updateStatus.isSuccess || updateStatus.isUninitialized))
        return null;
      return 'some text';
    };

    window.onbeforeunload = handlebeforeunload;
  }, [session, updateStatus]);

  useEffect(() => {
    return () => {
      dispatch(resetAllApplication());
    };
  }, []);

  let isReportEnabled = false;

  applicationDetails.forEach((application) => {
    if (
      application &&
      (!!application.dimensionDetails?.productTypeCode?.motorTypeDesignation ||
        !!application.dimensionDetails?.productTypeCode?.driveTypeCode)
    ) {
      isReportEnabled = true;
    }
  });

  useEffect(() => {
    const element = document.getElementById('content');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentAppRank]);

  const updateTransformerInit = async () => {
    const projectId = sessionStorage.getItem('id');
    const projectName = sessionStorage.getItem('name');
    await updateProject({
      userToken: session?.user ? session?.user.access_token : undefined,
      projectData: {
        projectId,
        projectName,
        jsonFileName: selectedTransformerDatas.trafoId,
        projectJson: JSON.stringify({
          transformerInput: selectedTransformerDatas,
        }),
      },
    });
  };

  const updateApplicationInit = async () => {
    const projectId = sessionStorage.getItem('id');
    const projectName = sessionStorage.getItem('name');
    await updateProject({
      userToken: session?.user ? session?.user.access_token : undefined,
      projectData: {
        projectId,
        projectName,
        jsonFileName:
          currentAppRank > 0
            ? applicationDetails[currentAppRank]?.id
            : applicationDetails[0]?.id,
        applicationDetailsJson: JSON.stringify({
          transformerId: selectedTransformerDatas?.trafoId,
          applicationId:
            currentAppRank > 0
              ? applicationDetails[currentAppRank]?.id
              : applicationDetails[0]?.id,
          applicationName:
            currentAppRank > 0
              ? applicationDetails[currentAppRank]?.name
              : applicationDetails[0]?.name,
          applicationRank: currentAppRank > 0 ? currentAppRank : 0,
        }),
      },
    });
  };

  const fetchProjectDetails = (projectId: string, isInitialLoad: boolean) => {
    setIsLoading(true);
    getProjectById({
      userToken: session?.user.access_token,
      id: projectId,
    }).then((response) => {
      if ('data' in response) {
        if (response.data.length > 0 && response.data.length === 1) {
          setIsLoading(false);
          setTimeout(() => {
            const applicationResult = updateApplicationInit();
            applicationResult.then(() => {
              updateTransformerInit().then(() => {
                setIsLoading(false);
              });
            });
          }, 500);
        } else if (response.data.length > 0) {
          //Updating general detailis of the project
          const projectDetails = response?.data.filter(
            (x) => x.projectName === 'General'
          );
          if (projectDetails) {
            dispatch(
              createProject(
                projectDetails?.[0].projectData as GeneralProjectDetails
              )
            );

            const ambientValues = projectDetails?.[0]
              .projectData as unknown as GeneralProjectDetails;

            dispatch(
              setAmbientConditionValues({
                altitude: ambientValues.ambientAltitude,
                motorTemparature: ambientValues.ambientTempMotor,
                driveTemparature: ambientValues.ambientTempInverter,
              })
            );
          }

          const transformerDetails = response?.data?.filter((i) =>
            i.projectName?.includes('tra-')
          );

          let transformerData = transformerDetails?.[0]
            ?.projectData as TransformerData;

          if (transformerDetails?.length > 0) {
            dispatch(
              setTransformerInputData({
                transformerInput:
                  transformerData?.transformerInput as TransformerSelectionInput,
              })
            );

            dispatch(
              setFetchedTransformerResult(transformerData?.transformerResult)
            );
          } else {
            dispatch(
              setTransformerInputData({
                transformerInput: transformerDefaultInputValues,
              })
            );

            dispatch(
              setFetchedTransformerResult({
                transformer: {
                  transformerResult: [
                    { typeDesignation: '-', selectionData: initialResultData },
                  ],
                },
              })
            );
          }

          const applicationDetails = response?.data.filter(
            (x) => x.projectName === 'ApplicationDetails'
          )?.[0]?.projectData as ApplicationDetail[];

          const bomDetails = response?.data.filter(
            (x) => x.projectName === 'BOM'
          )?.[0]?.projectData as BOMData[];

          if (bomDetails?.length > 0) {
            bomDetails?.forEach((bom) => {
              setCatalogInput((prev) => [
                ...prev,
                {
                  appId: bom.appId,
                  langCode: 'en',
                  countryCode: projectData.destinationCountry ?? 'IN',
                  productIds: {
                    motorProductId: bom.motorId ?? '',
                    driveProductId: bom.driveId ?? '',
                  },
                },
              ]);
            });
          }

          const allApplicationDetails = response?.data.filter((x) =>
            x.projectName?.includes('app-')
          );

          if (applicationDetails) {
            const thunkFunction = (dispatch: any, getState: any) => {
              applicationDetails.forEach((applicationDetail, idx) => {
                dispatch(
                  addSavedApplication({
                    id: applicationDetail.applicationId,
                    name: applicationDetail.applicationName,
                    rank: idx,
                  })
                );
              });

              const allApplications = getState().workspace.applicationDetails;

              if (allApplicationDetails.length > 0) {
                allApplicationDetails.forEach((applicationData, idx) => {
                  const applicationId = applicationData.projectName;

                  const projectDimensionDetails =
                    applicationData?.projectData as unknown as DimensionSavedData; // need to check with

                  const rank = allApplications.filter(
                    (x: any) => x.id === applicationId
                  )?.[0]?.rank;

                  if (
                    rank > -1 &&
                    projectDimensionDetails?.dimensionSavedData
                      ?.applicationOutput
                  ) {
                    dispatch(setCurrentApplication(rank));
                    dispatch(
                      setApplicationandindustryName({
                        rank,
                        industryName:
                          projectDimensionDetails?.selectionSavedData
                            ?.industryName,
                        applicationName:
                          projectDimensionDetails?.selectionSavedData
                            ?.applicationName,
                      })
                    );

                    let motorLoadData = projectDimensionDetails
                      ?.dimensionSavedData?.dimensionInput as MotorLoadData;
                    motorLoadData = handleLoadTableChanges(
                      projectDimensionDetails?.dimensionSavedData
                        ?.dimensionInput?.power,
                      'power',
                      motorLoadData
                    );

                    dispatch(motorLoadDataSave(motorLoadData));

                    const motorSelectionData: MotorSelectionData =
                      projectDimensionDetails?.dimensionSavedData
                        ?.dimensionInput as MotorSelectionData;

                    dispatch(setRetrievedMotorFormData(motorSelectionData));

                    const appDimensionDetail: Dimension = {
                      dimension: {
                        dimensionInput: {
                          selectionInputs: [
                            projectDimensionDetails?.dimensionSavedData
                              ?.dimensionInput,
                          ],
                        },
                        dimensionResult:
                          projectDimensionDetails?.dimensionSavedData
                            ?.applicationOutput,
                      },
                      exceptionType:
                        projectDimensionDetails?.dimensionSavedData
                          ?.exceptionType,
                      message:
                        projectDimensionDetails?.dimensionSavedData?.message,
                    };
                    dispatch(selectMotor(appDimensionDetail));

                    dispatch(
                      dimensionOutputData(
                        projectDimensionDetails?.dimensionSavedData
                          ?.applicationOutput
                      )
                    );

                    dispatch(
                      dimensionMotorInputData({
                        selectionInputs: [
                          projectDimensionDetails?.dimensionSavedData
                            ?.dimensionInput,
                        ],
                      })
                    );

                    const inverterUnitResult =
                      projectDimensionDetails?.dimensionSavedData
                        ?.applicationOutput?.supplyDriveLoads?.[0]?.inverterUnit
                        ?.results;

                    const driveTypeCode = inverterUnitResult?.[0]?.value;
                    const motorTypeDesignation =
                      projectDimensionDetails?.dimensionSavedData
                        ?.applicationOutput?.supplyDriveLoads?.[0]?.motorUnit
                        ?.results?.[0]?.value;

                    dispatch(
                      productAndTypeCodeSave({
                        productCode:
                          projectDimensionDetails?.dimensionSavedData
                            ?.applicationOutput?.supplyDriveLoads?.[0]
                            ?.motorUnit?.results?.[1]?.value ?? null,
                        motorTypeDesignation:
                          projectDimensionDetails?.dimensionSavedData
                            ?.applicationOutput?.supplyDriveLoads?.[0]
                            ?.motorUnit?.results?.[0]?.value ?? null,
                        driveTypeCode: inverterUnitResult
                          ? inverterUnitResult?.[0]?.value ?? null
                          : '',
                      })
                    );

                    dispatch(
                      driveLoadDataSave(
                        projectDimensionDetails?.dimensionSavedData
                          ?.dimensionInput as unknown as DriveLoadData
                      )
                    );
                    dispatch(
                      driveFormDataSave(
                        projectDimensionDetails?.dimensionSavedData
                          ?.dimensionInput as unknown as DriveSelectionData
                      )
                    );

                    if (
                      projectDimensionDetails?.dimensionSavedData
                        ?.applicationOutput?.supplyDriveLoads?.[0]?.motorUnit
                        ?.motorError !== ''
                    ) {
                      dispatch(
                        saveMotorErrorResponse({
                          errorMotorSelection:
                            projectDimensionDetails?.dimensionSavedData
                              ?.applicationOutput?.supplyDriveLoads?.[0]
                              ?.motorUnit?.motorError,
                          isError:
                            projectDimensionDetails?.dimensionSavedData?.applicationOutput?.supplyDriveLoads?.[0]?.motorUnit?.motorError
                              ?.toLowerCase()
                              .includes('error'),
                        })
                      );
                    }
                    if (
                      projectDimensionDetails?.dimensionSavedData
                        ?.applicationOutput?.supplyDriveLoads?.[0]
                        ?.inverterUnit &&
                      projectDimensionDetails?.dimensionSavedData
                        ?.applicationOutput?.supplyDriveLoads?.[0]?.inverterUnit
                        ?.invError !== ''
                    ) {
                      dispatch(
                        saveDriveErrorResponse({
                          errorDriveSelection:
                            projectDimensionDetails?.dimensionSavedData
                              ?.applicationOutput?.supplyDriveLoads?.[0]
                              ?.inverterUnit.invError,
                          isError:
                            projectDimensionDetails?.dimensionSavedData?.applicationOutput?.supplyDriveLoads?.[0]?.inverterUnit.invError
                              ?.toLowerCase()
                              .includes('error'),
                        })
                      );
                    }

                    if (
                      appDimensionDetail?.dimension?.dimensionResult
                        ?.supplyDriveLoads?.[0].inverterUnit
                    ) {
                      dispatch(selectDrive(appDimensionDetail));
                    }

                    if (
                      projectDimensionDetails?.dimensionSavedData
                        ?.dimensionInput?.overLoadType === '1'
                    ) {
                      dispatch(setHasMultipleOverload(true));
                    }

                    dispatch(setHasDimensionDataFetched(true));
                    if (
                      Boolean(motorTypeDesignation) ||
                      parseFloat(
                        projectDimensionDetails?.dimensionSavedData
                          ?.dimensionInput?.power
                      ) > 0
                    ) {
                      dispatch(setActiveComponent('motorDimension'));
                      dispatch(
                        projectAndMotorTypeSelected({
                          projectType:
                            projectDimensionDetails?.dimensionSavedData
                              ?.dimensionInput?.projectType,
                          motorType: 1,
                        })
                      );
                    } else if (Boolean(driveTypeCode)) {
                      dispatch(setActiveComponent('driveDimension'));
                      dispatch(
                        projectAndMotorTypeSelected({
                          projectType:
                            projectDimensionDetails?.dimensionSavedData
                              ?.dimensionInput?.projectType,
                          motorType: 1,
                        })
                      );
                    }
                  }

                  if (idx === allApplicationDetails?.length - 1)
                    dispatch(setCurrentApplication(0));
                });
              }
            };

            store.dispatch(thunkFunction);
          }

          setIsLoading(false);
        } else if (isInitialLoad) {
          dispatch(setIsProjectTemp(true));
        }
      } else {
        notificationManager.error('Unable to fetch project details');
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (!isLoading && status.isUninitialized) {
      if (catalogInput?.length > 0) {
        catalogReport({ catalogProductDatas: catalogInput })
          .then((response) => {
            if ('data' in response) {
              response.data.forEach((data) => {
                applicationDetails.forEach((application) => {
                  if (application.id === data.appId) {
                    if (data.catalogueDTO.length > 0) {
                      dispatch(
                        selectMotorCatalog({
                          data: {
                            ...application.dimensionDetails.selectMotorResult,
                            dimension: {
                              ...application.dimensionDetails.selectMotorResult
                                .dimension,
                              catalogueDataMotor: data.catalogueDTO.filter(
                                (catalog) => {
                                  if ('GloComAli' in catalog.productAttributes)
                                    return undefined;
                                  else return data;
                                }
                              )?.[0],
                              catalogueDataDrive: data.catalogueDTO.filter(
                                (catalog) => {
                                  if ('GloComAli' in catalog.productAttributes)
                                    return catalog;
                                  else return undefined;
                                }
                              )?.[0],
                            },
                          },
                          rank: application.rank,
                        })
                      );
                      dispatch(
                        selectDriveCatalog({
                          data: {
                            ...application.dimensionDetails.selectDriveResult,
                            dimension: {
                              ...application.dimensionDetails.selectDriveResult
                                .dimension,
                              catalogueDataMotor: data.catalogueDTO.filter(
                                (catalog) => {
                                  if ('GloComAli' in catalog.productAttributes)
                                    return undefined;
                                  else return data;
                                }
                              )?.[0],
                              catalogueDataDrive: data.catalogueDTO.filter(
                                (catalog) => {
                                  if ('GloComAli' in catalog.productAttributes)
                                    return catalog;
                                  else return undefined;
                                }
                              )?.[0],
                            },
                          },
                          rank: application.rank,
                        })
                      );
                    }
                  }
                });
              });
            }
          })
          .catch((e) => {});
      }
    }
  }, [isLoading]);

  useEffect(() => {
    const projectId = sessionStorage.getItem('id');
    const projectName = sessionStorage.getItem('name');
    if (!projectId || !projectName) {
      navigate(`/`, { replace: true });
    } else {
      if (!sessionLoading) {
        if (session?.user) {
          fetchProjectDetails(projectId, true);
        } else {
          setIsLoading(false);
          //application init function and transfromer init function
          updateApplicationInit();
          updateTransformerInit();
        }
      }
    }
  }, [sessionLoading]);

  useLayoutEffect(() => {
    const projectId = sessionStorage.getItem('id');
    const projectName = sessionStorage.getItem('name');

    if (
      isProjectTemp &&
      projectId &&
      projectName &&
      session?.user &&
      isLoading
    ) {
      saveProjectById({
        id: projectId,
        name: projectName,
        userToken: session.user.access_token,
      })
        .then((response) => {
          if ('data' in response) {
            notificationManager.success('Project saved successfully');
            fetchProjectDetails(projectId, false);
            dispatch(setIsProjectTemp(false));
          } else if ('error' in response) {
            if ('data' in response.error) {
              if (response.error.data === 'Project limt exceeded') {
                notificationManager.error(
                  'Maximum project limit of 50 exceeded'
                );

                setTimeout(() => {
                  window.location.replace('/');
                  window.history.pushState({}, '', '/');
                }, 1000);
              }
            }
          }
        })
        .catch((error) => {});
    }
  }, [isProjectTemp]);

  const handleBtnBlur = (e: CommonuxButtonCustomEvent<void>) => {
    setTimeout(() => {
      if (!openReports) {
        setIsMenuOpen(false);
      }
    }, 250);
  };

  const handleAmbientEditClick = () => {
    setViewAmbientEditModal(true);
  };
  const onAmbientEditModalClose = () => {
    setViewAmbientEditModal(false);
  };
  const handleAmbientEditConfirmClick = (
    ambientConditionVal: AmbientConditionValues
  ) => {
    //TO DO: Amibient value saving
    updateProject({
      userToken: session?.user.access_token,
      projectData: {
        projectId: projectDetails.projectId,
        projectName: projectDetails.projectName,
        jsonFileName: 'General',
        projectJson: JSON.stringify({
          ambientAltitude: ambientConditionVal?.altitude,
          ambientTempMotor: ambientConditionVal?.motorTemparature,
          ambientTempInverter: ambientConditionVal?.driveTemparature,
          ambientTempTransformer: '40',
          projectId: projectDetails.projectId,
          projectName: projectDetails.projectName,
          countryName: projectDetails?.countryName,
          destinationCountry: projectDetails?.destinationCountry,
          standard: projectDetails?.standard,
          unitSystem: projectDetails?.unitSystem,
        }),
      },
    });
    if (hasAnyApplicationSized) {
      setViewReselectModal(true);
    }
    setViewAmbientEditModal(false);
  };

  const onReselectConfirmationModalClose = () => {
    setViewReselectModal(false);
  };

  const handleReselectConfirmClick = () => {
    const lastAppRank = currentAppRank;
    reselectDimensioningData({
      transformerInput: transformerDatas?.transformerInput,
      selectionInputs: [
        ...applicationDetails?.map((application) => {
          let appDimensionInput =
            application.dimensionDetails?.dimensionInputData
              .selectionInputs?.[0];
          if (appDimensionInput && appDimensionInput?.appId) {
            if (
              Object.keys(appDimensionInput)?.length > 0 &&
              ('Compute' in appDimensionInput || 'compute' in appDimensionInput)
            ) {
              return {
                ...appDimensionInput,
                Compute: 'true',
                dataCategory: appDimensionInput.power !== '0' ? 'All' : 'Drive',
                iMax:
                  appDimensionInput.power !== '0'
                    ? undefined
                    : appDimensionInput.iMax,
                iContinuous:
                  appDimensionInput.power !== '0'
                    ? undefined
                    : appDimensionInput.iContinuous,
              };
            } else return initialApplicationData;
          } else {
            return initialApplicationData;
          }
        }),
      ],
      ambientInputs: {
        ambientAltitude: ambientConditionValues.altitude,
        ambientTempInverter: ambientConditionValues.driveTemparature,
        ambientTempMotor: ambientConditionValues.motorTemparature,
      },
    })
      .then((response) => {
        if (
          'data' in response &&
          response?.data?.dimension?.dimensionResult?.supplyDriveLoads.length >
            0
        ) {
          dispatch(
            setFetchedTransformerResult({
              transformer: {
                transformerResult: [
                  {
                    typeDesignation: '-',
                    selectionData:
                      response?.data?.dimension?.dimensionResult
                        ?.transformerUnit?.selectionData,
                  },
                ],
              },
            })
          );
          dispatch(setHasTransformerReselected(true));
          const thunkFunction = (dispatch: any, getState: any) => {
            const applicationResultSet =
              response?.data?.dimension?.dimensionResult?.supplyDriveLoads;

            const allApplications = getState().workspace.applicationDetails;

            updateProject({
              userToken: session ? session?.user.access_token : undefined,
              projectData: {
                projectId: projectDetails.projectId,
                projectName: projectDetails.projectName,
                jsonFileName: transformerDatas?.transformerInput?.trafoId,
                projectJson: JSON.stringify({
                  transformerInput: transformerDatas?.transformerInput,
                  transformerResult:
                    response.data?.dimension?.dimensionResult?.transformerUnit
                      ?.selectionData,
                }),
              },
            });
            applicationResultSet.forEach((i, index) => {
              const applicationId = i.appId;
              const rank = allApplications.filter(
                (x: any) => x.id === applicationId
              )?.[0]?.rank;

              let allResultData: Dimension = {
                dimension: {
                  dimensionInput: {
                    selectionInputs: [
                      response?.data?.dimension?.dimensionInput
                        ?.selectionInputs?.[index],
                    ],
                    transformerInput:
                      response?.data?.dimension?.dimensionInput
                        ?.transformerInput,
                  },
                  dimensionResult: { supplyDriveLoads: [i] },
                },
                exceptionType: response?.data?.exceptionType,
                message: response?.data?.message,
              };

              if (rank > -1) {
                dispatch(setCurrentApplication(rank));

                let motorLoadData = response?.data?.dimension?.dimensionInput
                  ?.selectionInputs?.[index] as unknown as MotorLoadData;
                motorLoadData = handleLoadTableChanges(
                  response?.data?.dimension?.dimensionInput?.selectionInputs?.[
                    index
                  ]?.power,
                  'power',
                  motorLoadData
                );

                dispatch(motorLoadDataSave(motorLoadData));

                dispatch(
                  motorFormDataSave(
                    response?.data?.dimension?.dimensionInput
                      ?.selectionInputs?.[
                      index
                    ] as unknown as MotorSelectionData
                  )
                );

                dispatch(selectMotor(allResultData));

                dispatch(dimensionOutputData({ supplyDriveLoads: [i] }));
                dispatch(
                  dimensionMotorInputData({
                    selectionInputs: [
                      response?.data?.dimension.dimensionInput
                        .selectionInputs?.[index],
                    ],
                  })
                );

                const inverterUnitResult = i.inverterUnit?.results;

                dispatch(
                  productAndTypeCodeSave({
                    productCode: i?.motorUnit?.results?.[1]?.value ?? null,
                    motorTypeDesignation:
                      i?.motorUnit?.results?.[0]?.value ?? null,
                    driveTypeCode: inverterUnitResult
                      ? inverterUnitResult?.[0]?.value ?? null
                      : '',
                  })
                );

                if (
                  response?.data?.dimension?.dimensionInput?.selectionInputs?.[
                    index
                  ]?.dataCategory !== 'Motor'
                ) {
                  dispatch(
                    driveLoadDataSave(
                      response?.data?.dimension?.dimensionInput
                        ?.selectionInputs?.[index] as unknown as DriveLoadData
                    )
                  );
                  dispatch(
                    driveFormDataSave(
                      response?.data?.dimension?.dimensionInput
                        ?.selectionInputs?.[
                        index
                      ] as unknown as DriveSelectionData
                    )
                  );
                  const motorError = i?.motorUnit?.motorError;
                  const driveError = i?.inverterUnit?.invError;
                  if (motorError && motorError !== '') {
                    if (motorError?.toLowerCase().includes('error')) {
                      dispatch(
                        saveMotorErrorResponse({
                          errorMotorSelection: motorError,
                          isError: true,
                          isWarning: false,
                        })
                      );
                    } else {
                      dispatch(
                        saveMotorErrorResponse({
                          errorMotorSelection: motorError ?? '',
                          isError: false,
                          isWarning: true,
                        })
                      );
                    }
                  } else {
                    saveMotorErrorResponse({
                      errorMotorSelection: null,
                      isError: false,
                      isWarning: false,
                    });
                  }

                  if (driveError && driveError !== '') {
                    if (driveError?.toLowerCase().includes('error')) {
                      dispatch(
                        saveDriveErrorResponse({
                          errorDriveSelection: driveError ?? '',
                          isError: true,
                          isWarning: false,
                        })
                      );
                    } else {
                      dispatch(
                        saveDriveErrorResponse({
                          errorDriveSelection: driveError ?? '',
                          isError: false,
                          isWarning: true,
                        })
                      );
                    }
                  } else {
                    saveDriveErrorResponse({
                      errorDriveSelection: null,
                      isError: false,
                      isWarning: false,
                    });
                  }

                  dispatch(selectDrive(allResultData));
                }

                updateProject({
                  userToken: session?.user
                    ? session?.user.access_token
                    : undefined,
                  projectData: {
                    projectId:
                      projectDetails.projectId ||
                      window.sessionStorage.getItem('id'),
                    projectName:
                      projectDetails.projectName ||
                      window.sessionStorage.getItem('name'),
                    jsonFileName:
                      index > 0
                        ? applicationDetails[index]?.id
                        : applicationDetails[0]?.id,
                    applicationDetailsJson: JSON.stringify({
                      transformerId:
                        transformerDatas?.transformerInput?.trafoId,
                      applicationId:
                        index > 0
                          ? applicationDetails[index]?.id
                          : applicationDetails[0]?.id,
                      applicationName:
                        index > 0
                          ? applicationDetails[index]?.name
                          : applicationDetails[0]?.name,
                      applicationRank: index > 0 ? index : 0,
                    }),
                    projectJson: JSON.stringify({
                      dimensionSavedData: {
                        dimensionInput:
                          response.data?.dimension?.dimensionInput
                            ?.selectionInputs?.[index],
                        applicationOutput: {
                          supplyDriveLoads: [
                            response.data?.dimension?.dimensionResult
                              ?.supplyDriveLoads?.[index],
                          ],
                        },

                        exceptionType: response?.data?.exceptionType,
                        message: response?.data?.message,
                      },
                    }),
                  },
                })
                  .then((response) => {})
                  .catch((error) => {
                    notificationManager.error(
                      'Unable to update application data',
                      error.data.toString()
                    );
                  });
              }
              if (index === allApplications?.length - 1)
                dispatch(setCurrentApplication(lastAppRank));
            });
          };
          store.dispatch(thunkFunction);
        } else {
          notificationManager.error('Unable to retrieve dimension data');
        }
      })
      .catch((error) => {
        notificationManager.error(
          'Unable to retrieve dimension data',
          error.data.toString()
        );
      });
  };

  useEffect(() => {
    applicationDetails.forEach((i) => {
      if (
        !Boolean(
          i.dimensionDetails?.dimensionOutputData?.supplyDriveLoads?.[0]
            ?.motorUnit?.results?.[0]?.value
        ) &&
        !Boolean(
          i.dimensionDetails?.dimensionOutputData?.supplyDriveLoads?.[0]
            ?.inverterUnit?.results?.[0]?.value
        )
      ) {
        setHasAnyApplicationSized(false);
      } else {
        setHasAnyApplicationSized(true);
      }
    });
  }, [applicationDetails, viewAmbientEditModal]);

  const handleMenuClick = (
    e: React.MouseEvent<HTMLCommonuxMenuItemElement>,
    type: string
  ) => {
    e.stopPropagation();
    if (type === 'all') {
      setReportType('all');
      setOpenReports(true);
      dispatch(setIsReportModalOpen(true));
    } else if (type === 'bom') {
      setReportType('bom');
      setOpenReports(true);
      dispatch(setIsReportModalOpen(true));
    }
    setIsMenuOpen(false);
  };

  //genenration of excel

  if (isLoading) {
    return (
      <div className="workspace-loader-container">
        <CommonLoader
          size="xlarge"
          indicatorType={'circular'}
          progressType={'indeterminate'}
          progressValue={50}
          type={'primary-black'}
        />
      </div>
    );
  }

  return (
    <div id="workspace-container" className="workspace-container">
      <SidebarPanel
        onItemClick={props.onSidebarItemClick}
        isProjectsLoading={isLoading}
        onAmbientEditClick={handleAmbientEditClick}
      />
      <div
        id="workspace-content-container"
        className={`workspace-content-container ${
          isSidebarOpen ? '' : 'collapsed'
        }`}
      >
        <div className="disp-flex">
          <div id="workspace-page-header" className="workspace-page-header">
            {isMobile && (
              <span className="workspace-revision">New revision</span>
            )}
            <span className="workspace-header">
              {currentAppRank === -1 ? 'Supply data' : reportData?.name}
            </span>
          </div>

          <Button
            type="primary-red"
            size="medium"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            data-rf="reports-button"
            disabled={
              !isReportEnabled ||
              status.isLoading ||
              // updateStatus.isLoading ||
              catalogStatus.isLoading
            }
            onBtnBlur={(e) => {
              handleBtnBlur(e);
            }}
          >
            <Icon slot="icon" size="small" name="reports" theme="light" />
            Reports
            <div slot="menu" hidden={isMenuOpen}>
              <Icon size="small" name="down" theme="light" />
            </div>
            <div slot="menu" hidden={!isMenuOpen}>
              <Icon size="small" name="up" theme="light" />
            </div>
          </Button>
          <CommonuxMenu
            open={isMenuOpen}
            className="workspace-reports-menu"
            size="large"
            onBlur={() => {
              setIsMenuOpen(false);
            }}
            heading="GENERATE REPORTS"
          >
            <CommonuxMenuItem
              label="All reports"
              size="large"
              onClick={(e) => {
                isReportEnabled && handleMenuClick(e, 'all');
              }}
              onBlur={() => {
                isReportEnabled && setIsMenuOpen(false);
              }}
              disabled={!isReportEnabled}
              aria-disabled={!isReportEnabled}
            ></CommonuxMenuItem>
            <CommonuxMenuItem
              label="Bill of Materials"
              size="large"
              onClick={(e) => {
                handleMenuClick(e, 'bom');
              }}
            ></CommonuxMenuItem>
            <CommonuxMenuItem
              label="Project Technical Data"
              size="large"
              onClick={(e) => {
                ProjectTechnicalDataGeneration(applicationDetails);
              }}
            ></CommonuxMenuItem>
            {/* Commented for beta release */}
            {/* <CommonuxMenuItem
              label="Selection Data"
              size="large"
              disabled
            ></CommonuxMenuItem>
            <CommonuxMenuItem
              label="Efficiency report"
              size="large"
              disabled
            ></CommonuxMenuItem>
            <CommonuxMenuItem
              label="Custom reports"
              size="large"
              disabled
            ></CommonuxMenuItem> */}
          </CommonuxMenu>
        </div>

        <WorkspaceTabs blocker={blocker} />
        {openReports && (
          <Suspense
            fallback={
              <CommonLoader
                indicatorType={'circular'}
                progressType={'indeterminate'}
                progressValue={50}
                type={'primary-black'}
              />
            }
          >
            <ReportModal
              open={openReports}
              onModalClose={() => {
                dispatch(setIsReportModalOpen(true));
                setOpenReports(false);
              }}
              isDownloadable={reportType === 'bom'}
              isPrintable={reportType === 'bom'}
              title={reportType}
            >
              {reportType === 'all' ? <Reports /> : <BOMReport />}
            </ReportModal>
          </Suspense>
        )}
      </div>
      {blocker ? <CustomNavigationModal blocker={blocker} /> : null}
      {
        <AmbientConditionEditModal
          modalOpen={viewAmbientEditModal}
          onClose={onAmbientEditModalClose}
          onConfirmClick={handleAmbientEditConfirmClick}
        />
      }
      {viewReselectModal && (
        <div>
          <ReselectionConfirmationModal
            modalOpen={viewReselectModal}
            onClose={onReselectConfirmationModalClose}
            onConfirmClick={handleReselectConfirmClick}
            contentText="This will reselect all the applications."
          />
        </div>
      )}
    </div>
  );
};

export default WorkspaceContainer;
