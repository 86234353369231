import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  Application,
  ApplicationInput,
  Drive,
  DriveInput,
  Industry,
  Motor,
  VoltageData,
} from '../models/SelectionModel';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_PRESALES_MAIN_BACKEND,
  credentials: 'same-origin',
  mode: 'cors',
});

export const selectionApi = createApi({
  reducerPath: 'selectionApi',
  baseQuery,
  endpoints: (builder) => ({
    getIndustries: builder.query<Industry[], string>({
      query: (locale: string) => `selection/getindustries?locale=${locale}`,
    }),
    getApplications: builder.query<Application[], ApplicationInput>({
      query: (data: ApplicationInput) =>
        `selection/getapplications?locale=${data.locale}&industryId=${data.industryId}`,
    }),
    getVoltages: builder.query<VoltageData, string>({
      query: (applicationId: string) =>
        `selection/getvoltages?applicationId=${applicationId}`,
    }),
    getDrives: builder.query<Drive[], DriveInput>({
      query: (body: DriveInput) => {
        return {
          url: `selection/getproducts?projectId=${body.projectId}`,
          method: 'POST',
          body: body.selectionData,
        };
      },
    }),
    getMotors: builder.query<Motor[], DriveInput>({
      query: (body: DriveInput) => {
        return {
          url: `selection/getmotors?projectId=${body.projectId}`,
          method: 'POST',
          body: body.selectionData,
        };
      },
    }),
    getAllSelectionData: builder.query<any, DriveInput>({
      query: (body: DriveInput) => {
        return {
          url: `selection/getallselectiondata?projectId=${body.projectId}`,
          method: 'POST',
          body: body.selectionData,
        };
      },
    }),
    getReportByID: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: `report/getreportdetails?projectId=${data.projectId}`,
          method: 'GET',
          headers: data.userToken
            ? {
                jwtToken: data.userToken,
                appKey: 'GoSelect',
              }
            : { appKey: 'GoSelect' },
        };
      },
      keepUnusedDataFor: 0, // 0s becuse this will call the api when where there is request
    }),
  }),
});

export const {
  useGetIndustriesQuery,
  useGetApplicationsQuery,
  useGetVoltagesQuery,
  useGetDrivesQuery,
  useGetMotorsQuery,
  useGetAllSelectionDataQuery,
  useGetReportByIDQuery,
} = selectionApi;
