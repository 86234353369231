import { CommonuxTextArea } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';

interface TextAreaProps extends JSX.CommonuxTextArea {
  className?: string;
  id?: string;
  valid?: boolean;
  dataRf?: string;
}

function TextArea(props: PropsWithChildren<TextAreaProps>) {
  return <CommonuxTextArea {...props}>{props.children}</CommonuxTextArea>;
}

export default TextArea;
