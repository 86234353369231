import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ArticleDetails,
  CatalogReportInput,
  CatalogReportOutput,
  CatalogueDetails,
  CountryDetails,
  PISInputData,
  Post,
  UserDetails,
} from '../models/homepageModel';
import {
  DrivetypeInputData,
  DrivetypeInputParameterData,
} from '../../Workspace/Dimensioning/models/DriveSelectionModels';

export interface ArticleImage {
  contextType: string;
  fileName: string;
  id: string;
  url: string;
}
export interface ProjectDetails {
  label: string;
  value: number;
  imgSrc: string;
  content: string;
}
export interface ProductImageDetails {
  url: string;
  thumbnailUrl: string;
}

export interface ProductAttributesInfo {
  attributeCode: string;
  attributeName: string;
  values: [{ text: string }];
}

export interface ProductAttributes {
  productId: ProductAttributesInfo;
  ABBType: ProductAttributesInfo;
  CatalogDescription: ProductAttributesInfo;
  CountryOfOrigin: ProductAttributesInfo;
  FrameSize?: ProductAttributesInfo;
  ProductName?: ProductAttributesInfo;
  FrameMaterial?: ProductAttributesInfo;
  MountingType?: ProductAttributesInfo;
  ModuleFunction?: ProductAttributesInfo;
  ProductType?: ProductAttributesInfo;
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_PRESALES_COMMON_BACKEND,
  credentials: 'same-origin',
  mode: 'cors',
});

// Example on how to create a basic get and post api
export const homeApi = createApi({
  reducerPath: 'homeAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://gorest.co.in/public/v2/',
  }),
  endpoints: (builder) => ({
    getUser: builder.query<Post, number>({
      query: (id) => `users/${id}`,
    }),
    addDetails: builder.mutation<UserDetails, Partial<UserDetails>>({
      query: (data: UserDetails) => ({
        url: 'users',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const countryApi = createApi({
  reducerPath: 'countryAPI',
  baseQuery,
  endpoints: (builder) => ({
    getCountries: builder.query<Array<CountryDetails>, string>({
      query: () => 'countries',
    }),
  }),
});

export const articlesApi = createApi({
  reducerPath: 'articlesAPI',
  baseQuery,

  endpoints: (builder) => ({
    getArticles: builder.query<Array<ArticleDetails>, string>({
      query: () => 'articles',
    }),
  }),
});

export const localeApi = createApi({
  reducerPath: 'localeAPI',
  baseQuery,
  endpoints: (builder) => ({
    getTranslations: builder.query<Object, string>({
      query: (lang) => `static-texts?locale=${lang}`,
    }),
  }),
});

export const PISDataApi = createApi({
  reducerPath: 'PISDataApi',
  baseQuery,
  endpoints: (builder) => ({
    getPISData: builder.mutation<CatalogueDetails, PISInputData>({
      query: (data: PISInputData) => ({
        url: `cataloguedata`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const CatalogReportApi = createApi({
  reducerPath: 'CatalogReportApi',
  baseQuery,
  endpoints: (builder) => ({
    getCatalogReport: builder.mutation<
      CatalogReportOutput[],
      CatalogReportInput
    >({
      query: (data: CatalogReportInput) => ({
        url: `catalogreportdata`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const DrivetypeInputDataApi = createApi({
  reducerPath: 'DrivetypeInputDataApi',
  baseQuery,
  endpoints: (builder) => ({
    getDrivetypeInputsData: builder.mutation<
      DrivetypeInputData,
      DrivetypeInputParameterData
    >({
      query: (data: DrivetypeInputParameterData) => ({
        url: 'getdriveinputdata',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useGetUserQuery, useAddDetailsMutation } = homeApi;

export const { useGetTranslationsQuery } = localeApi;

export const { useGetCountriesQuery } = countryApi;

export const { useGetCatalogReportMutation } = CatalogReportApi;

export const { useGetArticlesQuery } = articlesApi;

export const { useGetPISDataMutation } = PISDataApi;

export const { useGetDrivetypeInputsDataMutation } = DrivetypeInputDataApi;
