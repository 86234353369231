import { ApexOptions } from 'apexcharts';

export let dutyCyclegraphOptions: ApexOptions = {
  annotations: {
    xaxis: [
      { x: 200, borderWidth: 1 },
      { x: 400, borderWidth: 1 },
      { x: 600, borderWidth: 1 },
      { x: 800, borderWidth: 1 },
      { x: 1000, borderWidth: 1 },
      { x: 1200, borderWidth: 1 },
      { x: 1400, borderWidth: 1 },
      { x: 1600, borderWidth: 1 },
      { x: 1800, borderWidth: 1 },
    ],
  },
  chart: {
    type: 'line',
    height: 283,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: true,
      type: 'x',
      autoScaleYaxis: false,
    },
  },
  xaxis: {
    min: 0,
    max: 1800,
    tickAmount: 9,
    title: {
      text: 'Time [s]',
      style: {
        fontSize: '$font-size-s',
        fontWeight: 400,
      },
    },
  },
  yaxis: {
    min: 0,
    max: 500,
    tickAmount: 4,
  },
  stroke: {
    width: 2,
    colors: ['#323CF5'],
  },
};

export let dimensionGraphOptions: ApexOptions = {
  annotations: {
    xaxis: [
      { x: 500, borderWidth: 1 },
      { x: 1000, borderWidth: 1 },
      { x: 1500, borderWidth: 1 },
    ],
  },
  colors: ['#000000', '#32cd32', '#0000ff', '#de4040'],
  chart: {
    type: 'line',
    height: 283,
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: false,
      type: 'x',
      autoScaleYaxis: false,
    },
  },
  stroke: {
    width: [2, 2, 2, 2],
    curve: 'straight',
    dashArray: [0, 0, 8, 0],
  },
  xaxis: {
    min: 0,
    max: 1500,
    tickAmount: 3,
    title: {
      text: 'Speed [rpm]',
      style: {
        fontSize: '14px',
        fontWeight: 400,
      },
    },
  },
  yaxis: {
    min: 0,
    forceNiceScale: true,
    floating: false,
    decimalsInFloat: 0,
    title: {
      text: 'Power [kW]',
      style: {
        fontSize: '14px',
        fontWeight: 400,
      },
    },
  },
};

export let dimensionCurrentGraphOptions: ApexOptions = {
  annotations: {
    xaxis: [
      { x: 500, borderWidth: 1 },
      { x: 1000, borderWidth: 1 },
      { x: 1500, borderWidth: 1 },
    ],
  },
  colors: ['#000000', '#32cd32', '#0000ff', '#de4040'],
  chart: {
    type: 'line',
    height: 283,
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: false,
      type: 'x',
      autoScaleYaxis: false,
    },
  },
  stroke: {
    width: [2, 2, 2, 2],
    curve: 'straight',
    dashArray: [0, 0, 8, 0],
  },
  xaxis: {
    min: 0,
    max: 1500,
    tickAmount: 3,
    title: {
      text: 'Speed [rpm]',
      style: {
        fontSize: '14px',
        fontWeight: 400,
      },
    },
  },
  yaxis: {
    min: 0,
    forceNiceScale: true,
    floating: false,
    decimalsInFloat: 0,
    title: {
      text: 'Current [A]',
      style: {
        fontSize: '14px',
        fontWeight: 400,
      },
    },
  },
};

export let dimensionTorqueOptions: ApexOptions = {
  annotations: {
    xaxis: [
      { x: 500, borderWidth: 1 },
      { x: 1000, borderWidth: 1 },
      { x: 1500, borderWidth: 1 },
    ],
  },
  colors: ['#000000', '#32cd32', '#0000ff', '#de4040'],
  chart: {
    type: 'line',
    height: 283,
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: false,
      type: 'x',
      autoScaleYaxis: false,
    },
  },
  stroke: {
    width: [2, 2, 2, 2],
    curve: 'straight',
    dashArray: [0, 0, 8, 0],
  },
  xaxis: {
    min: 0,
    max: 1500,
    tickAmount: 3,
    title: {
      text: 'Speed [rpm]',
      style: {
        fontSize: '14px',
        fontWeight: 400,
      },
    },
  },
  yaxis: {
    min: 0,
    forceNiceScale: true,
    floating: false,
    decimalsInFloat: 0,
    title: {
      text: 'Torque [Nm]',
      style: {
        fontSize: '14px',
        fontWeight: 400,
      },
    },
  },
};

export let dimensionExpandedGraphOptions: ApexOptions = {
  annotations: {
    xaxis: [
      { x: 500, borderWidth: 1 },
      { x: 1000, borderWidth: 1 },
      { x: 1500, borderWidth: 1 },
    ],
  },
  colors: ['#000000', '#32cd32', '#0000ff', '#de4040'],
  chart: {
    type: 'line',
    height: 356,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: true,
      type: 'x',
      autoScaleYaxis: false,
    },
  },
  xaxis: {
    min: 0,
    max: 1500,
    tickAmount: 3,
    title: {
      text: 'Speed [rpm]',
      style: {
        fontSize: '14px',
        fontWeight: 400,
      },
    },
  },
  yaxis: {
    min: 0,
    forceNiceScale: true,
    floating: false,
    decimalsInFloat: 0,
    title: {
      text: 'Power [kW]',
      style: {
        fontSize: '14px',
        fontWeight: 400,
      },
    },
  },
  stroke: {
    width: [2, 2, 2, 2],
    curve: 'straight',
    dashArray: [0, 0, 8, 0],
  },
};

export let dimensionTorqueGraphOptions: ApexOptions = {
  annotations: {
    xaxis: [
      { x: 500, borderWidth: 1 },
      { x: 1000, borderWidth: 1 },
      { x: 1500, borderWidth: 1 },
    ],
  },
  colors: ['#000000', '#32CD32', '#0000FF', '#DE4040'],
  chart: {
    type: 'line',
    height: 356,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: true,
      type: 'x',
      autoScaleYaxis: false,
    },
  },
  xaxis: {
    min: 0,
    max: 1500,
    tickAmount: 3,
    title: {
      text: 'Speed [rpm]',
      style: {
        fontSize: '14px',
        fontWeight: 400,
      },
    },
  },
  yaxis: {
    min: 0,
    forceNiceScale: true,
    floating: false,
    decimalsInFloat: 0,
    title: {
      text: 'Torque [Nm]',
      style: {
        fontSize: '14px',
        fontWeight: 400,
      },
    },
  },
  stroke: {
    width: [2, 2, 2, 2],
    curve: 'straight',
    dashArray: [0, 0, 8, 0],
  },
};

export let efficiencyGraphOptions: ApexOptions = {
  annotations: {
    xaxis: [
      { x: 0, borderWidth: 1, borderColor: 'black' },
      { x: 10, borderWidth: 1 },
      { x: 20, borderWidth: 1 },
      { x: 30, borderWidth: 1 },
      { x: 40, borderWidth: 1 },
      { x: 50, borderWidth: 3, borderColor: 'black' },
      { x: 60, borderWidth: 1 },
      { x: 70, borderWidth: 1 },
      { x: 80, borderWidth: 1 },
      { x: 90, borderWidth: 1 },
      { x: 100, borderWidth: 1, borderColor: 'black' },
    ],
    yaxis: [
      { y: 0, borderWidth: 1, borderColor: 'black' },
      { y: 25, borderWidth: 1, borderColor: 'black' },
      { y: 50, borderWidth: 1, borderColor: 'black' },
      { y: 75, borderWidth: 1 },
      { y: 100, borderWidth: 1, borderColor: 'black' },
    ],
  },
  colors: ['#ed5739', 'rgb(27, 122, 194)', '#b5aa0d'],
  chart: {
    type: 'scatter',
    height: 403,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
      type: 'x',
      autoScaleYaxis: true,
    },
  },
  xaxis: {
    min: 0,
    max: 100,
    tickAmount: 10,
    title: {
      text: 'Speed (%)',
      style: {
        fontSize: '14px',
        fontWeight: 400,
      },
    },
  },
  yaxis: {
    min: 0,
    max: 100,
    tickAmount: 4,
    title: {
      text: 'Torque (%)',
      style: {
        fontSize: '14px',
        fontWeight: 400,
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
};
