import React from 'react';
import SelectionCards from './SelectionCards';

const Tools = () => {
  return (
    <>
      <SelectionCards />
    </>
  );
};

export default Tools;
