import { useSelector } from 'react-redux';
import Select from '../../../../components/Form/Select';
import SelectContainer from '../../../../components/Form/SelectContainer';
import SelectOption from '../../../../components/Form/SelectOption';
import { DriveSelectionData } from '../models/DriveSelectionModels';
import { RootState } from '../../../../store/rootReducer';

interface DriveSelectionDropdownProps {
  handleFormDataChange: (
    event: string,
    type: keyof DriveSelectionData,
    dataType: string
  ) => void;
  isLoading: boolean;
  isResultLoading: boolean;
}

const DriveSelectionDropdown = ({
  handleFormDataChange,
  isLoading,
  isResultLoading,
}: DriveSelectionDropdownProps) => {
  const driveDropDownData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.driveSelectionDropdownDataSet
  );
  const updatedFormFields = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.driveFormData
  );

  const projectMotorType = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.typeIds
  );

  return (
    <>
      {!isLoading && (
        <div>
          {driveDropDownData?.type?.length > 0 && (
            <div className="select-container">
              <div className="select-header">{'Type'}</div>
              <SelectContainer
                size="small"
                className="select-container-area"
                disabled={isResultLoading}
              >
                <Select
                  slot="main"
                  required={true}
                  value={updatedFormFields?.type}
                  disabled={isResultLoading}
                >
                  <div slot="options-main">
                    {driveDropDownData?.type?.map((input) => {
                      return (
                        <SelectOption
                          data-value={input?.integerValue}
                          optionText={input?.enumValue}
                          value={input?.integerValue?.toString()}
                          onOptionClick={(event) =>
                            handleFormDataChange(
                              event.target.value ?? '',
                              'type',
                              'form'
                            )
                          }
                          selected={
                            updatedFormFields?.type &&
                            projectMotorType?.projectType !== 7
                              ? input?.integerValue?.toString() ===
                                updatedFormFields?.type
                              : input?.isDefault
                          }
                        />
                      );
                    })}
                  </div>
                </Select>
              </SelectContainer>
            </div>
          )}
          {driveDropDownData?.construction?.length > 0 && (
            <div className="select-container">
              <div className="select-header">{'Construction'}</div>
              <SelectContainer
                size="small"
                className="select-container-area"
                disabled={isResultLoading}
              >
                <Select
                  slot="main"
                  required={true}
                  value={updatedFormFields?.construction}
                  disabled={isResultLoading}
                >
                  <div slot="options-main">
                    {driveDropDownData?.construction?.map((input) => {
                      return (
                        <SelectOption
                          data-value={input?.integerValue}
                          optionText={input?.enumValue}
                          value={input?.integerValue?.toString()}
                          onOptionClick={(event) =>
                            handleFormDataChange(
                              event.target.value ?? '',
                              'construction',
                              'form'
                            )
                          }
                          selected={
                            updatedFormFields?.construction
                              ? input?.integerValue?.toString() ===
                                updatedFormFields?.construction
                              : input?.isDefault
                          }
                        />
                      );
                    })}
                  </div>
                </Select>
              </SelectContainer>
            </div>
          )}
          {driveDropDownData?.ipClass?.length > 0 && (
            <div className="select-container">
              <div className="select-header">{'IP class'}</div>
              <SelectContainer
                size="small"
                className="select-container-area"
                disabled={isResultLoading}
              >
                <Select
                  slot="main"
                  required={true}
                  value={updatedFormFields?.ipClass}
                  disabled={isResultLoading}
                >
                  <div slot="options-main">
                    {driveDropDownData?.ipClass?.map((input) => {
                      return (
                        <SelectOption
                          data-value={input?.integerValue}
                          optionText={input?.enumValue}
                          value={input?.integerValue?.toString()}
                          onOptionClick={(event) =>
                            handleFormDataChange(
                              event.target.value ?? '',
                              'ipClass',
                              'form'
                            )
                          }
                          selected={
                            updatedFormFields?.ipClass
                              ? input?.integerValue?.toString() ===
                                updatedFormFields?.ipClass
                              : input?.isDefault
                          }
                        />
                      );
                    })}
                  </div>
                </Select>
              </SelectContainer>
            </div>
          )}
          {driveDropDownData?.minSwitchFreq?.length > 0 && (
            <div className="select-container">
              <div className="select-header">{'Min. Switching freq.'}</div>
              <SelectContainer
                size="small"
                className="select-container-area"
                disabled={isResultLoading}
              >
                <Select
                  slot="main"
                  required={true}
                  value={updatedFormFields?.minSwitchFreq}
                  disabled={isResultLoading}
                >
                  <div slot="options-main">
                    {driveDropDownData?.minSwitchFreq?.map((input) => {
                      return (
                        <SelectOption
                          data-value={input?.integerValue}
                          optionText={input?.enumValue}
                          value={input?.integerValue?.toString()}
                          onOptionClick={(event) =>
                            handleFormDataChange(
                              event.target.value ?? '',
                              'minSwitchFreq',
                              'form'
                            )
                          }
                          selected={
                            updatedFormFields?.minSwitchFreq
                              ? input?.integerValue?.toString() ===
                                updatedFormFields?.minSwitchFreq
                              : input?.isDefault
                          }
                        />
                      );
                    })}
                  </div>
                </Select>
              </SelectContainer>
            </div>
          )}
          {driveDropDownData?.cooling?.length > 0 && (
            <div className="select-container">
              <div className="select-header">{'Cooling'}</div>
              <SelectContainer
                size="small"
                className="select-container-area"
                disabled={isResultLoading}
              >
                <Select
                  slot="main"
                  required={true}
                  value={updatedFormFields?.cooling}
                  disabled={isResultLoading}
                >
                  <div slot="options-main">
                    {driveDropDownData?.cooling?.map((input) => {
                      return (
                        <SelectOption
                          data-value={input?.integerValue}
                          optionText={input?.enumValue}
                          value={input?.integerValue?.toString()}
                          onOptionClick={(event) =>
                            handleFormDataChange(
                              event.target.value ?? '',
                              'cooling',
                              'form'
                            )
                          }
                          selected={
                            updatedFormFields?.cooling
                              ? input?.integerValue?.toString() ===
                                updatedFormFields?.cooling
                              : input?.isDefault
                          }
                        />
                      );
                    })}
                  </div>
                </Select>
              </SelectContainer>
            </div>
          )}
          {driveDropDownData?.pulse?.length > 0 && (
            <div className="select-container">
              <div className="select-header">{'Pulse'}</div>
              <SelectContainer
                size="small"
                className="select-container-area"
                disabled={isResultLoading}
              >
                <Select
                  slot="main"
                  required={true}
                  value={updatedFormFields?.pulse}
                  disabled={isResultLoading}
                >
                  <div slot="options-main">
                    {driveDropDownData?.pulse?.map((input) => {
                      return (
                        <SelectOption
                          data-value={input?.integerValue}
                          optionText={input?.enumValue}
                          value={input?.integerValue?.toString()}
                          onOptionClick={(event) =>
                            handleFormDataChange(
                              event.target.value ?? '',
                              'pulse',
                              'form'
                            )
                          }
                          selected={
                            updatedFormFields?.pulse
                              ? input?.integerValue?.toString() ===
                                updatedFormFields?.pulse
                              : input?.isDefault
                          }
                        />
                      );
                    })}
                  </div>
                </Select>
              </SelectContainer>
            </div>
          )}
          {driveDropDownData?.sineFilter?.length > 0 && (
            <div className="select-container">
              <div className="select-header">{'Sine filter'}</div>
              <SelectContainer
                size="small"
                className="select-container-area"
                disabled={isResultLoading}
              >
                <Select
                  slot="main"
                  required={true}
                  value={updatedFormFields?.sineFilter}
                  disabled={isResultLoading}
                >
                  <div slot="options-main">
                    {driveDropDownData?.sineFilter?.map((input) => {
                      return (
                        <SelectOption
                          data-value={input?.integerValue}
                          optionText={input?.enumValue}
                          value={input?.integerValue?.toString()}
                          onOptionClick={(event) =>
                            handleFormDataChange(
                              event.target.value ?? '',
                              'sineFilter',
                              'form'
                            )
                          }
                          selected={
                            updatedFormFields?.sineFilter
                              ? input?.integerValue?.toString() ===
                                updatedFormFields?.sineFilter
                              : input?.isDefault
                          }
                        />
                      );
                    })}
                  </div>
                </Select>
              </SelectContainer>
            </div>
          )}
          {driveDropDownData?.glycolConcentration?.length > 0 && (
            <div className="select-container">
              <div className="select-header">{'Glycol concentration'}</div>
              <SelectContainer
                size="small"
                className="select-container-area"
                disabled={isResultLoading}
              >
                <Select
                  slot="main"
                  required={true}
                  value={updatedFormFields?.glycolConcentration}
                  disabled={isResultLoading}
                >
                  <div slot="options-main">
                    {driveDropDownData?.glycolConcentration?.map((input) => {
                      return (
                        <SelectOption
                          data-value={input?.integerValue}
                          optionText={input?.enumValue}
                          value={input?.integerValue?.toString()}
                          onOptionClick={(event) =>
                            handleFormDataChange(
                              event.target.value ?? '',
                              'glycolConcentration',
                              'form'
                            )
                          }
                          selected={
                            updatedFormFields?.glycolConcentration
                              ? input?.integerValue?.toString() ===
                                updatedFormFields?.glycolConcentration
                              : input?.isDefault
                          }
                        />
                      );
                    })}
                  </div>
                </Select>
              </SelectContainer>
            </div>
          )}
          {driveDropDownData?.liquidTemperature?.length > 0 && (
            <div className="select-container">
              <div className="select-header">{'Liquid temp.'}</div>
              <SelectContainer
                size="small"
                className="select-container-area"
                disabled={isResultLoading}
              >
                <Select
                  slot="main"
                  required={true}
                  value={updatedFormFields?.liquidTemperature}
                  disabled={isResultLoading}
                >
                  <div slot="options-main">
                    {driveDropDownData?.liquidTemperature?.map((input) => {
                      return (
                        <SelectOption
                          data-value={input?.integerValue}
                          optionText={input?.enumValue}
                          value={input?.integerValue?.toString()}
                          onOptionClick={(event) =>
                            handleFormDataChange(
                              event.target.value ?? '',
                              'liquidTemperature',
                              'form'
                            )
                          }
                          selected={
                            updatedFormFields?.liquidTemperature
                              ? input?.integerValue?.toString() ===
                                updatedFormFields?.liquidTemperature
                              : input?.isDefault
                          }
                        />
                      );
                    })}
                  </div>
                </Select>
              </SelectContainer>
            </div>
          )}
          {driveDropDownData?.emcCategory?.length > 0 && (
            <div className="select-container">
              <div className="select-header">{'EMC category'}</div>
              <SelectContainer
                size="small"
                className="select-container-area"
                disabled={isResultLoading}
              >
                <Select
                  slot="main"
                  required={true}
                  value={updatedFormFields?.emcCategory}
                  disabled={isResultLoading}
                >
                  <div slot="options-main">
                    {driveDropDownData?.emcCategory?.map((input) => {
                      return (
                        <SelectOption
                          data-value={input?.integerValue}
                          optionText={input?.enumValue}
                          value={input?.integerValue?.toString()}
                          onOptionClick={(event) =>
                            handleFormDataChange(
                              event.target.value ?? '',
                              'emcCategory',
                              'form'
                            )
                          }
                          selected={
                            updatedFormFields?.emcCategory
                              ? input?.integerValue?.toString() ===
                                updatedFormFields?.emcCategory
                              : input?.isDefault
                          }
                        />
                      );
                    })}
                  </div>
                </Select>
              </SelectContainer>
            </div>
          )}
          {driveDropDownData?.variant?.length > 0 && (
            <div className="select-container">
              <div className="select-header">{'Variant'}</div>
              <SelectContainer
                size="small"
                className="select-container-area"
                disabled={isResultLoading}
              >
                <Select
                  slot="main"
                  required={true}
                  value={updatedFormFields?.variant}
                  disabled={isResultLoading}
                >
                  <div slot="options-main">
                    {driveDropDownData?.variant?.map((input) => {
                      return (
                        <SelectOption
                          data-value={input?.integerValue}
                          optionText={input?.enumValue}
                          value={input?.integerValue?.toString()}
                          onOptionClick={(event) =>
                            handleFormDataChange(
                              event.target.value ?? '',
                              'variant',
                              'form'
                            )
                          }
                          selected={
                            updatedFormFields?.variant
                              ? input?.integerValue?.toString() ===
                                updatedFormFields?.variant
                              : input?.isDefault
                          }
                        />
                      );
                    })}
                  </div>
                </Select>
              </SelectContainer>
            </div>
          )}
          {driveDropDownData?.chokes?.length > 0 && (
            <div className="select-container">
              <div className="select-header">{'Chokes'}</div>
              <SelectContainer
                size="small"
                className="select-container-area"
                disabled={isResultLoading}
              >
                <Select
                  slot="main"
                  required={true}
                  value={updatedFormFields?.chokes}
                  disabled={isResultLoading}
                >
                  <div slot="options-main">
                    {driveDropDownData?.chokes?.map((input) => {
                      return (
                        <SelectOption
                          data-value={input?.integerValue}
                          optionText={input?.enumValue}
                          value={input?.integerValue?.toString()}
                          onOptionClick={(event) =>
                            handleFormDataChange(
                              event.target.value ?? '',
                              'chokes',
                              'form'
                            )
                          }
                          selected={
                            updatedFormFields?.chokes
                              ? input?.integerValue?.toString() ===
                                updatedFormFields?.chokes
                              : input?.isDefault
                          }
                        />
                      );
                    })}
                  </div>
                </Select>
              </SelectContainer>
            </div>
          )}
          {driveDropDownData?.gridVoltage?.length > 0 && (
            <div className="select-container">
              <div className="select-header">{'Grid Voltage'}</div>
              <SelectContainer
                size="small"
                className="select-container-area"
                disabled={isResultLoading}
              >
                <Select
                  slot="main"
                  required={true}
                  value={updatedFormFields?.gridVoltage}
                  disabled={isResultLoading}
                >
                  <div slot="options-main">
                    {driveDropDownData?.gridVoltage?.map((input) => {
                      return (
                        <SelectOption
                          data-value={input?.integerValue}
                          optionText={input?.enumValue}
                          value={input?.integerValue?.toString()}
                          onOptionClick={(event) =>
                            handleFormDataChange(
                              event.target.value ?? '',
                              'gridVoltage',
                              'form'
                            )
                          }
                          selected={
                            updatedFormFields?.gridVoltage
                              ? input?.integerValue?.toString() ===
                                updatedFormFields?.gridVoltage
                              : input?.isDefault
                          }
                        />
                      );
                    })}
                  </div>
                </Select>
              </SelectContainer>
            </div>
          )}
          {driveDropDownData?.cableLength?.length > 0 && (
            <div className="select-container">
              <div className="select-header">{'Cable length'}</div>
              <SelectContainer
                size="small"
                className="select-container-area"
                disabled={isResultLoading}
              >
                <Select
                  slot="main"
                  required={true}
                  value={updatedFormFields?.cableLength}
                  disabled={isResultLoading}
                >
                  <div slot="options-main">
                    {driveDropDownData?.cableLength?.map((input) => {
                      return (
                        <SelectOption
                          data-value={input?.integerValue}
                          optionText={input?.enumValue}
                          value={input?.integerValue?.toString()}
                          onOptionClick={(event) =>
                            handleFormDataChange(
                              event.target.value ?? '',
                              'cableLength',
                              'form'
                            )
                          }
                          selected={
                            updatedFormFields?.cableLength
                              ? input?.integerValue?.toString() ===
                                updatedFormFields?.cableLength
                              : input?.isDefault
                          }
                        />
                      );
                    })}
                  </div>
                </Select>
              </SelectContainer>
            </div>
          )}
          {driveDropDownData?.cableSize?.length > 0 && (
            <div className="select-container">
              <div className="select-header">{'Cable size'}</div>
              <SelectContainer
                size="small"
                className="select-container-area"
                disabled={isResultLoading}
              >
                <Select
                  slot="main"
                  required={true}
                  value={updatedFormFields?.cableSize}
                  disabled={isResultLoading}
                >
                  <div slot="options-main">
                    {driveDropDownData?.cableSize?.map((input) => {
                      return (
                        <SelectOption
                          data-value={input?.integerValue}
                          optionText={input?.enumValue}
                          value={input?.integerValue?.toString()}
                          onOptionClick={(event) =>
                            handleFormDataChange(
                              event.target.value ?? '',
                              'cableSize',
                              'form'
                            )
                          }
                          selected={
                            updatedFormFields?.cableSize
                              ? input?.integerValue?.toString() ===
                                updatedFormFields?.cableSize
                              : input?.isDefault
                          }
                        />
                      );
                    })}
                  </div>
                </Select>
              </SelectContainer>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DriveSelectionDropdown;
