import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { EfficiencyInputs, PDSResults, UDPInputs, MotorResults, MotorInputs, StandardPoints } from '../../models/EfficiencyModel';
import { EffDriveresults, DriveInputs } from '../../models/EfficiencyModel';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PRESALES_MAIN_BACKEND,
    credentials: 'same-origin',
    mode: 'cors',
});

export const efficiencyApi = createApi({
    reducerPath: 'efficiencyApi',
    baseQuery,
    endpoints: (builder) => ({
        getPDSResults: builder.mutation<PDSResults, EfficiencyInputs>({
            query: (data: EfficiencyInputs) => {
                return {
                    url: '/efficiency/getpdsresultsdata',
                    method: 'POST',
                    body: data
                }
            }
        }),
        getPartialLoadLosses: builder.mutation<StandardPoints[], UDPInputs>({
            query: (data: UDPInputs) => {
                return {
                    url: '/efficiency/getpartialloadlosses',
                    method: 'POST',
                    body: data
                }
            }
        }),
        getMotordata:builder.mutation<MotorResults, MotorInputs>({
            query: (data: MotorInputs) => {
                return {
                    url: '/efficiency/getmotordata',
                    method: 'POST',
                    body: data
                }
            }
        }),
        getDrivedata: builder.query<EffDriveresults, DriveInputs>({
            query: (data: DriveInputs) => ({
                url: 'efficiency/getdrivedata',
                method: 'GET',
                params: data
        }),
    }),
    })
});


export const { useGetPDSResultsMutation, useGetPartialLoadLossesMutation, useGetMotordataMutation, useLazyGetDrivedataQuery } = efficiencyApi;