import { useCallback, useEffect } from 'react';
import Icon from '../../../../components/Icon';
import useDeviceDetect from '../../../../hooks/useDeviceDetect';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import {
  handleLoadTableChanges,
  motorLoadDataGPDDefaults,
} from '../models/MotorDefaultValues';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { motorLoadDataSave } from '../../store/WorkspaceReducer';
import {
  MotorLoadData,
  MotorLoadTableProps,
} from '../models/MotorSelectionModels';
import Tooltip from '../../../../components/Tooltip';
import { roundDecimal } from '../../../../utils/GeneralUtils';

const MotorLoadTable = ({ isLoading }: MotorLoadTableProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const { isMobile } = useDeviceDetect();

  const selectionData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      .selectionDetails;
  });
  const hasDimensionDataFetched = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.hasDimensionDataFetched
  );
  const hasMultipleOverload = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.hasMultipleOverload
  );
  const loadfields = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.motorLoadData
  ) as MotorLoadData;

  const handleInputDataChange = useCallback(
    (event: string, type: string) => {
      let motorLoadFields = { ...loadfields };
      motorLoadFields = handleLoadTableChanges(event, type, motorLoadFields);
      dispatch(motorLoadDataSave(motorLoadFields));
    },
    [dispatch, loadfields]
  );

  useEffect(() => {
    if (selectionData.power > 0) {
      handleInputDataChange(selectionData.power.toString(), 'power');
    }
  }, []);

  return (
    <>
      <div className="motor-load-table-container">
        {!isMobile && (
          <table className="motor-load-table">
            <thead>
              <tr>
                <td></td>
                <td>{t(I18.motor_selection_motor_load_min_label)}</td>
                <td>{t(I18.motor_selection_motor_load_base_label)}</td>
                <td>{t(I18.motor_selection_motor_load_max_label)}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="property-col">
                  <div className="property-col-container">
                    <div className="property-text">
                      {t(I18.motor_selection_motor_load_speed_label)}{' '}
                    </div>
                    <Tooltip
                      size="medium"
                      alignment="start"
                      placement="bottom"
                      arrowPosition="left"
                    >
                      <div slot="tooltip-parent" className="tooltip-icon">
                        <Icon
                          slot="icon"
                          size="small"
                          name="information-circle-2"
                          className="icon-information-circle"
                        />
                      </div>
                      <div slot="content-section">Motor Speed</div>
                    </Tooltip>
                  </div>{' '}
                </td>
                <td>
                  {/* for Constant power && Pump/fan load, field  will be disabled*/}
                  <div
                    className={`value-column ${
                      loadfields?.loadType === '0' ||
                      loadfields?.loadType === '3' ||
                      isLoading
                        ? 'background-grey'
                        : ''
                    }`}
                  >
                    <div>
                      <input
                        className="table-input no-arrow"
                        type="number"
                        onChange={(event) =>
                          handleInputDataChange(event.target.value, 'nMin')
                        }
                        required={true}
                        value={loadfields?.nMin}
                        disabled={
                          loadfields?.loadType === '0' ||
                          loadfields?.loadType === '3' ||
                          isLoading
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="unit-container">{'rpm'}</div>
                  </div>
                </td>
                <td>
                  <div
                    className={`value-column ${
                      isLoading ? 'background-grey' : ''
                    }`}
                  >
                    <div>
                      <input
                        className="table-input no-arrow"
                        type="number"
                        onChange={(event) =>
                          handleInputDataChange(event.target.value, 'nBase')
                        }
                        required={true}
                        value={loadfields?.nBase}
                        max={5000}
                        disabled={isLoading}
                      />
                    </div>
                    <div className="unit-container">{'rpm'}</div>
                  </div>
                </td>
                <td>
                  {/* for Pump/fan load, field  will be disabled*/}
                  <div
                    className={`value-column ${
                      loadfields?.loadType === '3' ||
                      loadfields?.loadType === '1' ||
                      isLoading
                        ? 'background-grey'
                        : ''
                    }`}
                  >
                    <div>
                      <input
                        className="table-input no-arrow"
                        type="number"
                        onChange={(event) =>
                          handleInputDataChange(event.target.value, 'nMax')
                        }
                        required={true}
                        value={loadfields?.nMax}
                        disabled={
                          loadfields?.loadType === '3' ||
                          loadfields?.loadType === '1' ||
                          isLoading
                        }
                      />
                    </div>
                    <div className="unit-container">{'rpm'}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="property-col">
                  <div className="property-col-container">
                    <div className="property-text">
                      {t(I18.motor_selection_motor_load_power_label)}{' '}
                      <span className="required">*</span>
                    </div>
                    <Tooltip
                      size="medium"
                      alignment="start"
                      placement="bottom"
                      arrowPosition="left"
                    >
                      <div slot="tooltip-parent" className="tooltip-icon">
                        <Icon
                          slot="icon"
                          size="small"
                          name="information-circle-2"
                          className="icon-information-circle"
                        />
                      </div>
                      <div slot="content-section">
                        Load power at motor shaft
                      </div>
                    </Tooltip>
                  </div>
                </td>
                <td>
                  <div className={'value-column background-grey'}>
                    <div>
                      <input
                        className="table-input no-arrow"
                        type="number"
                        required={true}
                        value={
                          hasDimensionDataFetched
                            ? parseFloat(loadfields?.power) > 0
                              ? roundDecimal(
                                  (parseFloat(loadfields?.nMin) /
                                    parseFloat(loadfields?.nBase)) *
                                    parseFloat(loadfields?.power)
                                ).toString()
                              : '0'
                            : loadfields?.powerMin ??
                              motorLoadDataGPDDefaults?.powerMin
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="unit-container">{'kW'}</div>
                  </div>
                </td>
                <td>
                  <div
                    className={`value-column ${
                      parseFloat(loadfields?.power) > 0 &&
                      loadfields?.power !== ''
                        ? ''
                        : 'power-base-mandatory'
                    } ${isLoading ? 'background-grey' : ''}`}
                  >
                    <div>
                      <input
                        className="table-input no-arrow"
                        type="number"
                        onChange={(event) => {
                          handleInputDataChange(event.target.value, 'power');
                        }}
                        value={
                          (parseFloat(loadfields?.power) > 0
                            ? loadfields?.power
                            : loadfields?.power) ??
                          motorLoadDataGPDDefaults?.power
                        }
                        disabled={isLoading}
                      />
                    </div>
                    <div className="unit-container">{'kW'}</div>
                  </div>
                </td>
                <td>
                  <div className={'value-column background-grey'}>
                    <div>
                      <input
                        className="table-input no-arrow"
                        type="number"
                        required={true}
                        value={
                          (hasDimensionDataFetched
                            ? parseFloat(loadfields?.power) > 0
                              ? parseFloat(loadfields?.power)
                              : '0'
                            : loadfields?.powerMax) ??
                          motorLoadDataGPDDefaults?.powerMax
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="unit-container">{'kW'}</div>
                  </div>
                </td>
              </tr>
              {/* for One-time, field  will be disabled*/}
              {loadfields?.overLoadType !== '2' && (
                <tr>
                  <td className="property-col">
                    <div className="property-col-container">
                      <div className="property-text">
                        {t(I18.motor_selection_motor_load_overload_label)}{' '}
                      </div>
                      <Tooltip
                        size="medium"
                        alignment="center"
                        placement="bottom"
                        arrowPosition="left"
                      >
                        <div slot="tooltip-parent" className="tooltip-icon">
                          <Icon
                            slot="icon"
                            size="small"
                            name="information-circle-2"
                            className="icon-information-circle"
                          />
                        </div>
                        <div slot="content-section">
                          Overload demand at motor shaft from it's driven
                          equipment .
                        </div>
                      </Tooltip>
                    </div>
                  </td>
                  <td>{''}</td>
                  <td>
                    <div
                      className={`value-column ${
                        hasMultipleOverload ||
                        loadfields?.overLoadType === '1' ||
                        isLoading
                          ? 'background-grey'
                          : ''
                      }`}
                    >
                      <div>
                        <input
                          className="table-input no-arrow"
                          type={
                            hasMultipleOverload ||
                            loadfields?.overLoadType === '1'
                              ? 'text'
                              : 'number'
                          }
                          onChange={(event) => {
                            if (
                              parseFloat(event.target.value) <= 999 ||
                              event.target.value.length === 0
                            ) {
                              handleInputDataChange(
                                event.target.value,
                                'olBaseInput'
                              );
                            }
                          }}
                          required={true}
                          value={
                            hasMultipleOverload ||
                            loadfields?.overLoadType === '1'
                              ? 'custom'
                              : loadfields?.olBaseInput ??
                                motorLoadDataGPDDefaults?.olBaseInput
                          }
                          disabled={
                            hasMultipleOverload ||
                            loadfields?.overLoadType === '1' ||
                            isLoading
                          }
                        />
                      </div>
                      <div className="unit-container">{'%'}</div>
                    </div>
                  </td>
                  <td>
                    {/* for Constant torque & Pump/fan load, field  will be disabled*/}
                    <div
                      className={`value-column ${
                        loadfields?.loadType === '1' ||
                        loadfields?.loadType === '3' ||
                        hasMultipleOverload ||
                        isLoading
                          ? 'background-grey'
                          : ''
                      }`}
                    >
                      <div>
                        <input
                          className="table-input no-arrow"
                          type={
                            hasMultipleOverload ||
                            loadfields?.overLoadType === '1'
                              ? 'text'
                              : 'number'
                          }
                          onChange={(event) => {
                            handleInputDataChange(
                              event.target.value,
                              'olMaxInput'
                            );
                          }}
                          required={true}
                          value={
                            hasMultipleOverload ||
                            loadfields?.overLoadType === '1'
                              ? 'custom'
                              : loadfields?.olMaxInput ??
                                motorLoadDataGPDDefaults?.olMaxInput
                          }
                          disabled={
                            (loadfields?.loadType === '1' ||
                            loadfields?.loadType === '3' ||
                            isLoading
                              ? true
                              : false) || hasMultipleOverload
                          }
                        />
                      </div>
                      <div className="unit-container">{'%'}</div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        {isMobile && (
          <table className="motor-load-table-mobile">
            <thead>
              <tr className="motor-load-table-mobile-header">
                <td>{t(I18.motor_selection_motor_load_min_label)}</td>
                <td>{t(I18.motor_selection_motor_load_base_label)}</td>
                <td>{t(I18.motor_selection_motor_load_max_label)}</td>
              </tr>
            </thead>
            <tbody>
              <tr className="motor-load-table-mobile-subheader">
                <th colSpan={3}>
                  <span className="subheader-title">
                    {t(I18.motor_selection_motor_load_speed_label)}
                  </span>
                  <span className="subheader-desc">
                    {t(I18.motor_selection_motor_load_speed_description)}
                  </span>
                </th>
              </tr>
              <tr className="motor-load-table-mobile-value">
                <td>
                  {/* for Constant power & Pump/fan load, field  will be disabled*/}
                  <div
                    className={`value-column ${
                      loadfields?.loadType === '0' ||
                      loadfields?.loadType === '3'
                        ? 'background-grey'
                        : ''
                    }`}
                  >
                    <div className="input-unit-container">
                      <input
                        className="table-input no-arrow"
                        type="number"
                        onChange={(event) =>
                          handleInputDataChange(event.target.value, 'nMin')
                        }
                        required={true}
                        value={
                          loadfields?.nMin ?? motorLoadDataGPDDefaults?.nMin
                        }
                        disabled={
                          loadfields?.loadType === '0' ||
                          loadfields?.loadType === '3'
                            ? true
                            : false
                        }
                      />
                      <div className="unit-container">{'rpm'}</div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="value-column">
                    <div className="input-unit-container">
                      <input
                        className="table-input no-arrow"
                        type="number"
                        onChange={(event) =>
                          handleInputDataChange(event.target.value, 'nBase')
                        }
                        required={true}
                        value={
                          loadfields?.nBase ?? motorLoadDataGPDDefaults?.nBase
                        }
                        max={5000}
                      />
                      <div className="unit-container">{'rpm'}</div>
                    </div>
                  </div>
                </td>
                <td>
                  {/* for  Pump/fan load, field  will be disabled*/}
                  <div
                    className={`value-column ${
                      loadfields?.loadType === '3' ? 'background-grey' : ''
                    }`}
                  >
                    <div className="input-unit-container">
                      <input
                        className="table-input no-arrow"
                        type="number"
                        onChange={(event) =>
                          handleInputDataChange(event.target.value, 'nMax')
                        }
                        required={true}
                        value={
                          loadfields?.nMax ?? motorLoadDataGPDDefaults?.nMax
                        }
                        disabled={loadfields?.loadType === '3'}
                      />
                      <div className="unit-container">{'rpm'}</div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="motor-load-table-mobile-subheader">
                <th colSpan={3}>
                  <span className="subheader-title">
                    {t(I18.motor_selection_motor_load_power_label)}
                  </span>
                  <span className="subheader-desc">
                    {t(I18.motor_selection_motor_load_power_description)}
                  </span>
                </th>
              </tr>
              <tr>
                <td>
                  <div className={'value-column background-grey'}>
                    <div className="input-unit-container">
                      <input
                        className="table-input no-arrow"
                        type="number"
                        onChange={(event) =>
                          handleInputDataChange(event.target.value, 'powerMin')
                        }
                        required={true}
                        value={
                          loadfields?.powerMin ??
                          motorLoadDataGPDDefaults?.powerMin
                        }
                        disabled={true}
                      />
                      <div className="unit-container">{'rpm'}</div>
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    className={`value-column ${
                      loadfields?.power === '0' ? 'power-base-mandatory' : ''
                    }`}
                  >
                    <div className="input-unit-container">
                      <input
                        className="table-input no-arrow"
                        type="number"
                        onChange={(event) =>
                          handleInputDataChange(event.target.value, 'power')
                        }
                        required={true}
                        value={
                          loadfields?.power ?? motorLoadDataGPDDefaults?.power
                        }
                      />
                      <div className="unit-container">{'kW'}</div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className={'value-column background-grey'}>
                    <div className="input-unit-container">
                      <input
                        className="table-input no-arrow"
                        type="number"
                        onChange={(event) =>
                          handleInputDataChange(event.target.value, 'powerMax')
                        }
                        required={true}
                        value={
                          loadfields?.powerMax !== null
                            ? loadfields?.powerMax
                            : motorLoadDataGPDDefaults?.powerMax
                        }
                        disabled={true}
                      />
                      <div className="unit-container">{'kW'}</div>
                    </div>
                  </div>
                </td>
              </tr>
              {/* for One-time, field  will be disabled*/}
              {loadfields?.overLoadType !== '2' && (
                <>
                  <tr className="motor-load-table-mobile-subheader">
                    <th colSpan={3}>
                      <span className="subheader-title">
                        {t(I18.motor_selection_motor_load_overload_label)}
                      </span>
                      <span className="subheader-desc">
                        {t(I18.motor_selection_motor_load_overload_description)}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <td>{''} </td>
                    <td>
                      <div className="value-column">
                        <div>
                          <input
                            className="table-input no-arrow"
                            type="number"
                            onChange={(event) =>
                              handleInputDataChange(
                                event.target.value,
                                'overloadBase'
                              )
                            }
                            required={true}
                            value={
                              loadfields?.olBaseInput !== null
                                ? loadfields?.olBaseInput
                                : motorLoadDataGPDDefaults?.olBaseInput
                            }
                          />
                        </div>
                        <div className="unit-container">{'%'}</div>
                      </div>
                    </td>
                    <td>
                      {/* for Constant torque & Pump/fan load, field  will be disabled*/}
                      <div
                        className={`value-column ${
                          loadfields?.loadType === '1' ||
                          loadfields?.loadType === '3'
                            ? 'background-grey'
                            : ''
                        }`}
                      >
                        <div className="input-unit-container">
                          <input
                            className="table-input no-arrow"
                            type="number"
                            onChange={(event) =>
                              handleInputDataChange(
                                event.target.value,
                                'overloadMax'
                              )
                            }
                            required={true}
                            value={
                              loadfields?.olMaxInput !== null
                                ? loadfields?.olMaxInput
                                : motorLoadDataGPDDefaults?.olMaxInput
                            }
                            disabled={
                              loadfields?.loadType === '1' ||
                              loadfields?.loadType === '3'
                                ? true
                                : false
                            }
                          />
                          <div className="unit-container">{'kW'}</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default MotorLoadTable;
