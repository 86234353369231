import React from 'react';
import _ from 'lodash';
import { MotorStandardPoints } from '../../models/EfficiencyModel';
import { D3 } from '../../../../components/D3';
import '../Styles/LossesChart.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';

const STD_SHAPE = 'square';
const PART_LOAD_SHAPE = 'diamond';
const STD_SHAPE_COLOR = 'rgba(27, 122, 194, 1)';
const PART_LOAD_SHAPE_COLOR = 'rgba(181, 170, 13, 1)';
interface ICoordinate {
  index?: number;
  x: number;
  y: number;
  efficiency?: string;
  efficiencyUnit?: string;
  absoluteLoss?: string;
  absoluteLossUnit?: string;
  shape?: string;
  tooltipContent?: string;
  pointLabel?: string;
  pointColor?: string;
  pointLabelFontSize?: string;
  customShape?: any;
}

const getTooltip = (point: MotorStandardPoints, shape: string) => {
  const ChartToolColorClass =
    shape == STD_SHAPE
      ? 'chart-legend-color-tooltip'
      : shape == PART_LOAD_SHAPE
      ? 'diamond-narrow-tooltip'
      : '';
  const ChartToolContentClass =
    shape == STD_SHAPE
      ? 'chart-legend-content'
      : shape == PART_LOAD_SHAPE
      ? 'diamond-tool-content'
      : '';
  return `  
  <div class="chart-legends-tool">
    <div class= ${ChartToolColorClass}></div>
    <div class="${ChartToolContentClass}">
      <div class="content1">Speed ${point.speed}%; Torque ${point.torque}%</div>	
      <div class="content2">Absolute losses ${point.absoluteLoss}${point.absoluteLossUnit}</div>	
      <div class="content3">Efficiency  ${point.efficiency}${point.efficiencyUnit}</div>
    </div>
</div>`;
};

const getPointLabel = (point: MotorStandardPoints) => {
  return (
    '' +
    point?.absoluteLoss +
    point?.absoluteLossUnit +
    ';' +
    point?.efficiency +
    point?.efficiencyUnit
  );
};

// Partload shape(Diamond)
const partLoadShape = {
  draw: function (context: any, size: any) {
    context.moveTo(0, -size / 11);
    context.lineTo(size / 11, 0);
    context.lineTo(0, size / 11);
    context.lineTo(-size / 11, 0);
    context.lineTo(0, -size / 11);
    context.closePath();
  },
};

const formatPointsForChart = (
  points: MotorStandardPoints[],
  shape: string,
  type: string
): ICoordinate[] => {
  return points.map((point, index) => ({
    index: index + 1,
    shape,
    x: parseInt(point.speed),
    y: parseInt(point.torque),
    efficiency: point.efficiency,
    efficiencyUnit: point.efficiencyUnit,
    absoluteLoss: point.absoluteLoss,
    absoluteLossUnit: point.absoluteLossUnit,
    tooltipContent: getTooltip(point, shape),
    pointLabel: getPointLabel(point),
    pointLabelFontSize: type == 'small' ? '10' : '14',
    pointColor:
      shape === STD_SHAPE
        ? STD_SHAPE_COLOR
        : shape == PART_LOAD_SHAPE
        ? PART_LOAD_SHAPE_COLOR
        : '',
    customShape: shape === PART_LOAD_SHAPE ? partLoadShape : '',
  }));
};

interface ILossChart {
  partloadPoints?: MotorStandardPoints[];
  standardPoints?: MotorStandardPoints[];
  loading?: boolean;
  type: 'small' | 'large';
  resultsLoading?: boolean;
  height?: number;
  width?: number;
  id?: string;
}

export const MotorLossChart: React.FC<ILossChart> = ({
  standardPoints,
  partloadPoints,
  loading,
  type,
  resultsLoading,
  height,
  width,
  id,
}) => {
  const data1Color = STD_SHAPE_COLOR;
  const standardPointsSorted = standardPoints
    ?.slice()
    .sort(
      (point1, point2) =>
        parseInt(point1.speed) - parseInt(point2.speed) ||
        parseInt(point1.torque) - parseInt(point2.torque)
    );
  const formattedStandardPoints = standardPoints
    ? formatPointsForChart(
        standardPointsSorted ? standardPointsSorted : [],
        STD_SHAPE,
        type
      )
    : [];
  const formattedPartloadPoints = partloadPoints
    ? formatPointsForChart(partloadPoints, PART_LOAD_SHAPE, type)
    : [];

  const coordinates = formattedPartloadPoints
    ? formattedStandardPoints?.concat(formattedPartloadPoints)
    : formattedStandardPoints;

  const isReportModalOpen = useSelector(
    (state: RootState) => state.workspace.isReportModalOpen
  );

  return (
    <>
      <div
        className="scatter-chart-container"
        data-rf="motor-losses-chart"
        style={{ height: `${isReportModalOpen ? `${height}px` : 'initial'}` }}
      >
        <div className="chart-top-section">
          <div className="chart-legends">
            <div>
              <div
                style={{ backgroundColor: data1Color }}
                className="chart-legend-color"
              ></div>
              Standard point
            </div>
            {formattedPartloadPoints.length > 0 ? (
              <div>
                <div className="diamond-narrow "></div>
                User-defined load point
              </div>
            ) : null}
          </div>
        </div>
        {!loading && !resultsLoading && (
          <D3
            coordinates={coordinates ? coordinates : []}
            xAxisLabel="Speed (%)"
            yAxisLabel="Torque (%)"
            id={id ? id : 'Motor-chart'}
            height={height}
            width={width}
            xAxisTicks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            yAxisTicks={[0, 25, 50, 75, 100]}
            darkGridLines={{ xAxis: [25, 50, 90], yAxis: [0, 25, 50, 100] }}
            lightGridLines={{
              xAxis: [0, 10, 20, 30, 40, 60, 70, 80, 100],
              yAxis: [75],
            }}
          />
        )}
      </div>
    </>
  );
};
