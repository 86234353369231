import { useEffect, useState } from 'react';
import { loadPointsHeaders } from '../Utils/PreDefinedLoadPoints';
import { UserDefinedLoadPoints } from '../../models/EfficiencyModel';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';

const UDPTable = () => {
  const [tableData, setTableData] = useState([] as UserDefinedLoadPoints[]);

  const workspace = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
  );
  useEffect(() => {
    if (workspace.efficiencyUDPLossesdata)
      setTableData(workspace.efficiencyUDPLossesdata);
  }, [workspace]);

  return (
    <div style={{ overflow: 'auto' }}>
      <table className="energy-losses-table">
        <thead>
          <tr>
            {loadPointsHeaders.map((data, idx) => {
              if (
                idx === 2 &&
                workspace?.efficiencyUDPData?.torqueOrPower === 'power'
              ) {
                return (
                  <th key="power" id={data + idx}>
                    POWER
                  </th>
                );
              } else {
                return (
                  <th key={data} id={data + idx}>
                    {data}
                  </th>
                );
              }
            })}
          </tr>
        </thead>
        <tbody>
          {tableData.length > 0 &&
            tableData.map((val, index) => {
              let ind = index + 1;
              return (
                <tr key={index} id={ind.toString()}>
                  <td>{ind}</td>
                  <td>{val.userInputSpeed + ' ' + val.speedUnit} </td>
                  <td>{val.userInputTorque + ' ' + val.torqueUnit}</td>
                  <td>
                    {val.data.driveAbsoluteLoss +
                      ' ' +
                      val.data.absoluteLossUnit}
                  </td>
                  <td>
                    {val.data.motorAbsoluteLoss +
                      ' ' +
                      val.data.absoluteLossUnit}
                  </td>
                  <td>
                    {val.data.absoluteLoss + ' ' + val.data.absoluteLossUnit}
                  </td>
                  <td>
                    {val.data.relativeLoss + ' ' + val.data.relativeLossUnit}
                  </td>
                  <td>{val.data.efficiency + ' ' + val.data.efficiencyUnit}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default UDPTable;
