//This is auto generated enum
export enum I18 {
  app_productname = 'app_productname',
  app_header_login = 'app_header_login',
  app_header_signout = 'app_header_signout',
  app_support = 'app_support',
  app_footer_disclaimer = 'app_footer_disclaimer',
  app_footer_software_licenses = 'app_footer_software_licenses',
  home_login_msg = 'home_login_msg',
  home_create_project = 'home_create_project',
  create_project_desciption = 'create_project_desciption',
  home_project_planning_options = 'home_project_planning_options',
  home_selection_tab = 'home_selection_tab',
  home_performance_tab = 'home_performance_tab',
  home_configuration_tab = 'home_configuration_tab',
  home_documents_tab = 'home_documents_tab',
  home_selection_tab_dimension_card = 'home_selection_tab_dimension_card',
  home_selection_tab_selection_card = 'home_selection_tab_selection_card',
  home_selection_tab_upgrade_card = 'home_selection_tab_upgrade_card',
  home_selection_tab_efficiency_card = 'home_selection_tab_efficiency_card',
  home_selection_tab_hme_card = 'home_selection_tab_hme_card',
  home_selection_tab_energysave_card = 'home_selection_tab_energysave_card',
  home_selection_tab_confdrive_card = 'home_selection_tab_confdrive_card',
  home_selection_tab_confmotor_card = 'home_selection_tab_confmotor_card',
  home_selection_tab_confplc_card = 'home_selection_tab_confplc_card',
  home_selection_card_dimension_description = 'home_selection_card_dimension_description',
  home_selection_card_selection_description = 'home_selection_card_selection_description',
  home_selection_card_upgrade_description = 'home_selection_card_upgrade_description',
  home_selection_card_efficiency_description = 'home_selection_card_efficiency_description',
  home_selection_card_hme_description = 'home_selection_card_hme_description',
  home_selection_card_energysave_description = 'home_selection_card_energysave_description',
  home_selection_card_confdrive_description = 'home_selection_card_confdrive_description',
  home_selection_card_confmotor_description = 'home_selection_card_confmotor_description',
  home_selection_card_confplc_description = 'home_selection_card_confplc_description',
  modal_drives_motors_title = 'modal_drives_motors_title',
  modal_drive_size_title = 'modal_drive_size_title',
  modal_drive_upgrade_title = 'modal_drive_upgrade_title',
  modal_drives_motors_description = 'modal_drives_motors_description',
  modal_drive_size_description = 'modal_drive_size_description',
  modal_drive_upgrade_description = 'modal_drive_upgrade_description',
  modal_echo_design_title = 'modal_echo_design_title',
  modal_harmonic_estimator_title = 'modal_harmonic_estimator_title',
  modal_energy_save_title = 'modal_energy_save_title',
  modal_echo_design_description = 'modal_echo_design_description',
  modal_harmonic_estimator_description = 'modal_harmonic_estimator_description',
  modal_energy_save_description = 'modal_energy_save_description',
  modal_open_button = 'modal_open_button',
  modal_cancel_button = 'modal_cancel_button',
  login_modal_title = 'login_modal_title',
  login_modal_description = 'login_modal_description',
  modal_login_button = 'modal_login_button',
  modal_login_skip_button = 'modal_login_skip_button',
  modal_selection_title = 'modal_selection_title',
  modal_selection_create_project_title = 'modal_selection_create_project_title',
  modal_selection_project_name_label = 'modal_selection_project_name_label',
  modal_selection_project_name_placeholder = 'modal_selection_project_name_placeholder',
  modal_selection_from_country_label = 'modal_selection_from_country_label',
  modal_selection_country_label = 'modal_selection_country_label',
  modal_selection_country_description = 'modal_selection_country_description',
  modal_selection_destination_label = 'modal_selection_destination_label',
  modal_selection_destination_country_description = 'modal_selection_destination_country_description',
  modal_selection_standard_label = 'modal_selection_standard_label',
  modal_selection_standard_placeholder = 'modal_selection_standard_placeholder',
  modal_selection_unit_system_label = 'modal_selection_unit_system_label',
  modal_selection_unit_system_placeholder = 'modal_selection_unit_system_placeholder',
  modal_selection_cancel_button = 'modal_selection_cancel_button',
  modal_selection_create_project_button = 'modal_selection_create_project_button',
  home_selection_device_document_tab_device_type_label = 'home_selection_device_document_tab_device_type_label',
  home_selection_device_document_tab_device_type_placeholder = 'home_selection_device_document_tab_device_type_placeholder',
  home_selection_device_document_tab_get_document_button = 'home_selection_device_document_tab_get_document_button',
  home_dashboard_title = 'home_dashboard_title',
  home_dashboard_description = 'home_dashboard_description',
  home_dashboard_new_project_button = 'home_dashboard_new_project_button',
  home_dashboard_support_card_title = 'home_dashboard_support_card_title',
  home_dashboard_support_card_description = 'home_dashboard_support_card_description',
  home_dashboard_furthur_reading_title = 'home_dashboard_furthur_reading_title',
  home_dashboard_furthur_reading_article_title = 'home_dashboard_furthur_reading_article_title',
  home_dashboard_furthur_reading_article_subtitle = 'home_dashboard_furthur_reading_article_subtitle',
  home_dashboard_furthur_reading_updated_day_ago_label = 'home_dashboard_furthur_reading_updated_day_ago_label',
  home_dashboard_furthur_reading_article_description = 'home_dashboard_furthur_reading_article_description',
  home_dashboard_furthur_reading_video_title = 'home_dashboard_furthur_reading_video_title',
  home_dashboard_furthur_reading_video_subtitle = 'home_dashboard_furthur_reading_video_subtitle',
  home_dashboard_furthur_reading_video_description = 'home_dashboard_furthur_reading_video_description',
  home_dashboard_furthur_reading_brochure_title = 'home_dashboard_furthur_reading_brochure_title',
  home_dashboard_furthur_reading_brochure_subtitle = 'home_dashboard_furthur_reading_brochure_subtitle',
  home_dashboard_furthur_reading_brochure_description = 'home_dashboard_furthur_reading_brochure_description',
  project_article_card_title = 'project_article_card_title',
  project_article_card_subtitle = 'project_article_card_subtitle',
  project_article_card_updated_day_ago_label = 'project_article_card_updated_day_ago_label',
  project_article_card_description = 'project_article_card_description',
  workspace_segment_cards_industrial_drives_title = 'workspace_segment_cards_industrial_drives_title',
  workspace_segment_cards_industrial_drives_description = 'workspace_segment_cards_industrial_drives_description',
  workspace_segment_cards_industrial_multidrives_title = 'workspace_segment_cards_industrial_multidrives_title',
  workspace_segment_cards_industrial_multidrives_description = 'workspace_segment_cards_industrial_multidrives_description',
  workspace_segment_cards_general_purpose_drives_title = 'workspace_segment_cards_general_purpose_drives_title',
  workspace_segment_cards_general_purpose_drives_description = 'workspace_segment_cards_general_purpose_drives_description',
  workspace_segment_cards_drives_for_hvac_and_water_title = 'workspace_segment_cards_drives_for_hvac_and_water_title',
  workspace_segment_cards_drives_for_hvac_and_water_description = 'workspace_segment_cards_drives_for_hvac_and_water_description',
  workspace_segment_cards_machinery_drives_title = 'workspace_segment_cards_machinery_drives_title',
  workspace_segment_cards_machinery_drives_description = 'workspace_segment_cards_machinery_drives_description',
  workspace_segment_cards_motion_control_drives_title = 'workspace_segment_cards_motion_control_drives_title',
  workspace_segment_cards_motion_control_drives_description = 'workspace_segment_cards_motion_control_drives_description',
  workspace_segment_cards_single_drive_combination_title = 'workspace_segment_cards_single_drive_combination_title',
  sidebar_temparature_unit_label = 'sidebar_temparature_unit_label',
  sidebar_altitude_unit_label = 'sidebar_altitude_unit_label',
  sidebar_iec_label = 'sidebar_iec_label',
  sidebar_altitude_label = 'sidebar_altitude_label',
  sidebar_ok_button = 'sidebar_ok_button',
  sidebar_add_system_requirement_button = 'sidebar_add_system_requirement_button',
  workspace_tab_selection_dimensioning_title = 'workspace_tab_selection_dimensioning_title',
  workspace_tab_efficiency_title = 'workspace_tab_efficiency_title',
  home_footer_privacy_policy_label = 'home_footer_privacy_policy_label',
  home_footer_copyright_label = 'home_footer_copyright_label',
  workspace_selection_method_title = 'workspace_selection_method_title',
  workspace_selection_guide_card_title = 'workspace_selection_guide_card_title',
  workspace_selection_guide_card_description = 'workspace_selection_guide_card_description',
  workspace_selection_guide_card_start_selecting_button = 'workspace_selection_guide_card_start_selecting_button',
  workspace_segment_cards_title = 'workspace_segment_cards_title',
  drive_selection_title = 'drive_selection_title',
  drive_selection_drive_load_title = 'drive_selection_drive_load_title',
  drive_selection_i_continuous_label = 'drive_selection_i_continuous_label',
  current_unit_label = 'current_unit_label',
  time_second_unit_label = 'time_second_unit_label',
  speed_unit_label = 'speed_unit_label',
  percentage_unit_label = 'percentage_unit_label',
  drive_selection_caluculated_value_label = 'drive_selection_caluculated_value_label',
  drive_selection_i_maximum_label = 'drive_selection_i_maximum_label',
  drive_selection_time_label = 'drive_selection_time_label',
  drive_selection_every_label = 'drive_selection_every_label',
  drive_selection_overload_title = 'drive_selection_overload_title',
  drive_selection_add_duty_cycle_button = 'drive_selection_add_duty_cycle_button',
  drive_selection_drive_specifications_title = 'drive_selection_drive_specifications_title',
  drive_selection_drive_specifications_name_label = 'drive_selection_drive_specifications_name_label',
  drive_selection_drive_specifications_no_of_drives_label = 'drive_selection_drive_specifications_no_of_drives_label',
  drive_selection_select_drive_button = 'drive_selection_select_drive_button',
  drive_selection_select_all_button = 'drive_selection_select_all_button',
  drive_selection_manual_selection_button = 'drive_selection_manual_selection_button',
  drive_selection_result_title = 'drive_selection_result_title',
  drive_selection_result_efficiency_button = 'drive_selection_result_efficiency_button',
  drive_selection_result_subtitle = 'drive_selection_result_subtitle',
  drive_selection_result_typecode_label = 'drive_selection_result_typecode_label',
  motor_selection_title = 'motor_selection_title',
  motor_selection_motor_load_title = 'motor_selection_motor_load_title',
  motor_selection_motor_load_min_label = 'motor_selection_motor_load_min_label',
  motor_selection_motor_load_base_label = 'motor_selection_motor_load_base_label',
  motor_selection_motor_load_max_label = 'motor_selection_motor_load_max_label',
  motor_selection_motor_load_speed_label = 'motor_selection_motor_load_speed_label',
  motor_selection_motor_load_power_label = 'motor_selection_motor_load_power_label',
  motor_selection_motor_load_overload_label = 'motor_selection_motor_load_overload_label',
  motor_selection_motor_load_speed_description = 'motor_selection_motor_load_speed_description',
  motor_selection_motor_load_power_description = 'motor_selection_motor_load_power_description',
  motor_selection_motor_load_overload_description = 'motor_selection_motor_load_overload_description',
  motor_selection_motor_load_overload_time_label = 'motor_selection_motor_load_overload_time_label',
  motor_selection_motor_load_overload_every_label = 'motor_selection_motor_load_overload_every_label',
  motor_selection_add_duty_cycle_button = 'motor_selection_add_duty_cycle_button',
  motor_selection_motor_specification_title = 'motor_selection_motor_specification_title',
  motor_selection_motor_specifications_name_label = 'motor_selection_motor_specifications_name_label',
  motor_selection_motor_specifications_no_of_motors_label = 'motor_selection_motor_specifications_no_of_motors_label',
  motor_selection_select_motors_button = 'motor_selection_select_motors_button',
  motor_selection_select_all_button = 'motor_selection_select_all_button',
  motor_selection_manual_selection_button = 'motor_selection_manual_selection_button',
  motor_selection_select_button = 'motor_selection_select_button',
  motor_selection_result_button = 'motor_selection_result_button',
  dimensioning_tab_motor_title = 'dimensioning_tab_motor_title',
  dimensioning_tab_drive_title = 'dimensioning_tab_drive_title',
  motor_selection_result_efficiency_button = 'motor_selection_result_efficiency_button',
  motor_selection_result_title = 'motor_selection_result_title',
  motor_selection_result_subtitle = 'motor_selection_result_subtitle',
  motor_selection_result_typecode_label = 'motor_selection_result_typecode_label',
  motor_selection_result_productcode_label = 'motor_selection_result_productcode_label',
  motor_selection_result_catalogue_data_label = 'motor_selection_result_catalogue_data_label',
  motor_selection_result_selection_data_label = 'motor_selection_result_selection_data_label',
  motor_selection_result_vsd_rating_label = 'motor_selection_result_vsd_rating_label',
  motor_selection_result_required_label = 'motor_selection_result_required_label',
  motor_selection_result_result_label = 'motor_selection_result_result_label',
  motor_selection_result_margin_label = 'motor_selection_result_margin_label',
  motor_selection_result_torque_label = 'motor_selection_result_torque_label',
  motor_selection_result_nmin_label = 'motor_selection_result_nmin_label',
  motor_selection_result_nbase_label = 'motor_selection_result_nbase_label',
  motor_selection_result_power_label = 'motor_selection_result_power_label',
  motor_selection_result_overload_label = 'motor_selection_result_overload_label',
  motor_selection_result_min_speed_label = 'motor_selection_result_min_speed_label',
  motor_selection_result_base_speed_label = 'motor_selection_result_base_speed_label',
  motor_selection_result_voltage_label = 'motor_selection_result_voltage_label',
  motor_selection_result_frequency_label = 'motor_selection_result_frequency_label',
  motor_selection_result_output_power_label = 'motor_selection_result_output_power_label',
  motor_selection_result_speed_label = 'motor_selection_result_speed_label',
  motor_selection_result_current_label = 'motor_selection_result_current_label',
  motor_selection_result_load_torque_label = 'motor_selection_result_load_torque_label',
  motor_drive_selection_modal_add_duty_cycle_title = 'motor_drive_selection_modal_add_duty_cycle_title',
  motor_drive_selection_modal_rms_label = 'motor_drive_selection_modal_rms_label',
  motor_drive_selection_modal_rms10_label = 'motor_drive_selection_modal_rms10_label',
  motor_drive_selection_modal_highest_load_label = 'motor_drive_selection_modal_highest_load_label',
  motor_drive_selection_modal_base_power_label = 'motor_drive_selection_modal_base_power_label',
  motor_drive_selection_modal_cont_load_label = 'motor_drive_selection_modal_cont_load_label',
  motor_drive_selection_modal_power_overload_label = 'motor_drive_selection_modal_power_overload_label',
  motor_drive_selection_modal_radiobutton_torque_label = 'motor_drive_selection_modal_radiobutton_torque_label',
  motor_drive_selection_modal_radiobutton_power_label = 'motor_drive_selection_modal_radiobutton_power_label',
  motor_drive_selection_modal_load_points_title = 'motor_drive_selection_modal_load_points_title',
  motor_drive_selection_modal_table_description_title = 'motor_drive_selection_modal_table_description_title',
  motor_drive_selection_modal_table_time_title = 'motor_drive_selection_modal_table_time_title',
  motor_drive_selection_modal_table_min_speed_title = 'motor_drive_selection_modal_table_min_speed_title',
  motor_drive_selection_modal_table_max_speed_title = 'motor_drive_selection_modal_table_max_speed_title',
  motor_drive_selection_modal_table_load_title = 'motor_drive_selection_modal_table_load_title',
  motor_drive_selection_modal_table_torque_title = 'motor_drive_selection_modal_table_torque_title',
  motor_drive_selection_modal_table_power_title = 'motor_drive_selection_modal_table_power_title',
  motor_drive_selection_modal_table_base_load_title = 'motor_drive_selection_modal_table_base_load_title',
  motor_drive_selection_modal_table_over_load_title = 'motor_drive_selection_modal_table_over_load_title',
  motor_drive_selection_modal_add_over_load_button = 'motor_drive_selection_modal_add_over_load_button',
  motor_drive_selection_modal_load_graph_title = 'motor_drive_selection_modal_load_graph_title',
  motor_drive_selection_modal_current_graph_title = 'motor_drive_selection_modal_current_graph_title',
  motor_drive_selection_modal_cancel_button = 'motor_drive_selection_modal_cancel_button',
  motor_drive_selection_modal_apply_button = 'motor_drive_selection_modal_apply_button',
  motor_selection_motor_load_one_time_ol_title = 'motor_selection_motor_load_one_time_ol_title',
  motor_selection_motor_load_one_time_ol_time_label = 'motor_selection_motor_load_one_time_ol_time_label',
  motor_selection_motor_load_one_time_ol_max_speed_label = 'motor_selection_motor_load_one_time_ol_max_speed_label',
  motor_selection_notification_power_non_zero_label = 'motor_selection_notification_power_non_zero_label',
  motor_selection_notification_one_time_overload_at_start_label = 'motor_selection_notification_one_time_overload_at_start_label',
  drive_duty_cycle_modal_table_min_f_title = 'drive_duty_cycle_modal_table_min_f_title',
  drive_duty_cycle_modal_table_max_f_title = 'drive_duty_cycle_modal_table_max_f_title',
  drive_duty_cycle_modal_table_current_title = 'drive_duty_cycle_modal_table_current_title',
  drive_duty_cycle_modal_table_cosfi_title = 'drive_duty_cycle_modal_table_cosfi_title',
  drive_selection_select_button = 'drive_selection_select_button',
  drive_selection_result_button = 'drive_selection_result_button',
  drive_duty_cycle_graph_y_axis_title = 'drive_duty_cycle_graph_y_axis_title',
  efficiency_heading = 'efficiency_heading',
  pds_heading = 'pds_heading',
  pds_class_info = 'pds_class_info',
  cdm_losses_heading = 'cdm_losses_heading',
  pds_efficiency = 'pds_efficiency',
  motor_losses_result_heading = 'motor_losses_result_heading',
  drive_losses_result_heading = 'drive_losses_result_heading',
  user_load_points_heading = 'user_load_points_heading',
  choose_operation_point_type = 'choose_operation_point_type',
  custom_points = 'custom_points',
  speed = 'speed',
  torque = 'torque',
  pds_absolute_losses_heading = 'pds_absolute_losses_heading',
  cdm_absolute_losses_heading = 'cdm_absolute_losses_heading',
  standard_and_predifined_points = 'standard_and_predifined_points',
  standard_points_regulatory_msg = 'standard_points_regulatory_msg',
  user_defined_load_points = 'user_defined_load_points',
  absolute_lossed = 'absolute_lossed',
  cdm_losses = 'cdm_losses',
  add = 'add',
  motor_data_heading = 'motor_data_heading',
  drive_data_heading = 'drive_data_heading',
  efficiency_selected_motor_technical_data = 'efficiency_selected_motor_technical_data',
  efficiency_selected_drive_technical_data = 'efficiency_selected_drive_technical_data',
  efficiency_motor_range = 'efficiency_motor_range',
  efficiency_motor_type_designatione = 'efficiency_motor_type_designatione',
  efficiency_motor_efficiency_class = 'efficiency_motor_efficiency_class',
  efficiency_motor_output = 'efficiency_motor_output',
  efficiency_motor_Voltage = 'efficiency_motor_Voltage',
  efficiency_motor_speed = 'efficiency_motor_speed',
  efficiency_motor_current = 'efficiency_motor_current',
  efficiency_motor_product_code = 'efficiency_motor_product_code',
  efficiency_motor_voltage_code = 'efficiency_motor_voltage_code',
  efficiency_motor_frequency = 'efficiency_motor_frequency',
  efficiency_motor_torque = 'efficiency_motor_torque',
  efficiency_motor_efficiency = 'efficiency_motor_efficiency',
  efficiency_motor_meps = 'efficiency_motor_meps',
  efficiency_motor_calcid = 'efficiency_motor_calcid',
  efficiency_drive_type_code = 'efficiency_drive_type_code',
  efficiency_drive_nominal_apparent_power = 'efficiency_drive_nominal_apparent_power',
  efficiency_drive_nominal_voltage_range = 'efficiency_drive_nominal_voltage_range',
  efficiency_drive_nominal_power = 'efficiency_drive_nominal_power',
  efficiency_drive_nominal_current = 'efficiency_drive_nominal_current',
  efficiency_drive_nominal_frequency = 'efficiency_drive_nominal_frequency',
  reports_button = 'reports_button',
  report_modal_header = 'report_modal_header',
  reports_generate_reports = 'reports_generate_reports',
  reports_report_title = 'reports_report_title',
  reports_additional_comments = 'reports_additional_comments',
  share = 'share',
  print = 'print',
  export = 'export',
  report_header = 'report_header',
  reports_project_name = 'reports_project_name',
  reports_list_of_projects = 'reports_list_of_projects',
  reports_project_information = 'reports_project_information',
  reports_customer = 'reports_customer',
  reports_project = 'reports_project',
  reports_country = 'reports_country',
  reports_destinantion_country = 'reports_destinantion_country',
  reports_revision = 'reports_revision',
  reports_created_by = 'reports_created_by',
  reports_standard = 'reports_standard',
  reports_last_modified = 'reports_last_modified',
  efficiency_motor_icClass = 'efficiency_motor_icClass',
  efficiency_drive_stand_by_losses = 'efficiency_drive_stand_by_losses',
  efficiency_user_defined_load_points_header = 'efficiency_user_defined_load_points_header',
  efficiency_losses_data_heading = 'efficiency_losses_data_heading',
  efficiency_losses_data_rated_power = 'efficiency_losses_data_rated_power',
  efficiency_losses_data_relative_losses = 'efficiency_losses_data_relative_losses',
  efficiency_losses_data_absolute_losses = 'efficiency_losses_data_absolute_losses',
  efficiency_standard_points_header = 'efficiency_standard_points_header',
  efficiency_losses_data_pds_heading = 'efficiency_losses_data_pds_heading',
  efficiency_losses_data_pds_subtext1 = 'efficiency_losses_data_pds_subtext1',
  efficiency_losses_data_pds_subtext2 = 'efficiency_losses_data_pds_subtext2',
  efficiency_losses_data_pds_ieclass_heading = 'efficiency_losses_data_pds_ieclass_heading',
  efficiency_losses_data_pds_efficiency_label = 'efficiency_losses_data_pds_efficiency_label',
  efficiency_losses_data_pds_description_strong = 'efficiency_losses_data_pds_description_strong',
  efficiency_losses_data_pds_description = 'efficiency_losses_data_pds_description',
  efficiency_losses_data_cdm_header = 'efficiency_losses_data_cdm_header',
  efficiency_losses_data_cdm_subtext1 = 'efficiency_losses_data_cdm_subtext1',
  efficiency_losses_data_cdm_subtext2 = 'efficiency_losses_data_cdm_subtext2',
  efficiency_losses_data_cdm_ieclass = 'efficiency_losses_data_cdm_ieclass',
  efficiency_losses_data_cdm_efficiency_label = 'efficiency_losses_data_cdm_efficiency_label',
  efficiency_losses_data_cdm_typecode = 'efficiency_losses_data_cdm_typecode',
  efficiency_losses_data_cdm_rated_apparent_power = 'efficiency_losses_data_cdm_rated_apparent_power',
  efficiency_losses_data_cdm_rated_current = 'efficiency_losses_data_cdm_rated_current',
  efficiency_losses_data_cdm_description_strong = 'efficiency_losses_data_cdm_description_strong',
  efficiency_losses_data_cdm_description = 'efficiency_losses_data_cdm_description',
  efficiency_losses_data_cdm_description_sub = 'efficiency_losses_data_cdm_description_sub',
  efficiency_losses_data_motor_header = 'efficiency_losses_data_motor_header',
  efficiency_losses_data_motor_subtext1 = 'efficiency_losses_data_motor_subtext1',
  efficiency_losses_data_motor_subtext2 = 'efficiency_losses_data_motor_subtext2',
  efficiency_losses_data_motor_ieclass = 'efficiency_losses_data_motor_ieclass',
  efficiency_losses_data_motor_efficiency_label = 'efficiency_losses_data_motor_efficiency_label',
  efficiency_losses_data_motor_type_designation = 'efficiency_losses_data_motor_type_designation',
  efficiency_losses_data_motor_speed = 'efficiency_losses_data_motor_speed',
  efficiency_losses_data_motor_description_strong = 'efficiency_losses_data_motor_description_strong',
  efficiency_losses_data_motor_rated_power = 'efficiency_losses_data_motor_rated_power',
  efficiency_losses_data_motor_description = 'efficiency_losses_data_motor_description',
  efficiency_losses_motor_page_loss_determination_heading = 'efficiency_losses_motor_page_loss_determination_heading',
  efficiency_losses_motor_page_loss_determination = 'efficiency_losses_motor_page_loss_determination',
  efficiency_losses_motor_page_loss_determination_sub = 'efficiency_losses_motor_page_loss_determination_sub',
  efficiency_losses_motor_page_disclaimer_heading = 'efficiency_losses_motor_page_disclaimer_heading',
  efficiency_losses_page_disclaimer = 'efficiency_losses_page_disclaimer',
  efficiency_losses_motor_page_side_note_strong = 'efficiency_losses_motor_page_side_note_strong',
  efficiency_losses_motor_page_side_note = 'efficiency_losses_motor_page_side_note',
  efficiency_losses_motor_page_heading = 'efficiency_losses_motor_page_heading',
  efficiency_losses_drive_page_heading = 'efficiency_losses_drive_page_heading',
  efficiency_losses_drive_page_ieclass_heading = 'efficiency_losses_drive_page_ieclass_heading',
  efficiency_losses_drive_page_classification_strong = 'efficiency_losses_drive_page_classification_strong',
  efficiency_losses_drive_page_classification = 'efficiency_losses_drive_page_classification',
  efficiency_losses_drive_page_relative_loss_strong = 'efficiency_losses_drive_page_relative_loss_strong',
  efficiency_losses_drive_page_relative_loss = 'efficiency_losses_drive_page_relative_loss',
  efficiency_losses_drive_page_cdm_loss_strong = 'efficiency_losses_drive_page_cdm_loss_strong',
  efficiency_losses_drive_page_cdm_loss = 'efficiency_losses_drive_page_cdm_loss',
  efficiency_losses_drive_page_cdm_loss_determination = 'efficiency_losses_drive_page_cdm_loss_determination',
  efficiency_losses_drive_page_cdm_loss_heading = 'efficiency_losses_drive_page_cdm_loss_heading',
  efficiency_losses_drive_page_cdm_loss_1 = 'efficiency_losses_drive_page_cdm_loss_1',
  efficiency_losses_drive_page_cdm_loss_1_a = 'efficiency_losses_drive_page_cdm_loss_1_a',
  efficiency_losses_drive_page_cdm_loss_1_a_sub = 'efficiency_losses_drive_page_cdm_loss_1_a_sub',
  efficiency_losses_drive_page_cdm_loss_1_a_strong = 'efficiency_losses_drive_page_cdm_loss_1_a_strong',
  efficiency_losses_drive_page_cdm_loss_1_b = 'efficiency_losses_drive_page_cdm_loss_1_b',
  efficiency_losses_drive_page_cdm_loss_1_b_sub = 'efficiency_losses_drive_page_cdm_loss_1_b_sub',
  efficiency_losses_drive_page_cdm_loss_1_b_strong = 'efficiency_losses_drive_page_cdm_loss_1_b_strong',
  efficiency_losses_drive_page_cdm_loss_1_c = 'efficiency_losses_drive_page_cdm_loss_1_c',
  efficiency_losses_drive_page_cdm_loss_1_c_sub = 'efficiency_losses_drive_page_cdm_loss_1_c_sub',
  efficiency_losses_drive_page_cdm_loss_1_c_strong = 'efficiency_losses_drive_page_cdm_loss_1_c_strong',
  efficiency_losses_drive_page_cdm_loss_1_d = 'efficiency_losses_drive_page_cdm_loss_1_d',
  efficiency_losses_drive_page_cdm_loss_1_d_sub = 'efficiency_losses_drive_page_cdm_loss_1_d_sub',
  efficiency_losses_drive_page_cdm_loss_1_d_strong = 'efficiency_losses_drive_page_cdm_loss_1_d_strong',
  efficiency_losses_drive_page_cdm_loss_1_e = 'efficiency_losses_drive_page_cdm_loss_1_e',
  efficiency_losses_drive_page_cdm_loss_1_e_sub = 'efficiency_losses_drive_page_cdm_loss_1_e_sub',
  efficiency_losses_drive_page_cdm_loss_1_e_strong = 'efficiency_losses_drive_page_cdm_loss_1_e_strong',
  efficiency_losses_drive_page_cdm_loss_1_f = 'efficiency_losses_drive_page_cdm_loss_1_f',
  efficiency_losses_drive_page_cdm_loss_1_g = 'efficiency_losses_drive_page_cdm_loss_1_g',
  efficiency_losses_drive_page_cdm_loss_1_h = 'efficiency_losses_drive_page_cdm_loss_1_h',
  efficiency_losses_drive_page_cdm_loss_2 = 'efficiency_losses_drive_page_cdm_loss_2',
  efficiency_losses_drive_page_cdm_loss_2_sub = 'efficiency_losses_drive_page_cdm_loss_2_sub',
  efficiency_losses_drive_page_cdm_loss_3 = 'efficiency_losses_drive_page_cdm_loss_3',
  efficiency_losses_drive_page_cdm_loss_4 = 'efficiency_losses_drive_page_cdm_loss_4',
  efficiency_losses_drive_page_cdm_loss_5 = 'efficiency_losses_drive_page_cdm_loss_5',
  efficiency_losses_drive_page_cdm_loss_6 = 'efficiency_losses_drive_page_cdm_loss_6',
  efficiency_losses_drive_page_cdm_loss_7 = 'efficiency_losses_drive_page_cdm_loss_7',
  efficiency_losses_drive_page_cdm_loss_8 = 'efficiency_losses_drive_page_cdm_loss_8',
  efficiency_losses_drive_page_cdm_loss_8_output = 'efficiency_losses_drive_page_cdm_loss_8_output',
  efficiency_losses_drive_page_cdm_loss_8_output_end = 'efficiency_losses_drive_page_cdm_loss_8_output_end',
  efficiency_losses_drive_page_cdm_loss_conclusion = 'efficiency_losses_drive_page_cdm_loss_conclusion',
  efficiency_losses_motor_page_table_header = 'efficiency_losses_motor_page_table_header',
  efficiency_losses_motor_page_graph_header = 'efficiency_losses_motor_page_graph_header',
  efficiency_losses_motor_page_notification = 'efficiency_losses_motor_page_notification',
  efficiency_losses_drive_page_notification = 'efficiency_losses_drive_page_notification',
  efficiency_operating_points_table_regulation = 'efficiency_operating_points_table_regulation',
  efficiency_pds_loss_determination_header = 'efficiency_pds_loss_determination_header',
  efficiency_pds_loss_determination = 'efficiency_pds_loss_determination',
  efficiency_cdm_loss_determination_header = 'efficiency_cdm_loss_determination_header',
  efficiency_cdm_loss_determination = 'efficiency_cdm_loss_determination',
  efficiency_lossespds_loss_1_e_strong = 'efficiency_lossespds_loss_1_e_strong',
  efficiency_losses_drive_page_standard = 'efficiency_losses_drive_page_standard',
  efficiency_losses_drive_page_relative_loss_description_strong = 'efficiency_losses_drive_page_relative_loss_description_strong',
  efficiency_losses_drive_page_relative_loss_description = 'efficiency_losses_drive_page_relative_loss_description',
  efficiency_losses_drive_page_efficiency_of_cdm_description_strong = 'efficiency_losses_drive_page_efficiency_of_cdm_description_strong',
  efficiency_losses_drive_page_efficiency_of_cdm_description = 'efficiency_losses_drive_page_efficiency_of_cdm_description',
  efficiency_losses_drive_page_efficiency_of_cdm_description_sub1 = 'efficiency_losses_drive_page_efficiency_of_cdm_description_sub1',
  efficiency_losses_drive_page_efficiency_of_cdm_description_sub2 = 'efficiency_losses_drive_page_efficiency_of_cdm_description_sub2',
  efficiency_losses_drive_page_standard_points_table_heading = 'efficiency_losses_drive_page_standard_points_table_heading',
  footer_link_provider_information = 'footer_link_provider_information',
  footer_link_legal = 'footer_link_legal',
  footer_link_version = 'footer_link_version',
  footer_link_privacy_notice = 'footer_link_privacy_notice',
  footer_link_acceptable_use_policy = 'footer_link_acceptable_use_policy',
  footer_link_change_cookie_setting = 'footer_link_change_cookie_setting',
  footer_info_licenses = 'footer_info_licenses',
  footer_info_version_title = 'footer_info_version_title',
  footer_legal_software_licenses = 'footer_legal_software_licenses',
  footer_legal_terms_of_use_body = 'footer_legal_terms_of_use_body',
  footer_legal_terms_of_use_title = 'footer_legal_terms_of_use_title',
  fotoer_info_versions_body = 'fotoer_info_versions_body',
  privacy_banner_refuseall_button_title = 'privacy_banner_refuseall_button_title',
  privacy_banner_acceptselected_button_title = 'privacy_banner_acceptselected_button_title',
  privacy_banner_acceptall_button_title = 'privacy_banner_acceptall_button_title',
  privacy_banner_perferences_body = 'privacy_banner_perferences_body',
  privacy_banner_preference_title = 'privacy_banner_preference_title',
  privacy_banner_analytics_text = 'privacy_banner_analytics_text',
  privacy_banner_analytics_title = 'privacy_banner_analytics_title',
  privacy_banner_body_general_text = 'privacy_banner_body_general_text',
  privacy_banner_body_general_text_link = 'privacy_banner_body_general_text_link',
  privacy_banner_title = 'privacy_banner_title',
  efficiency_user_defined_load_points_chart_header = 'efficiency_user_defined_load_points_chart_header',
  delete_modal_title = 'delete_modal_title',
  delete_modal_body = 'delete_modal_body',
  cancel_button = 'cancel_button',
  delete_button = 'delete_button',
  open_button = 'open_button',
  save_button = 'save_button',
  leave_button = 'leave_button',
  home_learnings_tab = 'home_learnings_tab',
  home_tools_tab = 'home_tools_tab',
  home_things_to_know_label = 'home_things_to_know_label',
  navigation_block_modal_header = 'navigation_block_modal_header',
  navigation_block_modal_content = 'navigation_block_modal_content',
  homepage_dashboard_description = 'homepage_dashboard_description',
  homepage_info_section = 'homepage_info_section',
  recent_project_block_header = 'recent_project_block_header',
  recent_project_block_description = 'recent_project_block_description',
  no_data_available = 'no_data_available',
  pds_data_unavailable_message = 'pds_data_unavailable_message',
  drive_data_unavailable_message = 'drive_data_unavailable_message',
  motor_data_unavailable_message = 'motor_data_unavailable_message',
  goto_selection_button = 'goto_selection_button',
  retry = 'retry',
  important_note = 'important_note',
  note_for = 'note_for',
  imp_note_udp_tag = 'imp_note_udp_tag',
  imp_note_udp_info = 'imp_note_udp_info',
  imp_note_standard_point_tag = 'imp_note_standard_point_tag',
  imp_note_standard_point_info = 'imp_note_standard_point_info',
  something_went_wrong = 'something_went_wrong',
  retry_info = 'retry_info',
  privacy_banner_chinese_concent = 'privacy_banner_chinese_concent',
  privacy_banner_chinese_data_transfer_concent = 'privacy_banner_chinese_data_transfer_concent',
  goto_application_button = 'goto_application_button',
}
