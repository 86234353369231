import {
  DriveLoadData,
  DriveSelectionData,
  DriveSelectionResultData,
} from './DriveSelectionModels';
import { DropdownData, ResultData } from './MotorSelectionModels';

export const driveTableData: ResultData[] = [
  {
    name: 'Selection',
    value: '-',
    unit: '',
  },
  {
    name: 'SelMethodLongText',
    value: '-',
    unit: '',
  },
  {
    name: 'Voltage',
    value: '-',
    unit: '',
  },
  {
    name: 'Nominal power',
    value: '-',
    unit: '',
  },
  {
    name: 'Nominal current',
    value: '-',
    unit: '',
  },
  {
    name: 'Imax',
    value: '-',
    unit: '',
  },
  {
    name: 'Ihd',
    value: '-',
    unit: '',
  },
  {
    name: 'Frame type',
    value: '-',
    unit: '',
  },
  {
    name: 'CDM IE class',
    value: '-',
    unit: '',
  },
  {
    name: 'Stand-by loss',
    value: '-',
    unit: '',
  },
];

export const defaultDriveLoadData: DriveLoadData = {
  loadType: '1' /* 'Constant torque', */,
  overLoadType: '0' /* 'Simple', */,
  iContinuous: '0',
  dutyCycleTime: '600',
  maxLoadTime: '60',
  iMax: '0',
  calculatedIContinuous: '600',
  calculatedIMaximum: '600',
};

export const defaultDriveFormData: DriveSelectionData = {
  type: '0',
};

export const defaultMachineryDriveFormData: DriveSelectionData = {
  type: '130',
};

export const driveLoadDropDownData: DropdownData[] = [
  {
    label: 'Load type',
    selectInput: [],
    isMultiSelect: false,
    accessor: 'loadType',
    isVisible: true,
  },
  {
    label: 'Overload type',
    selectInput: [],
    isMultiSelect: false,
    isVisible: true,
    accessor: 'overLoadType',
  },
];

export const driveSelectionDropdownData: DropdownData[] = [
  {
    label: 'Drive type',
    selectInput: [],
    isMultiSelect: false,
    isVisible: true,
    accessor: 'driveType',
  },
];

export const driveSelectionAllDataDefault: DriveSelectionResultData = {
  driveLoadData: {
    loadType: '1',
    overLoadType: '0',
    iContinuous: '0',
    dutyCycleTime: '600',
    maxLoadTime: '60',
    iMax: '0',
    calculatedIContinuous: '600',
    calculatedIMaximum: '600',
    driveName: '',
    drivesCount: '1',
  },
  driveSelectionData: {},
  result: {
    typeCode: '',
  },
};

export const driveLoadDefaultData: DriveLoadData = {
  loadType: '1' /* 'Constant torque', */,
  overLoadType: '0' /* 'Simple', */,
  iContinuous: '0',
  dutyCycleTime: '600',
  maxLoadTime: '60',
  iMax: '0',
  calculatedIContinuous: '0',
  calculatedIMaximum: '0',
  Pulse: '0',
};

export const driveLoadHVACAndWaterDefaultData: DriveLoadData = {
  loadType: '3' /* 'Constant torque', */,
  overLoadType: '0' /* 'Simple', */,
  iContinuous: '0',
  dutyCycleTime: '600',
  maxLoadTime: '60',
  iMax: '0',
  calculatedIContinuous: '0',
  calculatedIMaximum: '0',
  Pulse: '0',
};
