export const loadPointsHeaders = [
  'POINT',
  'SPEED',
  'TORQUE',
  'CDM LOSSES',
  'MOTOR LOSSES',
  'PDS ABSOLUTE LOSSES',
  'PDS RELATIVE LOSSES',
  'PDS EFFICIENCY',
];
export const loadPointsHeadersInReportPage = [
  'Point',
  'Speed',
  'Torque',
  'CDM Losses',
  'Motor Losses',
  'PDS Absolute Losses',
  'PDS Relative Losses',
  'PDS Efficiency',
];
export const standardPointsHeaders = [
  'POINT',
  'SPEED',
  'TORQUE',
  'ABSOLUTE LOSSES',
  'RELATIVE LOSSES',
  'EFFICIENCY',
];

export const driveStandardPointsHeader = [
  'POINT',
  'FREQUENCY',
  'CURRENT',
  'ABSOLUTE LOSSES',
  'RELATIVE LOSSES',
  'EFFICIENCY',
];

export const predefinedPoints = [
  {
    POINT: '*1',
    SPEED: '0%',
    TORQUE: '25%',
    'CDM LOSSES': '45W',
    'MOTOR LOSSES': '59W',
    'PDS ABSOLUTE LOSSES': '172W',
    'PDS RELATIVE LOSSES': '4.7%',
    'PDS EFFICIENCY': '65.1%',
  },
  {
    POINT: '*2',
    SPEED: '0%',
    TORQUE: '50%',
    'CDM LOSSES': '45W',
    'MOTOR LOSSES': '59W',
    'PDS ABSOLUTE LOSSES': '172W',
    'PDS RELATIVE LOSSES': '4.7%',
    'PDS EFFICIENCY': '65.1%',
  },
  {
    POINT: '*3',
    SPEED: '0%',
    TORQUE: '100%',
    'CDM LOSSES': '45W',
    'MOTOR LOSSES': '59W',
    'PDS ABSOLUTE LOSSES': '172W',
    'PDS RELATIVE LOSSES': '4.7%',
    'PDS EFFICIENCY': '65.1%',
  },
  {
    POINT: '4',
    SPEED: '50%',
    TORQUE: '25%',
    'CDM LOSSES': '45W',
    'MOTOR LOSSES': '59W',
    'PDS ABSOLUTE LOSSES': '172W',
    'PDS RELATIVE LOSSES': '4.7%',
    'PDS EFFICIENCY': '65.1%',
  },
  {
    POINT: '5',
    SPEED: '50%',
    TORQUE: '50%',
    'CDM LOSSES': '45W',
    'MOTOR LOSSES': '59W',
    'PDS ABSOLUTE LOSSES': '172W',
    'PDS RELATIVE LOSSES': '4.7%',
    'PDS EFFICIENCY': '65.1%',
  },
  {
    POINT: '6',
    SPEED: '50%',
    TORQUE: '100%',
    'CDM LOSSES': '45W',
    'MOTOR LOSSES': '59W',
    'PDS ABSOLUTE LOSSES': '172W',
    'PDS RELATIVE LOSSES': '4.7%',
    'PDS EFFICIENCY': '65.1%',
  },
  {
    POINT: '7',
    SPEED: '90%',
    TORQUE: '50%',
    'CDM LOSSES': '45W',
    'MOTOR LOSSES': '59W',
    'PDS ABSOLUTE LOSSES': '172W',
    'PDS RELATIVE LOSSES': '4.7%',
    'PDS EFFICIENCY': '65.1%',
  },
  {
    POINT: '8',
    SPEED: '90%',
    TORQUE: '100%',
    'CDM LOSSES': '45W',
    'MOTOR LOSSES': '59W',
    'PDS ABSOLUTE LOSSES': '172W',
    'PDS RELATIVE LOSSES': '4.7%',
    'PDS EFFICIENCY': '65.1%',
  },
  {
    POINT: '9',
    SPEED: '24%',
    TORQUE: '25%',
    'CDM LOSSES': '45W',
    'MOTOR LOSSES': '59W',
    'PDS ABSOLUTE LOSSES': '172W',
    'PDS RELATIVE LOSSES': '4.7%',
    'PDS EFFICIENCY': '65.1%',
  },
  {
    POINT: '10',
    SPEED: '25%',
    TORQUE: '50%',
    'CDM LOSSES': '45W',
    'MOTOR LOSSES': '59W',
    'PDS ABSOLUTE LOSSES': '172W',
    'PDS RELATIVE LOSSES': '4.7%',
    'PDS EFFICIENCY': '65.1%',
  },
  {
    POINT: '11',
    SPEED: '25%',
    TORQUE: '100%',
    'CDM LOSSES': '45W',
    'MOTOR LOSSES': '59W',
    'PDS ABSOLUTE LOSSES': '172W',
    'PDS RELATIVE LOSSES': '4.7%',
    'PDS EFFICIENCY': '65.1%',
  },
  {
    POINT: '12',
    SPEED: '90%',
    TORQUE: '25%',
    'CDM LOSSES': '45W',
    'MOTOR LOSSES': '59W',
    'PDS ABSOLUTE LOSSES': '172W',
    'PDS RELATIVE LOSSES': '4.7%',
    'PDS EFFICIENCY': '65.1%',
  },
  {
    POINT: '13',
    SPEED: '90%',
    TORQUE: '50%',
    'CDM LOSSES': '45W',
    'MOTOR LOSSES': '59W',
    'PDS ABSOLUTE LOSSES': '172W',
    'PDS RELATIVE LOSSES': '4.7%',
    'PDS EFFICIENCY': '65.1%',
  },
  {
    POINT: '14',
    SPEED: '90%',
    TORQUE: '100%',
    'CDM LOSSES': '45W',
    'MOTOR LOSSES': '59W',
    'PDS ABSOLUTE LOSSES': '172W',
    'PDS RELATIVE LOSSES': '4.7%',
    'PDS EFFICIENCY': '65.1%',
  },
  {
    POINT: '15',
    SPEED: '100%',
    TORQUE: '25%',
    'CDM LOSSES': '45W',
    'MOTOR LOSSES': '59W',
    'PDS ABSOLUTE LOSSES': '172W',
    'PDS RELATIVE LOSSES': '4.7%',
    'PDS EFFICIENCY': '65.1%',
  },
];

export const operatingPoints = [
  {
    name: 'Custom Points',
    value: 'customPoints',
  },
];

export const driveStandardPointsValues = [
  {
    frequency: 0,
    current: 25,
  },
  {
    frequency: 0,
    current: 50,
  },
  {
    frequency: 0,
    current: 100,
  },
  {
    frequency: 50,
    current: 25,
  },
  {
    frequency: 50,
    current: 50,
  },
  {
    frequency: 50,
    current: 100,
  },
  {
    frequency: 90,
    current: 50,
  },
  {
    frequency: 90,
    current: 100,
  },
];
