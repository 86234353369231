import Button from '../../../../components/Form/Button';
import Modal from '../../../../components/Modal';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import Icon from '../../../../components/Icon';
import { ReselectionConfirmationModalProps } from '../models/TransformerSelectionModel';

const ReselectionConfirmationModal = ({
  modalOpen,
  onClose,
  onConfirmClick,
  contentText,
}: ReselectionConfirmationModalProps) => {
  const { t } = useTranslate();

  const handleConfirmClick = () => {
    onConfirmClick();
    onClose();
  };

  return (
    <>
      <Modal
        open={modalOpen}
        onModalClose={onClose}
        showCloseButton={false}
        type="default"
        showHederSeparator="false"
        show-footer-separator="true"
        backdropClick={false}
        className="reselection-confirmation-modal"
      >
        <div>
          <div className="reselction-confirmation-title-container">
            <div>
              <Icon
                slot="icon"
                size="small"
                name="select"
                className="drive-icon"
              />
            </div>
            <div className="confirmation-title-text">
              {`Reselect applications?`}
            </div>
          </div>
          <div
            id="confirmation-modal-content"
            className="confirmation-modal-content modal-container"
            data-rf="confirmation-modal-content"
          >
            <div className="reselection-confirmation-modal-description">
              {contentText}
            </div>
            <div className="reselection-confirmation-modal-button-container">
              <div className="cancel-button-container">
                <Button
                  type="tertiary"
                  size="small"
                  onClick={onClose}
                  className="cancel-button"
                  data-rf="confirmation-modal-cancel-button"
                >
                  {t(I18.modal_cancel_button)}
                </Button>
              </div>
              <div className="select-button-container">
                <Button
                  type="primary-black"
                  size="small"
                  onClick={handleConfirmClick}
                  className="confirmation-modal-select-button"
                  data-rf="confirmation-modal-select-button"
                >
                  {`Reselect`}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReselectionConfirmationModal;
