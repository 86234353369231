import { loadPointsHeadersInReportPage } from '../../Efficiency/Utils/PreDefinedLoadPoints';
import { I18 } from '../../../../languages/I18';
import { useTranslate } from '../../../../i18translate/Hooks';

const UDPTable = ({ applicationUdpPoints }: any) => {
  const { t } = useTranslate();

  return (
    <>
      <p className="efficiency-page-headers">User-defined load points</p>

      <div>
        <table className="energy-losses-table energy-losses-border">
          <thead>
            <tr>
              {loadPointsHeadersInReportPage.map((data, idx) => {
                return (
                  <th key={data} id={data + idx}>
                    {data}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {applicationUdpPoints.length > 0 &&
              applicationUdpPoints.map((val: any, index: number) => {
                let ind = index + 1;
                return (
                  <tr key={index} id={ind.toString()}>
                    <td>{ind}</td>
                    <td>{`${val?.data?.speed} %`}</td>
                    <td>{`${val?.data?.torque} %`}</td>
                    <td>
                      {val?.data?.driveAbsoluteLoss +
                        ' ' +
                        val?.data?.absoluteLossUnit}
                    </td>
                    <td>
                      {val?.data?.motorAbsoluteLoss +
                        ' ' +
                        val?.data?.absoluteLossUnit}
                    </td>
                    <td>
                      {val?.data?.absoluteLoss +
                        ' ' +
                        val?.data?.absoluteLossUnit}
                    </td>
                    <td>
                      {val?.data?.relativeLoss +
                        ' ' +
                        val?.data?.relativeLossUnit}
                    </td>
                    <td>
                      {val?.data?.efficiency + ' ' + val?.data?.efficiencyUnit}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <div className="efficiency-note report-efficiency-note">
        <div className="efficency-info-icon">
          <img
            src="./assets/icon/information-icon.svg"
            alt="information icon"
          />
        </div>
        <div>
          <span className="bold-imp-note">{t(I18.important_note)}</span>
          <br />
          {t(I18.note_for)}{' '}
          <span className="bold-imp-note"> {t(I18.imp_note_udp_tag)}</span>
          {t(I18.imp_note_udp_info)}
          <br />
          {t(I18.note_for)}{' '}
          <span className="bold-imp-note">
            {' '}
            {t(I18.imp_note_standard_point_tag)}
          </span>
          {t(I18.imp_note_standard_point_info)}
        </div>
      </div>
    </>
  );
};

export default UDPTable;
