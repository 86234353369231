import { DriveStandardPoints } from '../../models/EfficiencyModel';
import React from 'react';
import { D3 } from '../../../../components/D3';
import '../Styles/LossesChart.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';

const STD_SHAPE = 'square';
const PART_LOAD_SHAPE = 'diamond';
const STD_SHAPE_COLOR = 'rgba(27, 122, 194, 1)';
const PART_LOAD_SHAPE_COLOR = 'rgba(181, 170, 13, 1)';
const FZERO50 = 24;
const FZERO60 = 20;

interface ICoordinate {
  index?: number;
  x: number;
  y: number;
  efficiency?: string;
  efficiencyUnit?: string;
  absoluteLoss?: number;
  absoluteLossUnit?: string;
  shape?: string;
  tooltipContent?: string;
  pointLabel?: string;
  pointColor?: string;
  pointLabelFontSize?: string;
  customShape?: any;
}

const getTooltip = (point: DriveStandardPoints, shape: string) => {
  const ChartToolColorClass =
    shape == STD_SHAPE
      ? 'chart-legend-color-tooltip'
      : shape == PART_LOAD_SHAPE
      ? 'diamond-narrow-tooltip'
      : '';
  const ChartToolContentClass =
    shape == STD_SHAPE
      ? 'chart-legend-content'
      : shape == PART_LOAD_SHAPE
      ? 'diamond-tool-content'
      : '';
  return `  
  <div class="chart-legends-tool">
    <div class= ${ChartToolColorClass}></div>
    <div class="${ChartToolContentClass}">
      <div class="content1">Frequency ${point.frequency}%; Current ${point.current}%</div>	
      <div class="content2">Absolute losses ${point.absoluteLoss}${point.absoluteLossUnit}</div>	
      <div class="content3">Efficiency  ${point.efficiency}${point.efficiencyUnit}</div>
    </div>
</div>`;
};

const getPointLabel = (point: DriveStandardPoints) => {
  return (
    '' +
    point?.absoluteLoss +
    point?.absoluteLossUnit +
    ';' +
    point?.efficiency +
    point?.efficiencyUnit
  );
};

// Partload shape(Diamond)
const partLoadShape = {
  draw: function (context: any, size: any) {
    context.moveTo(0, -size / 11);
    context.lineTo(size / 11, 0);
    context.lineTo(0, size / 11);
    context.lineTo(-size / 11, 0);
    context.lineTo(0, -size / 11);
    context.closePath();
  },
};

const getFrequency = (
  shape: string,
  frequency: number,
  nominalFrequency?: string
) => {
  if (shape === STD_SHAPE) {
    if (frequency === 0 && Number(nominalFrequency) === 50) {
      return FZERO50;
    } else if (frequency === 0 && Number(nominalFrequency) === 60) {
      return FZERO60;
    } else {
      return frequency;
    }
  } else {
    return frequency;
  }
};

const formatPointsForChart = (
  points: DriveStandardPoints[],
  shape: string,
  type: string,
  nominalFrequency?: string
): ICoordinate[] => {
  return points.map((point, index) => ({
    index: index + 1,
    shape,
    x: getFrequency(shape, point.frequency, nominalFrequency),
    y: point.current,
    efficiency: point.efficiency,
    efficiencyUnit: point.efficiencyUnit,
    absoluteLoss: point.absoluteLoss,
    absoluteLossUnit: point.absoluteLossUnit,
    tooltipContent: getTooltip(point, shape),
    pointLabel: getPointLabel(point),
    pointLabelFontSize: type == 'small' ? '10' : '14',
    pointColor:
      shape === STD_SHAPE
        ? STD_SHAPE_COLOR
        : shape == PART_LOAD_SHAPE
        ? PART_LOAD_SHAPE_COLOR
        : '',
    customShape: shape === PART_LOAD_SHAPE ? partLoadShape : '',
  }));
};

interface IDriveLossChart {
  partloadPoints?: DriveStandardPoints[];
  standardPoints?: DriveStandardPoints[];
  loading?: boolean;
  type: 'small' | 'large';
  resultsLoading?: boolean;
  height?: number;
  width?: number;
  nominalFrequency?: string;
  id?: string;
}

export const DriveLossChart: React.FC<IDriveLossChart> = ({
  nominalFrequency,
  standardPoints,
  partloadPoints,
  loading,
  type,
  resultsLoading,
  height,
  width,
  id,
}) => {
  const data1Color = STD_SHAPE_COLOR;
  const standardPointsSorted = standardPoints
    ?.slice()
    .sort(
      (point1, point2) =>
        point1.frequency - point2.frequency || point1.current - point2.current
    );
  const formattedStandardPoints = standardPoints
    ? formatPointsForChart(
        standardPointsSorted ? standardPointsSorted : [],
        STD_SHAPE,
        type,
        nominalFrequency
      )
    : [];
  const formattedPartloadPoints = partloadPoints
    ? formatPointsForChart(partloadPoints, PART_LOAD_SHAPE, type)
    : [];

  const coordinates = formattedPartloadPoints
    ? formattedStandardPoints?.concat(formattedPartloadPoints)
    : formattedStandardPoints;

  const isReportModalOpen = useSelector(
    (state: RootState) => state.workspace.isReportModalOpen
  );

  return (
    <div
      className="scatter-chart-container"
      data-rf="drive-losses-chart"
      style={{ height: `${isReportModalOpen ? `${height}px` : 'initial'}` }}
    >
      <div className="chart-top-section">
        <div className="chart-legends">
          <div>
            <div
              style={{ backgroundColor: data1Color }}
              className="chart-legend-color"
            ></div>
            Standard point
          </div>
          {formattedPartloadPoints.length > 0 ? (
            <div>
              <div className="diamond-narrow "></div>
              User-defined load point
            </div>
          ) : null}
        </div>
      </div>
      {!loading && !resultsLoading && (
        <D3
          coordinates={coordinates ? coordinates : []}
          id={id ? id : `Drive-chart`}
          xAxisLabel={'Relative stator frequency %'}
          yAxisLabel={'Relative torque-producing current %'}
          height={height}
          width={width}
          xAxisTicks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          yAxisTicks={[0, 25, 50, 75, 100]}
          darkGridLines={{ xAxis: [0, 50, 90], yAxis: [0, 25, 50, 100] }}
          lightGridLines={{
            xAxis: [10, 20, 30, 40, 60, 70, 80, 100],
            yAxis: [75],
          }}
        />
      )}
    </div>
  );
};
