import { useState } from 'react';
import RadioGroup from '../../../../components/Form/RadioGroup';
import Radio from '../../../../components/Form/Radio';
import Button from '../../../../components/Form/Button';
import { useGetVoltagesQuery } from '../../services/APISelectionService';
import CommonLoader from '../../../../components/Loader';
import Input from '../../../../components/Form/Input';
import { useDispatch, useSelector } from 'react-redux';
import { setRatings, setLoadData } from '../../store/WorkspaceReducer';
import { RootState } from '../../../../store/rootReducer';
import Tooltip from '../../../../components/Tooltip';

interface RatingsSelectionProps {
  onRatingsNextClick: (data: string) => void;
  onPreviousClick: (value: number) => void;
}

const RatingsSelection = (props: RatingsSelectionProps) => {
  const applicationId = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      .selectionDetails.applicationId;
  });

  const supplyDataVoltage = useSelector((state: RootState) => {
    return state.workspace.transformerDetails.selectedTransformerDatas
      .transformerInput.secondaryVoltage;
  });

  const { data: VoltageData, isLoading } = useGetVoltagesQuery(applicationId);
  const [load, setLoad] = useState('0');
  const [loadType, setLoadType] = useState('2');
  const dispatch = useDispatch();

  const handleNextClick = () => {
    dispatch(
      setRatings({
        voltage: parseFloat(supplyDataVoltage),
        load: parseFloat(load),
        loadType: parseFloat(loadType),
      })
    );

    VoltageData?.loadvalues && dispatch(setLoadData(VoltageData?.loadvalues));
    props.onRatingsNextClick(`${supplyDataVoltage} V, ${load} kW`);
  };

  if (isLoading) {
    return (
      <CommonLoader
        size="xlarge"
        indicatorType={'circular'}
        progressType={'indeterminate'}
        progressValue={50}
        type={'primary-black'}
      />
    );
  }

  return (
    <div>
      {!isLoading && (
        <div>
          <span
            className="industry-selection-header"
            data-rf="ratings-selection-header"
          >
            Specify your ratings
          </span>
          <div className="ratings-container">
            <div>
              <div className="ratings-voltage-header">Mains voltage</div>
              <>
                <Input
                  className="selection-select-area"
                  value={supplyDataVoltage}
                  suffixText={'V'}
                  size="medium"
                  disabled={true}
                  valid={true}
                />
              </>
            </div>
            <div>
              <div className="ratings-voltage-header">Load</div>
              <div>
                <Tooltip size="medium" alignment="end" placement="bottom">
                  <div slot="tooltip-parent">
                    <Input
                      className="selection-select-area"
                      dataRf="selection-load-input"
                      value={load.toString()}
                      onInputInput={(event) => {
                        setLoad(event.detail.value);
                      }}
                      suffixText={'kW'}
                      valid={load !== '0'}
                      inputType="number"
                      size="medium"
                    />
                  </div>
                  <div slot="content-section">Load power at motor shaft</div>
                </Tooltip>
              </div>
            </div>

            <div>
              <div className="ratings-overload-header">Overloadability</div>
              <div>
                <RadioGroup
                  value={loadType}
                  onRadioChange={(event) => {
                    setLoadType(event.detail.value);
                  }}
                >
                  <div className="ratings-radio-container">
                    <Radio
                      size="medium"
                      type="primary-black"
                      name="Nominal Use"
                      value="2"
                      data-rf="nominal-use-radio-button"
                    >
                      <span slot="label">Nominal Use</span>
                      <span slot="description">
                        Typical motor power in no-overload use
                      </span>
                    </Radio>
                    <Radio
                      size="medium"
                      type="primary-black"
                      name="Light-overload Use"
                      value="1"
                      data-rf="light-overload-use-radio-button"
                    >
                      <span slot="label">Light-overload Use</span>
                      <span slot="description">
                        Typical motor power in light-overload use, 10% overload
                        every 5 or 10 minutes
                      </span>
                    </Radio>
                    <Radio
                      size="medium"
                      type="primary-black"
                      name="Heavy-duty Use"
                      value="3"
                      data-rf="heavy-duty-use-radio-button"
                    >
                      <span slot="label">Heavy-duty Use</span>
                      <span slot="description">
                        Typical motor power in heavy-duty use, 50% overload
                        every 5 or 10 minutes
                      </span>
                    </Radio>
                  </div>
                </RadioGroup>
              </div>
            </div>

            <div className="ratings-button-container">
              <Button
                className="ratings-button"
                type="secondary"
                size="large"
                data-rf="selection-previous-button"
                onClick={() => {
                  props.onPreviousClick(2);
                }}
              >
                Previous
              </Button>
              <Button
                className="ratings-button"
                type="primary-red"
                size="large"
                data-rf="selection-next-button"
                onClick={handleNextClick}
                disabled={load === '0'}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RatingsSelection;
