import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslate } from '../../i18translate/Hooks';
import { I18 } from '../../languages/I18';
import AbbFooter from '../../components/AbbFooter';

interface CookieBannerProps {
  onCookieBannerClick: () => void;
}

function Footer(props: CookieBannerProps) {
  const { t } = useTranslate();

  return (
    <React.Fragment>
      <AbbFooter
        showSeperator
        variant="top-only"
        className="footer-section-container"
        data-testid="footer-section-container"
        data-rf="footer-section-container"
      >
        <footer className="footer" slot="footerTop" data-testid="footer">
          <div>
            {' '}
            <label
              className="copyright"
              data-rf="footer-copyright-label"
              data-testid="footer-copyright-label"
            >
              {t(I18.home_footer_copyright_label)}
            </label>
          </div>
          <div className="footer-links">
            <section>
              <p
                onClick={() =>
                  window.open(
                    'https://new.abb.com/provider-information',
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
                data-rf="footer-provider-information-link"
                data-testid="footer-provider-information-link"
              >
                {t(I18.footer_link_provider_information)}
              </p>
            </section>
            <section>
              <p
                onClick={() =>
                  window.open(
                    'https://library.e.abb.com/public/09158efb31874fd2a7739e4f1e63d1cb/ABB%20Acceptable%20Use%20Policy%20March%202018.pdf?x-sign=J7ipRSrP215EEzLV41EbTFXfodoPSGbxSipCvi2QU/6wZ1AvEPrjv6xt6Mr2L/Tz',
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
                data-rf="footer-acceptableusepolicy-link"
                data-testid="footer-acceptableusepolicy-link"
              >
                {t(I18.footer_link_acceptable_use_policy)}
              </p>
            </section>
            <section>
              <p
                onClick={() =>
                  window.open(
                    'https://new.abb.com/privacy-policy',
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
                data-rf="footer-privacynotice-link"
                data-testid="footer-privacynotice-link"
              >
                {t(I18.footer_link_privacy_notice)}
              </p>
            </section>

            <p
              className="cookie-button"
              onClick={() => {
                props.onCookieBannerClick();
              }}
              data-rf="footer-changecookiesettings-text"
              data-testid="footer-changecookiesettings-text"
            >
              {t(I18.footer_link_change_cookie_setting)}
            </p>
            <section>
              <p
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_OIDC_CLIENT_ROOT}/info/legal`,
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
                data-rf="footer-legal-link"
                data-testid="footer-legal-link"
              >
                {t(I18.footer_link_legal)}
              </p>
            </section>
          </div>

          <section className="footer-link">
            <p
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_OIDC_CLIENT_ROOT}/info/versions`,
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
              data-rf="footer-version-link"
              data-testid="footer-version-link"
            >
              {t(I18.footer_link_version)}
            </p>
          </section>
        </footer>
      </AbbFooter>
    </React.Fragment>
  );
}

export default Footer;
