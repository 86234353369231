import { Drive, Motor } from '../../models/SelectionModel';

interface RecommendedProductsProps {
  handleProductClick?: (type: string) => void;
  currentMotor: Motor | undefined;
  currentDrive: Drive | undefined;
  isReadOnly?: boolean;
}

const RecommendedProducts = ({
  handleProductClick,
  currentDrive,
  currentMotor,
  isReadOnly,
}: RecommendedProductsProps) => {
  return (
    <div className="device-recommendation-container">
      <div
        className={`product-selection-container ${isReadOnly && 'disabled'}`}
        onClick={() => handleProductClick?.('motor')}
        data-rf="motor-selection-container"
      >
        <div className="product-main-image-container">
          <img
            className="product-main-image"
            src={currentMotor?.image?.url}
            alt={currentMotor?.image?.fileName}
          />
        </div>

        <div className="product-type-header" data-rf="motor-family-header">
          {isReadOnly ? 'Selected ABB Motor' : 'MOTOR FAMILY'}
        </div>
        <div className="product-name-header" data-rf="motor-name-header">
          {currentMotor?.name}
        </div>
        <div>
          <ul className="selection-product-desc">
            {currentMotor?.mainValueProposition && (
              <li>{currentMotor.mainValueProposition}</li>
            )}
            {currentMotor?.frameMaterial && (
              <li>{currentMotor.frameMaterial}</li>
            )}
            {currentMotor?.efficiencyClass && (
              <li>{currentMotor.efficiencyClass}</li>
            )}
            {currentMotor?.powerRange && <li>{currentMotor.powerRange}</li>}
          </ul>
          {!isReadOnly && <span className="show-more-label">Show more...</span>}
        </div>
      </div>

      <div
        className={`product-selection-container ${isReadOnly && 'disabled'}`}
        onClick={() => handleProductClick?.('drive')}
        data-rf="drive-selection-container"
      >
        <div className="product-main-image-container">
          <img
            className="product-main-image"
            src={currentDrive?.image?.url}
            alt={currentDrive?.image?.fileName}
          />
        </div>
        <div className="product-type-header" data-rf="drive-family-header">
          {isReadOnly ? 'Selected ABB Drive' : 'DRIVE FAMILY'}
        </div>
        <div className="product-name-header" data-rf="drive-name-header">
          {currentDrive?.name}
        </div>
        <div>
          <ul className="selection-product-desc">
            {currentDrive?.mainValueProposition && (
              <li>{currentDrive?.mainValueProposition}</li>
            )}
            {currentDrive?.motorControl && (
              <li>{currentDrive?.motorControl}</li>
            )}
            {currentDrive?.mounting && <li>{currentDrive?.mounting}</li>}
            {currentDrive?.degreeOfProtection && (
              <li>{currentDrive?.degreeOfProtection}</li>
            )}
          </ul>
          {!isReadOnly && <span className="show-more-label">Show more...</span>}
        </div>
      </div>
    </div>
  );
};

export default RecommendedProducts;
