import Icon from '../../../../components/Icon';
import { ErrorAndWarningDialogProps } from '../models/MotorSelectionModels';

const ErrorAndWarningDialog = ({
  isError,
  isWarning,
  data,
}: ErrorAndWarningDialogProps) => {
  return (
    <>
      <div
        className={
          isError ? 'error-dialogue-container' : 'warning-dialogue-container'
        }
      >
        <Icon
          slot="icon"
          size="large"
          name={
            isError ? 'error-circle-1' : isWarning ? 'warning-triangle' : ''
          }
          theme="dark"
          className={
            isError ? 'error-icon-color' : isWarning ? 'warning-icon-color' : ''
          }
        />
        <div className="message-container">{data}</div>
      </div>
    </>
  );
};

export default ErrorAndWarningDialog;
