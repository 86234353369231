import { MotorLoadData, DimensionOutput } from "../../Dimensioning/models/MotorSelectionModels";

// conversion formulas
export const convertToPercentage = (value:number, totalValue:number) =>{
    return Math.round((value/totalValue) * 100);
}

export const convertToValue = (value:number, totalValue:number) =>{
    return Math.round((value * totalValue) / 100);
}

export const convertTorqueToPower = (torque:number, baseSpeed:number) =>{
    return Math.round((torque * (2 * Math.PI * baseSpeed)) / 60000);
}

export const convertPowerToTorque = (power:number, baseSpeed:number) =>{
    return Math.round((power * 60000)/(2 * Math.PI *baseSpeed));
}

export const getMinTorque = (dsResult:DimensionOutput) =>{
    let res =  dsResult.supplyDriveLoads?.[0].motorUnit.results.filter((x) =>{ if(x.name === "Torque ") return x;})
    let nominalTorque = parseInt(res[0].value); // dsresults nominal torque
    return Math.round((nominalTorque * 15) / 100);
}


export const getNBaseValue = (data: any) => {
  let torqueRowIndex = -1;

  // Find the row containing "Torque [Nm]"
  for (let i = 0; i < data.length; i++) {
    if (data[i].row[0] === "Torque [Nm]") {
      torqueRowIndex = i;
      break;
    }
  }

  // If "Torque [Nm]" row found, find the "n base" value in the next row
  if (torqueRowIndex !== -1 && torqueRowIndex < data.length - 1) {
    const nBaseRow = data[torqueRowIndex + 1];
    const nBaseValue = nBaseRow.row[1]; // Assuming "n base" value is in the second column

    return nBaseValue;
  }

  return null; // Return null if "Torque [Nm]" or "n base" row not found
}
export const getMaxTorque = (dsResult:DimensionOutput) =>{
     let sel = getNBaseValue(dsResult.supplyDriveLoads?.[0].motorUnit.miscellaneousUnit.selectionData); 
     let selectiondataTorque = parseInt(sel);
    return Math.round(selectiondataTorque);
}

export const getLoadBalancingTorquePercentage =  (dsResult:DimensionOutput, trqInPercentage:number) =>{
     let sel = getNBaseValue(dsResult.supplyDriveLoads?.[0].motorUnit.miscellaneousUnit.selectionData); 
     let selectiondataTorque = parseInt(sel);
     let catalogueData = parseInt(dsResult.supplyDriveLoads?.[0].motorUnit.results.filter((x) =>{ if(x.name === "Torque ") return x;})[0].value);
    return Math.round((selectiondataTorque / catalogueData) * trqInPercentage);
}

export const getMaxSpeed = (dsInput:MotorLoadData, dsResult: DimensionOutput ) =>{
    let baseSpeed = parseInt(dsInput.nBase); // ds result base speed
    let loadType = dsInput.loadType;
    let res = dsResult.supplyDriveLoads?.[0].motorUnit.results.filter((x) =>{ if(x.name === "Speed ") return x;})
    let catalogueData = parseInt(res[0].value); // catalogue data from DS
    switch (loadType){
        //constant power
        case "0": let maxCatalogueSpeed = ((catalogueData * 200)/ 100) ;
                  let maxBaseSpeed =  ((baseSpeed * 200)/100);
                  return maxCatalogueSpeed > maxBaseSpeed ?  Math.round(maxCatalogueSpeed) : Math.round(maxBaseSpeed);
        //constant torque
        case "1": return baseSpeed;
        //constant torque and power
        case "2": let maxCatSpeed = ((catalogueData * 200)/ 100) ;
                  let maxBasSpeed =  ((baseSpeed * 200)/100);
                  return maxCatSpeed > maxBasSpeed ?  Math.round(maxCatSpeed) : Math.round(maxBasSpeed);
        //pump and fan
        case "3": return Math.round((catalogueData * 200)/ 100);
        default: return baseSpeed;
    }
}

export const getMinSpeed = (dsInput:MotorLoadData,dsResult: DimensionOutput) =>{
    let baseSpeed = parseInt(dsInput.nBase);  // ds result base speed
    let loadType = dsInput.loadType;
    let res = dsResult.supplyDriveLoads?.[0].motorUnit.results.filter((x) =>{ if(x.name === "Speed ") return x;})
    let catalogueData = parseInt(res[0].value); // catalogue data from DS
    let minSpeed = parseInt(dsInput.nMin) // ds input entered by user
    switch(loadType){
        //constant power
        case "0": return Math.round((baseSpeed * 25) / 100);
        //constant torque
        case "1": let minCatSpeed = ((catalogueData * 25) /100);
                  return minSpeed > minCatSpeed ?  Math.round(minCatSpeed) :  Math.round(minSpeed);
        //constant torque and power
        case "2": let minCatalogueSpeed = ((catalogueData * 25) /100);
                  return minSpeed > minCatalogueSpeed ?  Math.round(minCatalogueSpeed) :  Math.round(minSpeed);
        //pump and fan
        case "3": return Math.round((catalogueData * 25) /100);
        default: return baseSpeed;
    }               
}

export const getLoadBalancingSpeed = (dsInput:MotorLoadData,dsResult: DimensionOutput, speedInPercentage:number) =>{
    let baseSpeed = parseInt(dsInput.nBase);  // ds result base speed
    let res = dsResult?.supplyDriveLoads[0]?.motorUnit.results.filter((x) =>{ if(x.name === "Speed ") return x;})
    let catalogueData = parseInt(res[0].value); // catalogue data from DS
    return Math.round((baseSpeed/catalogueData) * speedInPercentage);
}
export const getMinPower = (dsInput: MotorLoadData) => {
        let power = parseInt(dsInput.power); // ds result base speed
        return Math.round(power * 15) / 100 ;
}
