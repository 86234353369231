import React from 'react';
import { EffDriveresults } from '../../models/EfficiencyModel';
import '../Styles/styles.scss';
import { RangeChart } from './RangeChart';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { RangeChartEfficiencyReport } from '../../Reports/Efficiency/RangeChartEfficiencyReport';

interface IDriveRelativeLossChart {
  results?: EffDriveresults;
  loading?: boolean;
}

export const DriveRelativeLossChart: React.FC<IDriveRelativeLossChart> = ({
  results,
}) => {
  const isModalOpen = useSelector(
    (state: RootState) => state.workspace.isReportModalOpen
  );

  let lossRatio: any;
  if (results?.standardData) {
    // Complete drive module relativeLoss is point with freq 90 and current 100%
    lossRatio = results?.driveTechnicalData?.lossRatio;
  }
  const progressValue = isNaN(Number(lossRatio)) ? 0 : Number(lossRatio);
  return (
    <div data-rf="drive-losses-cdm-reference-chart">
      {!isModalOpen ? (
        <RangeChart value={progressValue} />
      ) : (
        <RangeChartEfficiencyReport value={progressValue} />
      )}
      <div className="RangeChart-Footer">
        <div className="RangeChart-Footer-Scale"></div>
        <div className="RangeChart-Footer-Text">
          Improving efficiency, lower losses compared to reference CDM
        </div>
      </div>
    </div>
  );
};
