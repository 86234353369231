import React from 'react';
import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';
import { Link } from 'react-router-dom';

const Versions = () => {
  const { t } = useTranslate();

  return (
    <div className="layout-container">
      <div className="vandlpagecontainer info-page-container">
        <Link to="/info/versions" className="selected">
          Versions
        </Link>
        <Link to={'/info/legal'}>Legal</Link>
      </div>
      <div className="version-container info-tabs-container ">
        <div
          dangerouslySetInnerHTML={{
            __html: `${t(I18.footer_info_version_title)}`,
          }}
          className="version-title"
        ></div>
        <div
          dangerouslySetInnerHTML={{
            __html: `${t(I18.fotoer_info_versions_body)}`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default Versions;
