import { CommonuxCheckbox } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';

interface CheckboxProps extends JSX.CommonuxCheckbox {
  className?: string;
  id?: string;
}

const Checkbox = (props: PropsWithChildren<CheckboxProps>) => {
  return <CommonuxCheckbox {...props}>{props?.children}</CommonuxCheckbox>;
};

export default Checkbox;
