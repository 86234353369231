import React from 'react';
import { ProjectGridCardProps } from '../pages/Home/Components/types';
import Card from './Card';
import { timeAgo } from '../utils/GeneralUtils';
import { convertDateTime } from '../pages/Home/Components/RecentProjects';

const ProjectsGridCard = ({
  children,
  createdDate,
  idx,
  modifiedDate,
  projectId,
  projectName,
  openProject,
}: ProjectGridCardProps) => {
  return (
    <Card
      key={projectId}
      className="project-card"
      size="default"
      type="discreet"
      data-rf={'project-' + (idx + 1)}
      onCardClick={() => {}}
    >
      {children}
      <div slot="contents">
        <div
          className="project-name"
          id="header"
          data-rf="project-name"
          onClick={openProject}
        >
          {projectName}
        </div>

        <div id="content" data-rf="project-content">
          <div>
            <div className="project-modified-label">
              <div data-rf="project-created-date">
                Created{' '}
                {createdDate
                  ? timeAgo.format(convertDateTime(createdDate))
                  : '-'}
              </div>
              <div data-rf="project-modified-date">
                Last modified{' '}
                {modifiedDate
                  ? timeAgo.format(convertDateTime(modifiedDate))
                  : '-'}
              </div>
            </div>
            <div data-rf="project-revision-label">1 revision</div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ProjectsGridCard;
