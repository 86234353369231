import { CommonuxNumericInput } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';

interface NumericInputProps extends JSX.CommonuxNumericInput {
  className?: string;
  id?: string;
}

const NumericInput = (props: PropsWithChildren<NumericInputProps>) => {
  return (
    <CommonuxNumericInput {...props}>{props?.children}</CommonuxNumericInput>
  );
};

export default NumericInput;
