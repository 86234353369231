import { ProjectActionTypes } from '../../models/projectModel';
import { ActionTypes } from '../action/actionTypes';

interface DimensionState {
  projectEfficiencyData: Array<any>;
}

const initialState: DimensionState = {
  projectEfficiencyData: {} as Array<any>,
};

const ProjectReducer = (
  state = initialState,
  action: ProjectActionTypes
): DimensionState => {
  switch (action.type) {
    case ActionTypes.FETCHED_EFFICIENCY_DATA:
      return {
        ...state,
        projectEfficiencyData: action.payload,
      };

    default:
      return state;
  }
};

export default ProjectReducer;
