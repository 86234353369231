import { PropsWithChildren, useReducer } from 'react';
import { Translate } from './translate/Translate';
import { TranslationState } from './models/TranslationState';
import { ITranslate } from './models/ITranslate';
import {
  TranslateContext,
  TranslateDispatchContext,
  TranslateStateContext,
} from './Contexts';
import { translateReducer } from './TranslateReducer';
import { TranslationConfig } from './models/TranslationConfig';

export const TranslateProvider = (
  props: PropsWithChildren<TranslationConfig>
) => {
  const translate = Translate.getInstance(props);
  const value: ITranslate = translate;
  const initialState: TranslationState = {
    language: translate.getCurrentLanguage(),
    languageJson: translate.getCurrentLanguageJson(),
  };
  const [state, dispatch] = useReducer(translateReducer, initialState);

  return (
    <TranslateContext.Provider value={value}>
      <TranslateStateContext.Provider value={state}>
        <TranslateDispatchContext.Provider value={dispatch}>
          {props?.children}
        </TranslateDispatchContext.Provider>
      </TranslateStateContext.Provider>
    </TranslateContext.Provider>
  );
};
