import { PropsWithChildren } from 'react';
import { CommonuxIcon } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';

interface IconProps extends JSX.CommonuxIcon {
  slot?: string;
  className?: string;
  //make it false if wanted to used the specified icon name
  formatIconName?: boolean;
}

function Icon(props: PropsWithChildren<IconProps>) {
  const { size = 'small', name, formatIconName = true } = props;
  const getIconName = (): string | undefined => {
    if (!formatIconName) return name;

    switch (size) {
      case 'small':
        return `${name}-16`;
      case 'medium':
        return `${name}-24`;
      case 'large':
        return `${name}-32`;
      default:
        return name;
    }
  };
  return (
    <CommonuxIcon {...props} name={getIconName()}>
      {props?.children}
    </CommonuxIcon>
  );
}

export default Icon;
