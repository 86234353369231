import './styles/style.scss';
import Card from '../../components/Card';
import Button from '../../components/Form/Button';
import SegmentCards from './Components/SegmentCards';
import { I18 } from '../../languages/I18';
import { useTranslate } from '../../i18translate/Hooks';
import { useDispatch } from 'react-redux';
import { setActiveComponent } from './store/WorkspaceReducer';
import { useEffect } from 'react';
import { appInsights } from '../../services/AppInsight';

const Workspace = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  useEffect(() => {
    appInsights.trackPageView({
      name: 'Workspace Page',
      pageType: 'Main page',
    });
  }, []);
  const handleSelectionClick = () => {
    window.onbeforeunload = () => {
      return 'Are you sure you want to leave?';
    };
    dispatch(setActiveComponent('selection'));
  };

  return (
    <>
      <div className="selection-area">
        <div className="selection-method-container">
          <div className="selection-method-header">
            {t(I18.workspace_selection_method_title)}
          </div>

          <div>
            <Card
              key={'selection-guide-card'}
              className="selection-card"
              size="larger-spacing"
              onClick={() => {}}
              type="discreet"
              data-rf="guided-selection-card"
            >
              <div slot="header">
                <div className="selection-header">
                  {t(I18.workspace_selection_guide_card_title)}
                </div>
                <img
                  src={'selection-steps.svg'}
                  alt={'Guided selection'}
                  className="selection-image"
                />
              </div>

              <div className="selection-content" slot="contents" id="content">
                {t(I18.workspace_selection_guide_card_description)}
              </div>

              <div slot="footer" className="selection-button">
                <Button
                  type="primary-black"
                  size="medium"
                  onClick={handleSelectionClick}
                >
                  {t(I18.workspace_selection_guide_card_start_selecting_button)}
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </div>

      <div className="segment-area" data-rf="drive-segment-section">
        <div className="segment-method-header">
          {t(I18.workspace_segment_cards_title)}
        </div>
        <div className="segment-method-container">
          <SegmentCards />
        </div>
      </div>
    </>
  );
};

export default Workspace;
