import { useEffect, useState } from 'react';
import Button from '../../../../components/Form/Button';
import Radio from '../../../../components/Form/Radio';
import RadioGroup from '../../../../components/Form/RadioGroup';
import Modal from '../../../../components/Modal';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import {
  ManualSelectionModalMotorProps,
  RecommendedMotors,
} from '../models/MotorSelectionModels';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { selectMotor } from '../../store/WorkspaceReducer';
import ManualSelectionConfirmationModal from './ManualSelectionConfirmationModal';
import CommonLoader from '../../../../components/Loader';

const ManualSelectionModalMotor = ({
  modalOpen,
  onClose,
  onSelectClick,
}: ManualSelectionModalMotorProps) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const [currentSelectedRowIndex, setCurrentRowIndex] = useState(0);
  const [isMotorManuallySelected, setIsMotorManuallySelected] = useState(false);
  const [motorDetailsSelected, setMotorDetailsSelected] = useState<
    RecommendedMotors | undefined
  >(undefined);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const manualSelectionMotorData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.manualSelectionMotorData
  );

  const motorTypeDesignation = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.productTypeCode.motorTypeDesignation
  );

  const [dimensioningDataResult] = useState<any>(undefined);

  useEffect(() => {
    if (isMotorManuallySelected) {
      onSelectClick('Motor', motorDetailsSelected?.index ?? '');
      setIsMotorManuallySelected(false);
    }
  }, [isMotorManuallySelected]);

  useEffect(() => {
    if (dimensioningDataResult) {
      dispatch(selectMotor(dimensioningDataResult));
    }
  }, [dimensioningDataResult]);

  const handleSelectClick = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmationSelectClick = () => {
    setIsMotorManuallySelected(true);
    setMotorDetailsSelected(
      manualSelectionMotorData?.motorsList?.recommendedMotors[
        currentSelectedRowIndex
      ]
    );
    onClose();
  };

  const onConfirmationModalClose = () => {
    setIsConfirmationModalOpen(false);
    onClose();
  };

  const handleMotorRowSelectClick = (rowIndex: number) => {
    if (rowIndex !== currentSelectedRowIndex) {
      setCurrentRowIndex(rowIndex);
    }
  };
  useEffect(() => {
    //get the motordetails and in that number value that object is to be set here
    if (
      currentSelectedRowIndex === 0 &&
      manualSelectionMotorData?.motorsList?.recommendedMotors &&
      manualSelectionMotorData?.motorsList?.recommendedMotors?.length > 0
    ) {
      let motorselected =
        manualSelectionMotorData?.motorsList?.recommendedMotors.filter(
          (motor, index) =>
            motor?.type === motorTypeDesignation && motor?.number === '0'
        );
      motorselected.length > 0 &&
        setCurrentRowIndex(parseInt(motorselected[0]?.index) + 1);
    }
  }, [
    currentSelectedRowIndex,
    manualSelectionMotorData?.motorsList?.recommendedMotors,
    motorTypeDesignation,
  ]);

  return (
    <>
      <Modal
        open={modalOpen}
        onModalClose={onClose}
        modalTitle={'Manual selection'}
        showCloseButton={true}
        type="default"
        showHederSeparator="true"
        show-footer-separator="true"
        backdropClick={false}
        className="manual-selection-modal"
      >
        <div
          id="manual-selection-motor-modal-content"
          className="manual-selection-motor-modal-content"
        >
          {!manualSelectionMotorData?.motorsList?.recommendedMotors && (
            <div className="form-loader">
              <CommonLoader
                size="xlarge"
                indicatorType={'circular'}
                progressType={'indeterminate'}
                progressValue={50}
                type={'primary-black'}
              />
            </div>
          )}
          <table className="manual-selection-table">
            <RadioGroup value={currentSelectedRowIndex.toString()}>
              <tbody>
                {manualSelectionMotorData?.motorsList?.recommendedMotors?.map(
                  (row, rowIndex) => {
                    let className = '-column-data';
                    if (rowIndex === 0) {
                      className = '-column-title';
                    }
                    return (
                      <tr
                        className={
                          currentSelectedRowIndex === rowIndex
                            ? 'tr-selected'
                            : ''
                        }
                      >
                        <td className={`select` + className}>
                          {rowIndex !== 0 && (
                            <Radio
                              value={rowIndex.toString()}
                              onRadioFocus={() =>
                                handleMotorRowSelectClick(rowIndex)
                              }
                            ></Radio>
                          )}
                        </td>
                        <td>{row.number}</td>
                        <td>{row.type}</td>
                        <td>{row.code}</td>
                        <td>{row.meps}</td>
                        <td>{row.efficiencyClass}</td>
                        <td>{row.winding}</td>
                        <td>{row.power}</td>
                        <td>{row.poles}</td>
                        <td>{row.speed}</td>
                        <td>{row.in}</td>
                        <td>{row.tn}</td>
                        <td>{row.tmaxByTn}</td>
                        <td>{row.tcontMargin}</td>
                        <td>{row.tmaxMargin}</td>
                        <td>{row.im}</td>
                        <td>{row.imMax}</td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </RadioGroup>
          </table>
        </div>
        <div className="report-graph-modal-footer" slot="footer">
          <Button
            type="secondary"
            size="small"
            onClick={onClose}
            className="create-project-cancel-button"
          >
            {t(I18.motor_drive_selection_modal_cancel_button)}
          </Button>
          <Button type="primary-black" size="small" onClick={handleSelectClick}>
            {'Select'}
          </Button>
        </div>
      </Modal>
      <ManualSelectionConfirmationModal
        modalOpen={isConfirmationModalOpen}
        onClose={onConfirmationModalClose}
        onSelectClick={handleConfirmationSelectClick}
        type={'motor'}
      />
    </>
  );
};

export default ManualSelectionModalMotor;
