import { CommonuxNotification } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';

interface NotificationProps extends JSX.CommonuxNotification {
  className?: string;
  id?: string;
}

function Notification(props: PropsWithChildren<NotificationProps>) {
  return (
    <CommonuxNotification {...props}>{props?.children}</CommonuxNotification>
  );
}

export default Notification;
