import React from 'react';
import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';

const LossDetermination = () => {
  const { t } = useTranslate();
  return (
    <div className="report-page-loss-container">
      <div className="report-page-loss-subheader">
        {t(I18.efficiency_pds_loss_determination_header)}
      </div>
      <div className="report-page-loss-content">
        {t(I18.efficiency_pds_loss_determination)}
      </div>

      <div className="report-page-loss-subheader">
        {t(I18.efficiency_cdm_loss_determination_header)}
      </div>
      <div>
        <div className="report-page-loss-content">
          <p>{t(I18.efficiency_cdm_loss_determination)}</p>
          <p>{t(I18.efficiency_losses_drive_page_cdm_loss_heading)}</p>
          <ol>
            <li>
              {t(I18.efficiency_losses_drive_page_cdm_loss_1)}
              <br />
              {t(I18.efficiency_losses_drive_page_cdm_loss_1_a)}
              <sub>
                {t(I18.efficiency_losses_drive_page_cdm_loss_1_a_sub)}
              </sub>{' '}
              ={' '}
              <strong>
                {t(I18.efficiency_losses_drive_page_cdm_loss_1_a_strong)}
              </strong>
              <br />
              {t(I18.efficiency_losses_drive_page_cdm_loss_1_b)}
              <sub>{t(I18.efficiency_losses_drive_page_cdm_loss_1_b_sub)}</sub>=
              <strong>
                {t(I18.efficiency_losses_drive_page_cdm_loss_1_b_strong)}
              </strong>
              <br />
              {t(I18.efficiency_losses_drive_page_cdm_loss_1_c)}
              <sub>
                {' '}
                {t(I18.efficiency_losses_drive_page_cdm_loss_1_c_sub)}
              </sub>{' '}
              ={' '}
              <strong>
                {' '}
                {t(I18.efficiency_losses_drive_page_cdm_loss_1_c_strong)}
              </strong>
              <br />
              {t(I18.efficiency_losses_drive_page_cdm_loss_1_d)}
              <sub>
                {t(I18.efficiency_losses_drive_page_cdm_loss_1_d_sub)}
              </sub>{' '}
              ={' '}
              <strong>
                {t(I18.efficiency_losses_drive_page_cdm_loss_1_d_strong)}
              </strong>
              <br />
              {t(I18.efficiency_losses_drive_page_cdm_loss_1_e)}
              <sub>
                {t(I18.efficiency_losses_drive_page_cdm_loss_1_e_sub)}
              </sub>{' '}
              ={' '}
              <strong>
                {t(I18.efficiency_losses_drive_page_cdm_loss_1_e_strong)}
              </strong>
              <br />
              {t(I18.efficiency_losses_drive_page_cdm_loss_1_f)}
              <br />
              {t(I18.efficiency_losses_drive_page_cdm_loss_1_g)}
              <br />
              {t(I18.efficiency_losses_drive_page_cdm_loss_1_h)}
              <br />
            </li>
            <li>
              {t(I18.efficiency_losses_drive_page_cdm_loss_2)}
              <sub>n</sub> {t(I18.efficiency_losses_drive_page_cdm_loss_2_sub)}
              <sub>N</sub> * U​<sub>1,out</sub>
              <br />
            </li>
            <li>
              {' '}
              {t(I18.efficiency_losses_drive_page_cdm_loss_3)} <br />
            </li>
            <li>
              {t(I18.efficiency_losses_drive_page_cdm_loss_4)}
              <br />
            </li>
            <li>
              {t(I18.efficiency_losses_drive_page_cdm_loss_5)}
              <br />
            </li>
            <li>
              {t(I18.efficiency_losses_drive_page_cdm_loss_6)}
              <br />
            </li>
            <li>
              {t(I18.efficiency_losses_drive_page_cdm_loss_7)}
              <br />
            </li>
            <li>
              {t(I18.efficiency_losses_drive_page_cdm_loss_8)}P ​
              <sub>output, CDM</sub> / ( P <sub>​output, CDM</sub> + P{' '}
              <sub>​losses, CDM</sub>). P <sub>​output, CDM</sub> is
              {' ' + t(I18.efficiency_losses_drive_page_cdm_loss_8_output)}{' '}
              <sub>​losses, CDM</sub>{' '}
              {t(I18.efficiency_losses_drive_page_cdm_loss_8_output_end)}
              <br />
            </li>
          </ol>
          <p>{t(I18.efficiency_losses_drive_page_cdm_loss_conclusion)}</p>
        </div>
      </div>

      <div className="report-page-loss-subheader">
        {t(I18.efficiency_losses_motor_page_loss_determination_heading)}
      </div>
      <div>
        <div className="report-page-loss-content">
          {t(I18.efficiency_losses_motor_page_loss_determination)}
          <br />
          {t(I18.efficiency_losses_motor_page_loss_determination_sub)}
        </div>
      </div>

      <div className="report-page-loss-subheader">
        {t(I18.efficiency_losses_motor_page_disclaimer_heading)}
      </div>
      <div>
        <div className="report-page-loss-content">
          {t(I18.efficiency_losses_page_disclaimer)}
        </div>
      </div>
    </div>
  );
};

export default LossDetermination;
