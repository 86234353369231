import { CommonuxSegmentedControl } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';

interface CommonuxButtonGroupProps extends JSX.CommonuxSegmentedControl {
  className?: string;
  id?: string;
}

const ButtonGroupCommonux = (
  props: PropsWithChildren<CommonuxButtonGroupProps>
) => {
  return (
    <CommonuxSegmentedControl {...props}>
      {props?.children}
    </CommonuxSegmentedControl>
  );
};

export default ButtonGroupCommonux;
