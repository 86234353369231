import React from 'react';
import {
  driveStandardPointsHeader,
} from '../Utils/PreDefinedLoadPoints';
import { DriveStandardPoints } from '../../models/EfficiencyModel';
import { driveStandardPointsValues } from '../Utils/PreDefinedLoadPoints';

interface TableProps {
  data: DriveStandardPoints[],
  losses:string
}
const DriveStandardPointsTable = (props: TableProps) => {
  let stdPoints = props.data;

  const standardPointsPreset: DriveStandardPoints[] = driveStandardPointsValues;

    const points = standardPointsPreset.map((prePoint) => {
        const availablePoint = stdPoints?.find(
          (point) =>{
            if(point.current == prePoint.current && point.frequency == prePoint.frequency)
              return point;
          }
        );
        return availablePoint ? availablePoint : prePoint;
      });

  return (
    <div data-rf="drive-losses-table">
      <table className="energy-losses-table standard-points-table">
        <thead>
          <tr>
            {driveStandardPointsHeader.map((data, idx) => {
              return (
                <th key={data} id={data + idx}>
                  {data}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {
            stdPoints && stdPoints.length > 0 && 
            points.map((val, index) => {
              let ind = index + 1;
              let imp = ind < 4 ? "*" + ind : ind
              return (
                <tr key={val.absoluteLoss} id={val.absoluteLoss + ""+ind}>
                  <td>{imp}</td>
                  <td>{val.frequency.toString()}%</td>
                  <td>{val.current.toString()}%</td>
                  <td>{val.absoluteLoss?.toString() +" "+ val.absoluteLossUnit}</td>
                  <td>{val.relativeLoss?.toString()+" "+ val.relativeLossUnit}</td>
                  <td>{val.efficiency?.toString() +" "+ val.efficiencyUnit}</td>
                </tr>
              )
            })
          }
          <tr>
            <td></td>
            <td></td>
            <td>Standby losses</td>
            <td>{props.losses}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};


export default DriveStandardPointsTable;
