import React, { FC } from 'react';

interface IRangeChartLine {
  value: number;
  lineType: 'Dash' | 'Solid';
  extraText?: string;
  hideLabel?: boolean;
}

const getPercentage = (val: number) => (val / 150) * 100;

const getCustomStyle = (line: IRangeChartLine): React.CSSProperties => {
  return line.lineType === 'Solid' && line.value < 30
    ? { right: 0, textAlign: 'end' }
    : {};
};

interface IProgressbar {
  value: number;
}

export const RangeChartEfficiencyReport: FC<IProgressbar> = ({ value }) => {
  const lines: IRangeChartLine[] = [
    {
      value: 0,
      lineType: 'Dash',
    },
    {
      value: value,
      lineType: 'Solid',
      extraText: 'ABB result',
    },
    {
      value: 75,
      lineType: 'Dash',
    },
    {
      value: 100,
      lineType: 'Dash',
      extraText: 'Of ref. CDM losses',
    },
    {
      value: 125,
      lineType: 'Dash',
    },
    {
      value: 150,
      lineType: 'Dash',
      hideLabel: true,
    },
  ];

  return (
    <div className="">
      <div className="RangeChart-Title">Losses compared to reference CDM</div>
      <div className="rangechart-efficiency-report">
        <div className="RangeChart-Bar">
          <div className="RangeChart-Bar-Item"></div>
          <div className="RangeChart-Bar-Item"></div>
          <div className="RangeChart-Bar-Item"></div>
        </div>
        <div className="RangeChart-Bar">
          <div className="RangeChart-Bar-Label">CDM IE0</div>
          <div className="RangeChart-Bar-Label">CDM IE1</div>
          <div className="RangeChart-Bar-Label">CDM IE2</div>
        </div>

        <div className="RangeChart-VLines">
          {lines.map((line, index) => (
            <div
              key={index}
              className={`RangeChart-VLine VLine-${line.lineType}`}
              style={{ right: `${getPercentage(line.value)}%` }}
            >
              {!line.hideLabel && (
                <div className="VLine-Text-Container">
                  <div style={getCustomStyle(line)} className="VLine-Text">
                    {line.extraText && (
                      <div className="VLine-Extra-Text">{line.extraText}</div>
                    )}
                    <div>
                      {line.extraText?.includes('ABB result')
                        ? `${line.value.toFixed(1)}%`
                        : `${line.value}%`}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
