import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';
import { getCountry } from 'countries-and-timezones';
import { useSession } from '../../../utils/Auth';
import { RootState } from '../../../store/rootReducer';
import { useSelector } from 'react-redux';

const ProjectInformation = () => {
  const { t } = useTranslate();
  const { session } = useSession();
  const projectData = useSelector((state: RootState) => state.home.projectData);
  const projectInfo = useSelector(
    (state: RootState) => state.workspace.applicationDetails
  );

  return (
    <div>
      {/* project information section */}
      <div className="project-information-header">
        {t(I18.reports_project_information)}
      </div>

      <div className="project-information">
        <div className="project-information-col">
          <div>
            {t(I18.reports_project)} <p>{projectData?.projectName}</p>
          </div>

          <div>
            {t(I18.reports_created_by)}
            <p>
              {(session?.user.profile.given_name ?? '-') +
                ' ' +
                (session?.user.profile.family_name ?? '')}
            </p>
          </div>

          {/* <div>
            CUSTOMER REFERENCE
            <p>-</p>
          </div> */}
        </div>

        <div className="project-information-col-right">
          {/* <div>
            {t(I18.reports_customer)} <p>-</p>
          </div> */}

          <div>
            LOCATION
            {projectData?.destinationCountry && (
              <p>{getCountry(projectData?.destinationCountry)?.name}</p>
            )}
          </div>

          <div>
            {t(I18.reports_standard)}
            <p>{projectData.standard + '/' + projectData.unitSystem}</p>
          </div>
        </div>
      </div>
      {projectData?.description && (
        <div>
          <div className="project-description-header">{'Description'}</div>
          <div className="project-description-content">
            {projectData?.description}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectInformation;
