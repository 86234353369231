import { useState } from 'react';
import Icon from '../../../components/Icon';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { RecentProjectMenuProps } from './types';
import DeleteProjectModal from './DeleteProjectModal';
import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';

const RecentProjectMenu = ({
  openProjectButtonHandler,
  deleteProjectButtonHandler,
  projectName,
  deleteProjectLoading,
}: RecentProjectMenuProps) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const { t } = useTranslate();

  const ref = useOutsideClick(() => {
    setShowMenu(false);
  });

  const deleteButtonHandler = () => {
    deleteProjectButtonHandler();
    setShowMenu(false);
  };
  return (
    <>
      <div slot="contents" className="recent-project-menu-container">
        <>
          <div className="project-menu">
            <button
              className={` kebab-icon-container ${showMenu ? 'active' : ''}`}
              onClick={() => setShowMenu(true)}
            >
              <Icon name="kebab" size="small" slot="icon" />
            </button>
          </div>
          {showMenu ? (
            <div className="project-menu-container" ref={ref}>
              <button onClick={openProjectButtonHandler}>
                <Icon name="folder-open" size="small" slot="menu-icon" />
                <label>{t(I18.open_button)}</label>
              </button>

              <button onClick={() => setOpenDeleteModal(true)}>
                <Icon name="trash" size="small" />
                <label>{t(I18.delete_button)}</label>
              </button>
            </div>
          ) : (
            ''
          )}
        </>
      </div>
      <div slot="contents">
        <DeleteProjectModal
          closeDeleteModal={() => setOpenDeleteModal(false)}
          showModal={openDeleteModal}
          projectName={projectName}
          deleteButtonHandler={() => deleteButtonHandler()}
          deleteProjectLoading={deleteProjectLoading}
        />
      </div>
    </>
  );
};

export default RecentProjectMenu;
