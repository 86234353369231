import { CommonuxColumnSpan } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';

interface ColumnSpanProps extends JSX.CommonuxColumnSpan {
  arrowIcon?: boolean;
  borderBottom?: boolean;
  collapseIcon?: boolean;
  contentExpanded?: boolean;
  onExpandButtonClick?: () => void;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  type?: 'primary-black' | 'primary-red' | 'primary-blue';
  value?: string;
  onexpandTextClick?: boolean;
}

function ColumnSpan(props: PropsWithChildren<ColumnSpanProps>) {
  return <CommonuxColumnSpan {...props}>{props?.children}</CommonuxColumnSpan>;
}

export default ColumnSpan;
