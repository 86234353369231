import {
  CommonuxMenu,
  CommonuxMenuItem,
  CommonuxTreeView,
  CommonuxTreeViewNode,
} from '@abb-motion-ux/commonux-web-components-react';
import Icon from '../../../components/Icon';
import Button from '../../../components/Form/Button';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import { PropsWithoutRef, useCallback, useEffect, useState } from 'react';
import Input from '../../../components/Form/Input';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectTypeEnum } from '../Dimensioning/models/DriveMotorEnums';
import {
  setSideBarState,
  setActiveComponent,
  addApplication,
  setCurrentApplication,
  setCurrentApplicationName,
  deleteApplication,
  setIsAllApplicationsEdited,
} from '../store/WorkspaceReducer';
import { RootState } from '../../../store/rootReducer';
import { I18 } from '../../../languages/I18';
import { useTranslate } from '../../../i18translate/Hooks';
import InformationModal from '../../Home/Components/InformationModal';
import { GetNameConverted, generateKey } from '../../../utils/GeneralUtils';
import {
  useDeleteApplicationMutation,
  useUpdateProjectMutation,
} from '../../Home/services/APIMainService';
import { useSession } from '../../../utils/Auth';
import notificationManager from '../../../utils/NotificationManager';
import { useSelectAllMutation } from '../Dimensioning/services/APIDimensioningService';
import {
  useGetCatalogReportMutation,
  useGetPISDataMutation,
} from '../../Home/services/APICommonService';
import { ApplicationDetails } from '../models/WorkspaceModels';
import Tooltip from '../../../components/Tooltip';

interface SidebarPanelProps {
  onItemClick?: any;
  isProjectsLoading: boolean;
  onAmbientEditClick: () => void;
}

const SidebarPanel = (props: PropsWithoutRef<SidebarPanelProps>) => {
  const { isDesktop, isTablet } = useDeviceDetect();
  const { t } = useTranslate();
  const [isProjectsUpdating, setIsProjectsUpdating] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [applicationName, setApplicationName] = useState('');
  const [menuOpen, setMenuOpen] = useState<boolean[]>([]);
  const [isEditable, setIsEditable] = useState<boolean[]>([]);

  const dispatch = useDispatch();
  const [updateProject, updateResult] = useUpdateProjectMutation({
    fixedCacheKey: 'shared-update-post',
  });

  const [dimensionData, dimensionResult] = useSelectAllMutation({
    fixedCacheKey: 'shared-dimension-post',
  });

  const [catalogueData, catalogueResult] = useGetPISDataMutation({
    fixedCacheKey: 'shared-catalogue-post',
  });

  const [catalogReport, catalogueReportResult] = useGetCatalogReportMutation({
    fixedCacheKey: 'shared-dimension-post',
  });

  const { session } = useSession();
  const [deleteApp] = useDeleteApplicationMutation();

  const projectDetails = useSelector(
    (state: RootState) => state.home.projectData
  );

  const applications = useSelector(
    (state: RootState) => state.workspace.applicationDetails
  );

  const isAllApplicationsEdited = useSelector(
    (state: RootState) => state.workspace.isAllApplicationsEdited
  );

  const sidebarOpen = useSelector(
    (state: RootState) => state.workspace.sidebarOpen
  );

  const currentAppRank = useSelector(
    (state: RootState) => state.workspace.currentAppRank
  );

  const selectedTransformerDatas = useSelector(
    (state: RootState) =>
      state.workspace.transformerDetails.selectedTransformerDatas
        ?.transformerInput
  );
  const transformerData = useSelector(
    (state: RootState) =>
      state.workspace.transformerDetails.selectedTransformerDatas
  );
  const ambientConditionValues = useSelector(
    (state: RootState) => state.workspace.ambientConditionValues
  );

  const onKebabButtonClick = (idx: number) => {
    const tempArr = menuOpen.map(() => {
      return false;
    });
    tempArr[idx] = !menuOpen[idx];
    setMenuOpen(tempArr);
  };

  useEffect(() => {
    if (
      // updateResult.isLoading ||
      dimensionResult.isLoading ||
      catalogueReportResult.isLoading ||
      catalogueResult.isLoading
    ) {
      setIsProjectsUpdating(true);
    } else if (
      // !updateResult.isLoading &&
      !dimensionResult.isLoading &&
      !catalogueReportResult.isLoading &&
      !catalogueResult.isLoading
    ) {
      setIsProjectsUpdating(false);
    }
  }, [dimensionResult, updateResult, catalogueResult, catalogueReportResult]);

  useEffect(() => {
    if (!isDesktop && sidebarOpen) {
      handleButtonClick(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonClick = useCallback(
    (event: any, id?: number) => {
      dispatch(setSideBarState(!sidebarOpen));
      !isProjectsUpdating && id && dispatch(setCurrentApplication(id));
    },
    [props, dispatch]
  );

  const handleRenameButtonClick = (id: number) => {
    const tempArr = isEditable.map(() => {
      return false;
    });
    handleInputBlur();
    const newArr = tempArr;
    newArr[id] = !isEditable[id];
    setIsEditable(newArr);
  };

  useEffect(() => {
    const id = isEditable.indexOf(true);
    const inputref = document.querySelectorAll('input');
    inputref?.[id]?.focus();
  }, [isEditable]);

  useEffect(() => {
    if (Boolean(applicationName)) {
      dispatch(setCurrentApplicationName(applicationName));
    }
  }, [applicationName, dispatch]);

  const handleInputBlur = () => {
    let arr: boolean[] = [];
    if (applications) {
      applications?.forEach(() => {
        arr.push(false);
      });
      setIsEditable(arr);
      setMenuOpen(arr);
    }
  };

  const handleMenuBlur = () => {
    let arr: boolean[] = [];
    if (applications) {
      applications?.forEach(() => {
        arr.push(false);
      });
      setMenuOpen(arr);
    }
  };

  if (!isDesktop && sidebarOpen) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'unset';
  }

  const handleDeleteButtonClick = () => {
    setIsProjectsUpdating(true);
    const currentApplication = applications[currentAppRank];

    deleteApp({
      id: projectDetails.projectId,
      applicationId: currentApplication.id,
      userToken: session?.user.access_token,
    })
      .then((response) => {
        if ('error' in response) {
          notificationManager.error('Unable to delete the application');
          setIsProjectsUpdating(false);
          return;
        }

        dispatch(deleteApplication());
        setIsProjectsUpdating(false);
      })
      .catch(() => {
        notificationManager.error('Unable to delete the application');
        setIsProjectsUpdating(false);
      });
    setShowDeleteModal(false);
  };

  const handleAddApplicationClick = () => {
    dispatch(addApplication());
  };

  useEffect(() => {
    if (isAllApplicationsEdited) {
      setIsProjectsUpdating(true);
      const currentApplication = applications[currentAppRank];
      updateProject({
        userToken: session?.user ? session?.user.access_token : undefined,
        projectData: {
          projectId:
            projectDetails.projectId || window.sessionStorage.getItem('id'),
          projectName:
            projectDetails.projectName || window.sessionStorage.getItem('name'),
          jsonFileName: currentApplication.id,
          applicationDetailsJson: JSON.stringify({
            transformerId: selectedTransformerDatas?.trafoId,
            applicationId: currentApplication.id,
            applicationName: currentApplication.name,
            applicationRank: currentAppRank,
          }),
          applicationId: currentApplication?.id,
        },
      }).then(() => {
        dispatch(setIsAllApplicationsEdited(false));
        setIsProjectsUpdating(false);
      });
    }
  }, [applications]);

  const getIndustryDetails = (application: ApplicationDetails) => {
    if (
      application.selectionDetails?.industryId.length > 0 &&
      application.selectionDetails.applicationId.length > 0
    ) {
      return (
        application.selectionDetails.industryName +
        ' | ' +
        application.selectionDetails.applicationName
      );
    } else if (
      application.selectionDetails?.applicationName?.length > 0 &&
      application.selectionDetails?.industryName?.length > 0
    ) {
      return (
        application.selectionDetails.industryName +
        ' | ' +
        application.selectionDetails.applicationName
      );
    } else if (
      application.selectionDetails.industryId.length === 0 &&
      application.selectionDetails.applicationId.length === 0
    ) {
      if (application.dimensionDetails.typeIds?.projectName)
        return application.dimensionDetails.typeIds.projectName;
      else if (application.dimensionDetails.typeIds?.projectType) {
        let prj = application.dimensionDetails.typeIds.projectType.toString();
        return ProjectTypeEnum[prj as keyof typeof ProjectTypeEnum];
      } else {
        return null;
      }
    }
  };

  const shouldDisplayDiv = (
    typeCode: string,
    isWarning: boolean,
    isError: boolean
  ) => {
    if (!Boolean(typeCode) && !isError && !isWarning) {
      return true;
    }

    if (Boolean(typeCode) && isWarning && !isError) {
      return true;
    }

    if (isError) {
      return true;
    }

    if (isWarning) {
      return true;
    }

    return false;
  };

  const shouldDisplayApplicationIconDiv = (
    motorTypeCode: string,
    driveTypeCode: string,
    isMotorWarning: boolean,
    isMotorError: boolean,
    isDriveWarning: boolean,
    isDriveError: boolean
  ) => {
    if (
      !isMotorError &&
      !isMotorWarning &&
      !isDriveError &&
      !isDriveWarning &&
      !Boolean(motorTypeCode || driveTypeCode)
    ) {
      return true;
    }

    if (isMotorError || isMotorWarning || isDriveError || isDriveWarning) {
      return true;
    }

    return false;
  };

  return (
    <div
      className={`sidebar-container ${sidebarOpen ? '' : 'sidebar-collapsed'}`}
    >
      {isDesktop && sidebarOpen ? (
        <div className="settings-container">
          <div className="ambient-condition-container">
            <div className="country-varient-container">
              <div className="destination-country-value-container">
                {projectDetails?.destinationCountry}
              </div>
              <div>
                <Icon
                  size="small"
                  name="ci-dark-caret-down"
                  className="setting-icon"
                  theme="dark"
                  disabled={true}
                />
              </div>
            </div>
            <div className="ambient-altitude-container">
              <div className="ambient-elements-container">
                <Icon size="small" name="up-arrow" className="setting-icon" />
                <div className="ambient-condition-values-label">
                  {ambientConditionValues.altitude}{' '}
                  {t(I18.sidebar_altitude_unit_label)}
                </div>
              </div>
            </div>
            <div className="ambient-motorTemparature-container">
              <div className="ambient-elements-container">
                <Icon size="small" name="motor" className="setting-icon" />
                <div className="ambient-condition-values-label">
                  {ambientConditionValues.motorTemparature}{' '}
                  {t(I18.sidebar_temparature_unit_label)}
                </div>
              </div>
            </div>
            <div className="ambient-driveTemparature-container">
              <div className="ambient-elements-container">
                <Icon size="small" name="drive" className="setting-icon" />
                <div className="ambient-condition-values-label">
                  {ambientConditionValues.driveTemparature}{' '}
                  {t(I18.sidebar_temparature_unit_label)}
                </div>
              </div>
            </div>
            <div className="ambient-edit-container">
              <Button type="discreet-black" onClick={props.onAmbientEditClick}>
                <Icon size="small" name="edit" className="setting-icon" />
              </Button>
            </div>
          </div>
        </div>
      ) : (
        isDesktop && (
          <Button
            type="discreet-black"
            size="small"
            className="ambient-button"
            onClick={handleButtonClick}
          >
            <div>
              <Icon slot="icon" name="menu"></Icon>
            </div>
          </Button>
        )
      )}
      <div className="sidebar-content">
        {isTablet && (
          <div className="settings-container">
            <div className="ambient-condition-container">
              <div className="country-varient-container">
                <div className="destination-country-value-container">
                  {projectDetails?.destinationCountry}
                </div>
                <div>
                  <Icon
                    size="small"
                    name="ci-dark-caret-down"
                    className="setting-icon"
                    theme="dark"
                    disabled={true}
                  />
                </div>
              </div>
              <div className="ambient-altitude-container">
                <div className="ambient-elements-container">
                  <Icon size="small" name="up-arrow" className="setting-icon" />
                  <div className="ambient-condition-values-label">
                    {ambientConditionValues.altitude}{' '}
                    {t(I18.sidebar_altitude_unit_label)}
                  </div>
                </div>
              </div>
              <div className="ambient-motorTemparature-container">
                <div className="ambient-elements-container">
                  <Icon size="small" name="motor" className="setting-icon" />
                  <div className="ambient-condition-values-label">
                    {ambientConditionValues.motorTemparature}{' '}
                    {t(I18.sidebar_temparature_unit_label)}
                  </div>
                </div>
              </div>
              <div className="ambient-driveTemparature-container">
                <div className="ambient-elements-container">
                  <Icon size="small" name="drive" className="setting-icon" />
                  <div className="ambient-condition-values-label">
                    {ambientConditionValues.driveTemparature}{' '}
                    {t(I18.sidebar_temparature_unit_label)}
                  </div>
                </div>
              </div>
              <div className="ambient-edit-container">
                <Button
                  type="discreet-black"
                  onClick={props.onAmbientEditClick}
                >
                  <Icon size="small" name="edit" className="setting-icon" />
                </Button>
              </div>
            </div>
          </div>
        )}
        <div
          className="left-pane-container"
          onClick={sidebarOpen ? () => {} : handleButtonClick}
        >
          <div
            key={generateKey('transformer-container')}
            className={`transformer-container ${
              currentAppRank === -1 ? 'transformer-active' : ''
            }`}
          >
            <CommonuxTreeView
              className="transformer-container"
              size="medium"
              type="primary-default "
              hidden={!sidebarOpen}
              actionDisplayType="hover"
            >
              <CommonuxTreeViewNode
                isSelected={true}
                isListExpanded={sidebarOpen}
                actionDisplayType="default"
                onClick={sidebarOpen ? () => {} : handleButtonClick}
                onExpandButtonClick={
                  sidebarOpen
                    ? () =>
                        !isProjectsUpdating &&
                        dispatch(setCurrentApplication(-1))
                    : handleButtonClick
                }
                disabled={isProjectsUpdating}
              >
                <div slot="tree-icon-folder">
                  <Icon
                    slot="icon"
                    size="small"
                    name={'transformer'}
                    disabled={isProjectsUpdating}
                  />
                </div>
                <div className="supply-data-text" slot="tree-view-text">
                  Supply data
                </div>
                <div slot="list">{''}</div>
                <div className="freq-voltage-data" slot="action-containers">
                  {transformerData?.transformerInput?.frequency +
                    'Hz | ' +
                    transformerData?.transformerInput?.secondaryVoltage +
                    'V'}
                </div>
              </CommonuxTreeViewNode>
            </CommonuxTreeView>
          </div>
          <div className="all-applications-container">
            {applications.map((application, idx) => {
              const driveName =
                application?.dimensionDetails?.dimensionInputData
                  ?.selectionInputs?.[0]?.driveName;

              const motorName =
                application?.dimensionDetails?.dimensionInputData
                  ?.selectionInputs?.[0]?.motorName;

              const motorError =
                application?.dimensionDetails?.errorOrWarningMotorResponse;

              const isMotorError = motorError?.isError;
              const isMotorWarning = motorError?.isWarning;

              const driveError =
                application?.dimensionDetails?.errorOrWarningDriveResponse;

              const isDriveError = driveError?.isError;
              const isDriveWarning = driveError?.isWarning;

              const motorIconClassname =
                isMotorWarning ||
                (!isMotorError &&
                  !isMotorWarning &&
                  !Boolean(
                    application.dimensionDetails.productTypeCode
                      .motorTypeDesignation
                  ))
                  ? 'warning-status'
                  : 'error-status';

              const driveIconClassname =
                isDriveWarning ||
                (!isDriveError &&
                  !isDriveWarning &&
                  !Boolean(
                    application.dimensionDetails.productTypeCode.driveTypeCode
                  ))
                  ? 'warning-status'
                  : 'error-status';

              const isActive = idx === currentAppRank;
              return (
                <div
                  key={idx}
                  className={`application-container ${
                    isActive ? 'application-active' : ''
                  }`}
                  onClick={
                    sidebarOpen
                      ? () => {
                          !isProjectsUpdating &&
                            dispatch(setCurrentApplication(idx));
                        }
                      : () => handleButtonClick(idx)
                  }
                >
                  <CommonuxTreeView size="small" type="primary-default ">
                    <CommonuxTreeViewNode
                      className={`application-header`}
                      actionDisplayType={'default'}
                      isSelected={false}
                      isListExpanded={sidebarOpen}
                      onClick={
                        sidebarOpen
                          ? () => {
                              !isProjectsUpdating &&
                                dispatch(setCurrentApplication(idx));
                            }
                          : () => handleButtonClick(idx)
                      }
                      onExpandButtonClick={
                        sidebarOpen
                          ? () => {
                              isActive &&
                                !isProjectsUpdating &&
                                dispatch(setCurrentApplication(idx));
                            }
                          : () => handleButtonClick(idx)
                      }
                      disabled={props.isProjectsLoading || isProjectsUpdating}
                    >
                      <div
                        className="application-text"
                        slot="tree-view-text"
                        onClick={
                          sidebarOpen
                            ? (e) => {
                                e.stopPropagation();
                                !isProjectsUpdating &&
                                  dispatch(setCurrentApplication(idx));
                              }
                            : () => handleButtonClick(idx)
                        }
                      >
                        <span hidden={isEditable[idx]}>{application.name}</span>
                        <div className="app-selection-data">
                          {getIndustryDetails(application)}
                        </div>
                        <div
                          hidden={!isEditable[idx]}
                          onBlur={handleInputBlur}
                          tabIndex={0}
                        >
                          <Input
                            id={idx.toString()}
                            value={application.name}
                            readOnly={false}
                            placeholder="Application name"
                            inputLimit={32}
                            onInputChange={(e) => {
                              setApplicationName(e.detail.value);
                            }}
                            valid
                          />
                        </div>
                      </div>
                      <div slot="action-containers" hidden={!isActive}>
                        <Button
                          size="xsmall"
                          type="discreet-black"
                          onBtnClick={() => onKebabButtonClick(idx)}
                          disabled={isProjectsUpdating}
                        >
                          <Icon
                            size="small"
                            name="kebab"
                            theme="dark"
                            disabled={isProjectsUpdating}
                          />
                        </Button>
                      </div>
                      <div className="status-menu-container" slot="status-icon">
                        <div className="status-icon-hover">
                          <div className="application-menu-container">
                            <CommonuxMenu
                              open={menuOpen[idx]}
                              onMouseLeave={handleMenuBlur}
                            >
                              <CommonuxMenuItem
                                label="Rename"
                                onMenuClick={() => {
                                  handleRenameButtonClick(idx);
                                }}
                              >
                                <img
                                  src="rename_16.svg"
                                  alt="rename"
                                  slot="menu-icon"
                                />
                              </CommonuxMenuItem>
                              <CommonuxMenuItem
                                label="Delete"
                                onMenuClick={() => setShowDeleteModal(true)}
                                disabled={applications.length < 2}
                              >
                                <Icon
                                  slot="menu-icon"
                                  size="small"
                                  name="trash"
                                  theme="dark"
                                />
                              </CommonuxMenuItem>
                            </CommonuxMenu>
                          </div>
                        </div>
                        <div
                          hidden={
                            isActive ||
                            !shouldDisplayApplicationIconDiv(
                              application.dimensionDetails.productTypeCode
                                ?.motorTypeDesignation,
                              application.dimensionDetails.productTypeCode
                                ?.driveTypeCode,
                              isMotorWarning,
                              isMotorError,
                              isDriveWarning,
                              isDriveError
                            )
                          }
                          className="tooltip-container"
                        >
                          {!(
                            isMotorWarning ||
                            isMotorError ||
                            isDriveWarning ||
                            isDriveError
                          ) ? (
                            <Tooltip
                              size="small"
                              alignment="end"
                              placement="left"
                              arrowPosition="bottom"
                            >
                              <div
                                slot="tooltip-parent"
                                className="tooltip-icon"
                              >
                                <Icon
                                  className={
                                    isMotorError || isDriveError
                                      ? 'error-status-application'
                                      : 'warning-status-application'
                                  }
                                  name={'warning-circle-1'}
                                />
                              </div>
                              <div slot="content-section">
                                {'Application is not sized'}
                              </div>
                            </Tooltip>
                          ) : (
                            <div slot="tooltip-parent" className="tooltip-icon">
                              <Icon
                                className={
                                  isMotorError || isDriveError
                                    ? 'error-status-application'
                                    : 'warning-status-application'
                                }
                                name={'warning-circle-1'}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div slot="list">
                        <CommonuxTreeView
                          className="device-container"
                          type="primary-default "
                          actionDisplayType={'hover'}
                        >
                          <div
                            className={`application-device ${
                              application.activeComponent === 'driveDimension'
                                ? 'active'
                                : ''
                            }`}
                          >
                            <CommonuxTreeViewNode
                              disabled={
                                !(
                                  application.activeComponent ===
                                    'motorDimension' ||
                                  application.activeComponent ===
                                    'driveDimension'
                                )
                              }
                              onExpandButtonClick={
                                sidebarOpen
                                  ? () => {
                                      !isProjectsUpdating &&
                                        dispatch(setCurrentApplication(idx));
                                      application.activeComponent ===
                                        'motorDimension' && isActive
                                        ? Object.keys(
                                            application.dimensionDetails.typeIds
                                          ).length > 0 &&
                                          dispatch(
                                            setActiveComponent('driveDimension')
                                          )
                                        : Object.keys(
                                            application.dimensionDetails.typeIds
                                          ).length > 0 ||
                                          (parseFloat(
                                            application.dimensionDetails
                                              .dimensionInputData
                                              ?.selectionInputs?.[0]?.power
                                          ) > 0 &&
                                            dispatch(
                                              setActiveComponent(
                                                'motorDimension'
                                              )
                                            ));
                                    }
                                  : () => handleButtonClick(idx)
                              }
                              actionDisplayType={undefined}
                            >
                              <div slot="tree-icon-folder">
                                <Icon
                                  name="drive"
                                  disabled={
                                    !(
                                      application.activeComponent ===
                                        'motorDimension' ||
                                      application.activeComponent ===
                                        'driveDimension'
                                    ) || isProjectsUpdating
                                  }
                                />
                              </div>
                              <div
                                className="application-text"
                                slot="tree-view-text"
                              >
                                {!application.dimensionDetails.productTypeCode
                                  ?.driveTypeCode ||
                                application.dimensionDetails.productTypeCode
                                  ?.driveTypeCode === ''
                                  ? 'Drive undefined'
                                  : application.dimensionDetails.productTypeCode
                                      ?.driveTypeCode}
                              </div>
                              <div
                                slot="status-icon"
                                className="drive-name-container"
                                hidden={
                                  !Boolean(
                                    application.dimensionDetails.productTypeCode
                                      .driveTypeCode
                                  )
                                }
                              >
                                {!isDriveError && !isDriveWarning
                                  ? GetNameConverted(driveName ?? '')
                                  : null}
                              </div>
                              <div
                                slot="status-icon"
                                hidden={
                                  !shouldDisplayDiv(
                                    application.dimensionDetails.productTypeCode
                                      .driveTypeCode,
                                    isDriveWarning,
                                    isDriveError
                                  )
                                }
                                className="tooltip-container"
                              >
                                <Tooltip
                                  size="small"
                                  alignment="end"
                                  placement="left"
                                  arrowPosition="bottom"
                                >
                                  <div
                                    slot="tooltip-parent"
                                    className="tooltip-icon"
                                  >
                                    <Icon
                                      className={driveIconClassname}
                                      name={
                                        isDriveWarning ||
                                        (!isDriveError &&
                                          !isDriveWarning &&
                                          !Boolean(
                                            application.dimensionDetails
                                              .productTypeCode.driveTypeCode
                                          ))
                                          ? 'warning-triangle'
                                          : 'error-circle-1'
                                      }
                                    />
                                  </div>
                                  <div slot="content-section">
                                    {isDriveError || isDriveWarning
                                      ? driveError?.errorDriveSelection
                                          ?.toString()
                                          .slice(0, 30) + '...' ?? ''
                                      : 'Drive is not selected'}
                                  </div>
                                </Tooltip>
                              </div>
                            </CommonuxTreeViewNode>
                          </div>
                          <div
                            className={`application-device-motor ${
                              application.activeComponent === 'motorDimension'
                                ? 'active'
                                : ''
                            }`}
                          >
                            <CommonuxTreeViewNode
                              disabled={
                                !(
                                  application.activeComponent ===
                                    'motorDimension' ||
                                  application.activeComponent ===
                                    'driveDimension'
                                )
                              }
                              onExpandButtonClick={
                                sidebarOpen
                                  ? () => {
                                      !isProjectsUpdating &&
                                        dispatch(setCurrentApplication(idx));
                                      application.activeComponent ===
                                        'driveDimension' &&
                                        dispatch(
                                          setActiveComponent('motorDimension')
                                        );
                                    }
                                  : () => handleButtonClick(idx)
                              }
                              actionDisplayType={undefined}
                            >
                              <div slot="tree-icon-folder">
                                <Icon
                                  name="motor"
                                  disabled={
                                    !(
                                      application.activeComponent ===
                                        'motorDimension' ||
                                      application.activeComponent ===
                                        'driveDimension'
                                    ) || isProjectsUpdating
                                  }
                                />
                              </div>
                              <div
                                className="application-text"
                                slot="tree-view-text"
                              >
                                {!application.dimensionDetails.productTypeCode
                                  ?.motorTypeDesignation ||
                                application.dimensionDetails.productTypeCode
                                  ?.motorTypeDesignation === ''
                                  ? 'Motor undefined'
                                  : application.dimensionDetails.productTypeCode
                                      ?.motorTypeDesignation}
                              </div>

                              <div
                                slot="status-icon"
                                className="motor-name-container"
                                hidden={
                                  !Boolean(
                                    application.dimensionDetails.productTypeCode
                                      ?.motorTypeDesignation
                                  ) ||
                                  isMotorError ||
                                  isMotorWarning
                                }
                              >
                                {GetNameConverted(motorName ?? '')}
                              </div>

                              <div
                                slot="status-icon"
                                hidden={
                                  !shouldDisplayDiv(
                                    application.dimensionDetails.productTypeCode
                                      .motorTypeDesignation,
                                    isMotorWarning,
                                    isMotorError
                                  )
                                }
                                className="tooltip-container"
                              >
                                <Tooltip
                                  size="small"
                                  alignment="end"
                                  placement="left"
                                  arrowPosition="bottom"
                                >
                                  <div
                                    slot="tooltip-parent"
                                    className="tooltip-icon"
                                  >
                                    <Icon
                                      className={motorIconClassname}
                                      name={
                                        isMotorWarning ||
                                        (!isMotorError &&
                                          !isMotorWarning &&
                                          !Boolean(
                                            application.dimensionDetails
                                              .productTypeCode
                                              .motorTypeDesignation
                                          ))
                                          ? 'warning-triangle'
                                          : 'error-circle-1'
                                      }
                                    />
                                  </div>
                                  <div slot="content-section">
                                    {isMotorError || isMotorWarning
                                      ? motorError?.errorMotorSelection
                                          ?.toString()
                                          .slice(0, 30) + '...' ?? ''
                                      : 'Motor is not selected'}
                                  </div>
                                </Tooltip>
                              </div>
                            </CommonuxTreeViewNode>
                          </div>
                        </CommonuxTreeView>
                      </div>
                    </CommonuxTreeViewNode>
                  </CommonuxTreeView>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="sidebar-button-container">
        <div className="sidebar-button">
          <Button
            type="secondary"
            size="small"
            className="sidebar-close-button"
            onClick={handleButtonClick}
            data-rf="sidebar-close-button"
          >
            <Icon
              slot="icon"
              size="small"
              name={sidebarOpen ? 'double-left' : 'double-right'}
              theme="dark"
            />
          </Button>
        </div>
      </div>
      <div className="add-application-button" hidden={!sidebarOpen}>
        <Button
          className="add-system-button"
          type="tertiary"
          size="xlarge"
          block
          disabled={
            process.env.REACT_APP_MULTIPLE_APPLICATIONS_FEATURE_ENABLED ===
              'false' || applications.length >= 10
          }
          onBtnClick={handleAddApplicationClick}
          loading={
            props.isProjectsLoading ||
            isProjectsUpdating ||
            updateResult.isLoading
          }
        >
          <Icon
            slot="icon"
            size="medium"
            name={'plus'}
            disabled={
              process.env.REACT_APP_MULTIPLE_APPLICATIONS_FEATURE_ENABLED ===
                'false' ||
              applications.length >= 10 ||
              isProjectsUpdating ||
              updateResult.isLoading
            }
            theme="dark"
          />
          {t('Add application')}
        </Button>
      </div>
      <InformationModal
        modalTitle="Delete this application?"
        modalContentText="This will delete the selected motor and drive with the application."
        modalOpen={showDeleteModal}
        buttonText="Delete"
        onClick={handleDeleteButtonClick}
        onClose={() => {
          setShowDeleteModal(false);
          handleInputBlur();
        }}
        isDelete={true}
      />
    </div>
  );
};

export default SidebarPanel;
