import { CommonuxFooter } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';
interface FooterProps extends JSX.CommonuxFooter {
  className?: string;
}

function AbbFooter(props: PropsWithChildren<FooterProps>) {
  return <CommonuxFooter {...props}>{props?.children}</CommonuxFooter>;
}

export default AbbFooter;
