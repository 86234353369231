import { DropdownData, SelectionInput } from './MotorSelectionModels';
import { SelectionData, TransformerInput } from './TransformerSelectionModel';
import { v4 as uuidv4 } from 'uuid';

export const transformerDefaultInputValues: TransformerInput = {
  trafoId: 'tra-' + uuidv4(),
  frequency: '50',
  networkSk: '200',
  primaryVoltage: '21000',
  secondaryVoltage: '400',
  trafoWindingNo: '2',
  trafoName: 'NewName',
  trafoType: '0',
  trafoIPclass: '0',
};

export const frequencyDropdownData: DropdownData[] = [
  {
    label: 'Frequency',
    accessor: 'frequency',
    isMultiSelect: false,
    isVisible: true,
    selectInput: [
      { label: '50', value: '50', isDefault: true },
      { label: '60', value: '60', isDefault: false },
    ],
  },
];

export const supplyLoadDropdownAt50HzData: DropdownData[] = [
  {
    label: 'Secondary voltage',
    accessor: 'secondaryVoltage',
    isMultiSelect: false,
    isVisible: true,
    selectInput: [
      { label: '200', value: '200', isDefault: false },
      { label: '208', value: '208', isDefault: false },
      { label: '220', value: '220', isDefault: false },
      { label: '230', value: '230', isDefault: false },
      { label: '240', value: '240', isDefault: false },
      { label: '380', value: '380', isDefault: false },
      { label: '400', value: '400', isDefault: true },
      { label: '415', value: '415', isDefault: false },
      { label: '440', value: '440', isDefault: false },
      { label: '500', value: '500', isDefault: false },
      { label: '525', value: '525', isDefault: false },
      { label: '660', value: '660', isDefault: false },
      { label: '690', value: '690', isDefault: false },
    ],
  },
  {
    label: 'No. of windings',
    accessor: 'trafoWindingNo',
    isMultiSelect: false,
    isVisible: true,
    selectInput: [
      { label: '2', value: '2', isDefault: false },
      { label: '3', value: '3', isDefault: true },
    ],
  },
];

export const supplyLoadDropdownAt60HzData: DropdownData[] = [
  {
    label: 'Secondary voltage',
    accessor: 'secondaryVoltage',
    isMultiSelect: false,
    isVisible: true,
    selectInput: [
      { label: '200', value: '200', isDefault: false },
      { label: '208', value: '208', isDefault: false },
      { label: '220', value: '220', isDefault: false },
      { label: '230', value: '230', isDefault: false },
      { label: '240', value: '240', isDefault: false },
      { label: '380', value: '380', isDefault: false },
      { label: '400', value: '400', isDefault: false },
      { label: '440', value: '440', isDefault: false },
      { label: '460', value: '460', isDefault: false },
      { label: '480', value: '480', isDefault: true },
      { label: '500', value: '500', isDefault: false },
      { label: '575', value: '575', isDefault: false },
      { label: '600', value: '600', isDefault: false },
      { label: '660', value: '660', isDefault: false },
      { label: '690', value: '690', isDefault: false },
    ],
  },
  {
    label: 'No. of windings',
    accessor: 'trafoWindingNo',
    isMultiSelect: false,
    isVisible: true,
    selectInput: [
      { label: '2', value: '2' },
      { label: '3', value: '3' },
    ],
  },
];

export const initialResultData: SelectionData[] = [
  { label: 'Fundamental power', value: '-' },
  { label: 'Multiplier due to harmonics', value: '-' },
  { label: 'Required apparent power', value: '-' },
  { label: 'Sn derated with altitude and Tamb', value: '-' },
  { label: 'Reserves', value: '-' },
  { label: 'Primary voltage', value: '-' },
  { label: 'Secondary voltage', value: '-' },
];

export const initialApplicationData: SelectionInput = {
  appId: 'App1',
  Compute: 'false',
  dataCategory: 'All',
  motorName: '',
  projectType: '5',
  frequency: '50',
  loadType: '1',
  overLoadType: '0',
  iContinuous: '0',
  iMax: '0',
  maxLoadTime: '0',
  dutyCycleTime: '0',
  power: '0',
  drivesCount: '0',
  driveName: '',
  motorType: '1',
  olBaseInput: '0',
  maxSpeed: '0',
  olMaxInput: '100',
  powerMax: '0',
  powerMin: '0',
  nMin: '0',
  nBase: '0',
  nMax: '0',
  motorsCount: '0',
  motorIndex: null,
};
