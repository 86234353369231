import React from 'react';
import IESClassGraph from '../../Efficiency/Components/IESClassGraph';
import { PDSEfficiencyPoint } from '../../Efficiency/Utils/PDSCommon';

const PDSRelativeLosses = ({ psdEfficientDetails }: any) => {
  let pdsRelativeLossWithoutFormatting: any;
  let pdsModuleEfficiency: any;
  let pdsAbsoluteLoss: any;
  let pdsRelativeLoss: any;
  if (psdEfficientDetails?.standardPoints) {
    const selectedPoint = psdEfficientDetails?.standardPoints.find(
      (point: { speed: string; torque: string }) =>
        parseInt(point.speed) === PDSEfficiencyPoint.Speed &&
        parseInt(point.torque) === PDSEfficiencyPoint.Torque
    );
    if (selectedPoint && selectedPoint.efficiency) {
      pdsModuleEfficiency = selectedPoint.efficiency
        ? selectedPoint.efficiency
        : '-';
    }
    if (selectedPoint && selectedPoint.absoluteLoss) {
      pdsAbsoluteLoss = selectedPoint.absoluteLoss
        ? selectedPoint.absoluteLoss && selectedPoint.absoluteLoss !== '-'
          ? selectedPoint.absoluteLoss + ` ${selectedPoint.absoluteLossUnit}`
          : '-'
        : '-';
    }
    if (selectedPoint && selectedPoint.relativeLoss) {
      pdsRelativeLoss = selectedPoint.relativeLoss
        ? selectedPoint.relativeLoss && selectedPoint.relativeLoss !== '-'
          ? selectedPoint.relativeLoss + ` ${selectedPoint.relativeLossUnit}`
          : '-'
        : '-';
      pdsRelativeLossWithoutFormatting = selectedPoint.relativeLoss;
    }
  }
  return (
    <div id="pdf-section">
      <IESClassGraph
        rangeList={psdEfficientDetails?.iesClass?.iesLimits}
        relativeLoss={pdsRelativeLossWithoutFormatting}
        rangeIesCalss={psdEfficientDetails?.iesClass?.ies}
      />
    </div>
  );
};

export default PDSRelativeLosses;
