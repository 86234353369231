import { CombinedState, configureStore } from '@reduxjs/toolkit';
import rootReducer, { RootState } from './rootReducer';
import {
  CatalogReportApi,
  DrivetypeInputDataApi,
  PISDataApi,
  articlesApi,
  countryApi,
  homeApi,
  localeApi,
} from '../pages/Home/services/APICommonService';
import { projectApi } from '../pages/Home/services/APIMainService';
import { dimensioningApi } from '../pages/Workspace/Dimensioning/services/APIDimensioningService';
import { selectionApi } from '../pages/Workspace/services/APISelectionService';
import { efficiencyApi } from '../pages/Workspace/Efficiency/services/APIEfficiencyService';

export let store: CombinedState<any>;

export const setupStore = (preloadedState?: Partial<RootState>) => {
  store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({}).concat(
        homeApi.middleware,
        localeApi.middleware,
        countryApi.middleware,
        articlesApi.middleware,
        projectApi.middleware,
        dimensioningApi.middleware,
        PISDataApi.middleware,
        CatalogReportApi.middleware,
        selectionApi.middleware,
        efficiencyApi.middleware,
        DrivetypeInputDataApi.middleware
      ),
  });

  return store;
};

export type AppStore = ReturnType<typeof setupStore>;
