import { ApexOptions } from 'apexcharts';
import ReactApexCharts from 'react-apexcharts';

interface ApexChartGraphProps {
  options: ApexOptions;
  type:
    | 'line'
    | 'area'
    | 'bar'
    | 'histogram'
    | 'pie'
    | 'donut'
    | 'radialBar'
    | 'scatter'
    | 'bubble'
    | 'heatmap'
    | 'treemap'
    | 'boxPlot'
    | 'candlestick'
    | 'radar'
    | 'polarArea'
    | 'rangeBar';
  height: number | string | undefined;

  series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
}

const ApexChartGraph = ({
  options,
  type,
  height,
  series,
}: ApexChartGraphProps) => {
  return (
    <>
      <div>
        <ReactApexCharts
          options={options}
          series={series}
          //@ts-ignore
          type={type}
          height={height}
        />
      </div>
    </>
  );
};

export default ApexChartGraph;
