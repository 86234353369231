import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  SelectionInput,
  ManualSelectionMotorData,
  ManualSelectionDriveData,
  MotorSelectionParamResult,
  Dimension,
} from '../models/MotorSelectionModels';
import { DrivetypeDropdownsData } from '../models/DriveSelectionModels';
import {
  DimensionInput,
  TransformerDimensionProjectIdData,
  TransformerResult,
  TransformerSelectionInput,
} from '../models/TransformerSelectionModel';

export interface TypeIdDetails {
  projectType: number;
  motorType: number;
}

export interface DimensionInputData {
  projectId: string;
  dimensionData: SelectionInput;
}

export interface DeleteProjectInput {
  userToken: string | undefined;
  id: string;
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_PRESALES_MAIN_BACKEND,
  credentials: 'same-origin',
  mode: 'cors',
});

export const dimensioningApi = createApi({
  reducerPath: 'dimensioningAPI',
  baseQuery,
  tagTypes: ['drivemanualselection', 'motormanualselection'],
  endpoints: (builder) => ({
    getDriveSizeParameters: builder.query<
      MotorSelectionParamResult,
      TypeIdDetails
    >({
      query: (typeIds: TypeIdDetails) => ({
        url: 'dimension/getdrivesizeparameters',
        method: 'GET',
        params: typeIds,
      }),
    }),
    deleteProject: builder.mutation<any, DeleteProjectInput>({
      query: (data: DeleteProjectInput) => ({
        url: `project/deleteprojectbyid?projectId=${data.id}`,
        method: 'POST',
        headers: data.userToken
          ? {
              jwtToken: data.userToken,
              appKey: 'GoSelect',
            }
          : {},
      }),
    }),
    selectAll: builder.mutation<Dimension, TransformerDimensionProjectIdData>({
      query: (data: TransformerDimensionProjectIdData) => ({
        url: `dimension/getdimensiondata?projectId=${data?.projectId}&projectName=DimensionTest2`,
        method: 'POST',
        body: data.transformerDimension,
      }),
    }),
    reselectDimensionData: builder.mutation<
      Dimension,
      TransformerSelectionInput
    >({
      query: (data: TransformerSelectionInput) => ({
        url: `dimension/reselectdimensiondata`,
        method: 'POST',
        body: data,
      }),
    }),
    recommendedMotorData: builder.mutation<
      ManualSelectionMotorData,
      DimensionInput
    >({
      query: (data: DimensionInput) => ({
        url: `dimension/getrecommendedmotors`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['motormanualselection'],
    }),
    recommendedDriveData: builder.mutation<
      ManualSelectionDriveData,
      DimensionInput
    >({
      query: (data: DimensionInput) => ({
        url: `dimension/getrecommendeddrives`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['drivemanualselection'],
    }),
    getDrivetypeInputs: builder.query<DrivetypeDropdownsData, string>({
      query: (projectType: string) => ({
        url: 'dimension/getdrivetypeinputs',
        method: 'GET',
        params: { projectType },
      }),
    }),
    getTransformerSizing: builder.mutation<
      TransformerResult,
      TransformerSelectionInput
    >({
      query: (data: TransformerSelectionInput) => ({
        url: 'dimension/gettransformersizing',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetDriveSizeParametersQuery,
  useSelectAllMutation,
  useRecommendedMotorDataMutation,
  useRecommendedDriveDataMutation,
  useGetDrivetypeInputsQuery,
  useDeleteProjectMutation,
  useGetTransformerSizingMutation,
  useReselectDimensionDataMutation,
} = dimensioningApi;
