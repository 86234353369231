import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';

const TableOfContents = () => {
  const reportData = useSelector((state: RootState) => {
    return state.workspace;
  });

  return (
    <div className="table-of-contents-container" id="pdf-break-after">
      <h2>Table of contents</h2>
      <div className="table-of-contents">
        <div className="content-heading font-bold">1. Bill of material</div>
        <div className="content-heading font-bold">2. Project details</div>
        <div className="content-heading font-bold">
          3. Product dimensioning details
        </div>
        {reportData?.applicationDetails.length > 0 &&
          reportData?.applicationDetails.map((application, i) => {
            let j = 1;
            if (
              application?.dimensionDetails?.productTypeCode
                ?.motorTypeDesignation ||
              application?.dimensionDetails?.productTypeCode?.driveTypeCode
            ) {
              return (
                <>
                  <div className="content-heading application-name" id="">
                    3.{i + 1}. {application?.name}
                  </div>
                  <div className="content-heading application-sub-names">
                    3.{i + 1}.{j}. Load profile
                  </div>
                  {application?.dimensionDetails?.productTypeCode
                    ?.motorTypeDesignation && (
                    <div className="content-heading application-sub-names">
                      3.{i + 1}.{++j}. Motor data
                    </div>
                  )}
                  {application?.dimensionDetails?.productTypeCode
                    ?.driveTypeCode && (
                    <div className="content-heading application-sub-names">
                      3.{i + 1}.{++j}. Drive profile
                    </div>
                  )}
                  {(application?.pdsEfficiencyDetails &&
                    Object.keys(application?.pdsEfficiencyDetails).length >
                      0) ||
                  (application?.motorEfficiencyDetails &&
                    Object.keys(application?.motorEfficiencyDetails).length >
                      0) ||
                  (application?.driveEfficiencyDetails &&
                    Object.keys(application?.driveEfficiencyDetails).length >
                      0) ? (
                    <div className="content-heading application-sub-names">
                      3.{i + 1}.{++j}. Effciency
                    </div>
                  ) : null}
                </>
              );
            }
          })}
      </div>
    </div>
  );
};

export default TableOfContents;
