import '../Styles/styles.scss';
import ProgressBar from './ProgressBar';
import '../Styles/ProductInfo.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';

interface IProductInfo {
  productInfo: {
    title: string;
    subText1: string;
    subText2: string;
    icon: string;
    absoluteLoss?: string;
    absoluteLossUnit?: string;
    relativeLoss?: string;
    relativelossUnit?: string;
    ieClass?: string;
    ieClassLabel?: string;
    smallIcon?: string;
    efficiencyLabel: string;
    efficiency?: string;
    typecode?: string;
    ratedApparentPower?: string;
    ratedCurrent?: string;
    typeDesignation?: string;
    ratedPower?: string;
    technicalParameters?: {
      label: string;
      value?: string;
      unit?: string;
    }[];
    descriptionStr: string;
    description: string;
    speed?: string;
    datarf?: string;
  };
}
interface ProductInfoDataModel {
  fromReportModal?: boolean;
}

export const ProductInfo = (props: IProductInfo) => {
  const { t } = useTranslate();

  let productInfo = props.productInfo;

  return (
    <div id="pdf-section" className="ProductInfoContainer">
      <div className="ProductInfoContainer Header">
        <img
          className="ProductInfoContainer HeaderIcon"
          src={productInfo.icon}
          alt="productInfo icon"
        />
        <div className="ProductInfoContainer HeaderTitle">
          {productInfo.title}
        </div>
        <div className="ProductInfoContainer HeaderSubtext1">
          {productInfo.subText1}
        </div>
        <div className="ProductInfoContainer HeaderSubtext2">
          {productInfo.subText2}
        </div>
      </div>
      <div className="hr" />

      <div
        className="ProductInfoContainer KeyValRow"
        data-rf={productInfo.datarf + '-iesclass'}
      >
        <div className="ProductInfoContainer KeyValKey">
          <img src={productInfo.icon} alt="productinfo icon" />
          {productInfo.ieClassLabel}
        </div>

        <div className="ProductInfoContainer KeyValValue">
          {productInfo.ieClass ?? '-'}
        </div>
      </div>
      <div className="hr" />
      {productInfo.relativeLoss && (
        <>
          <div
            className="ProductInfoContainer KeyValRow"
            data-rf={productInfo.datarf + '-relative-losses'}
          >
            <div className="ProductInfoContainer KeyValKey">
              {t(I18.efficiency_losses_data_relative_losses)}
            </div>
            <div className="ProductInfoContainer KeyValValue">
              {productInfo.relativeLoss}
            </div>
          </div>
          <div className="hr" />
        </>
      )}
      {productInfo.absoluteLoss && (
        <>
          <div
            className="ProductInfoContainer KeyValRow"
            data-rf={productInfo.datarf + '-absolute-losses'}
          >
            <div className="ProductInfoContainer KeyValKey">
              {t(I18.efficiency_losses_data_absolute_losses)}
            </div>
            <div className="ProductInfoContainer KeyValValue">
              {productInfo.absoluteLoss}
            </div>
          </div>
          <div className="hr" />
        </>
      )}
      <div
        className="pds-progressbar"
        data-rf={productInfo.datarf + '-progressbar'}
      >
        <ProgressBar
          label={productInfo.efficiencyLabel}
          progress={productInfo.efficiency}
        />
      </div>
      <div className="hr" />

      {productInfo.typecode && (
        <>
          <div
            className="ProductInfoContainer KeyValRow"
            data-rf={productInfo.datarf + '-typecode'}
          >
            <div className="ProductInfoContainer KeyValKey">
              {t(I18.efficiency_losses_data_cdm_typecode)}
            </div>

            <div className="ProductInfoContainer KeyValValue">
              {productInfo.typecode}
            </div>
          </div>
          <div className="hr" />
        </>
      )}
      {productInfo.ratedApparentPower && (
        <>
          <div
            className="ProductInfoContainer KeyValRow"
            data-rf={productInfo.datarf + '-apparent-power'}
          >
            <div className="ProductInfoContainer KeyValKey">
              {t(I18.efficiency_losses_data_cdm_rated_apparent_power)}
            </div>

            <div className="ProductInfoContainer KeyValValue">
              {productInfo.ratedApparentPower}
            </div>
          </div>
          <div className="hr" />
        </>
      )}
      {productInfo.ratedCurrent && (
        <>
          <div
            className="ProductInfoContainer KeyValRow"
            data-rf={productInfo.datarf + '-rated-current'}
          >
            <div className="ProductInfoContainer KeyValKey">
              {t(I18.efficiency_losses_data_cdm_rated_current)}
            </div>

            <div className="ProductInfoContainer KeyValValue">
              {productInfo.ratedCurrent}
            </div>
          </div>
          <div className="hr" />
        </>
      )}
      {productInfo.typeDesignation && (
        <>
          <div
            className="ProductInfoContainer KeyValRow"
            data-rf={productInfo.datarf + '-type-designation'}
          >
            <div className="ProductInfoContainer KeyValKey">
              {t(I18.efficiency_losses_data_motor_type_designation)}
            </div>

            <div className="ProductInfoContainer KeyValValue">
              {productInfo.typeDesignation}
            </div>
          </div>
          <div className="hr" />
        </>
      )}
      {productInfo.ratedPower && (
        <>
          <div
            className="ProductInfoContainer KeyValRow"
            data-rf={productInfo.datarf + '-rated-power'}
          >
            <div className="ProductInfoContainer KeyValKey">
              {t(I18.efficiency_losses_data_motor_rated_power)}
            </div>

            <div className="ProductInfoContainer KeyValValue">
              {productInfo.ratedPower !== 'undefined undefined'
                ? productInfo.ratedPower
                : '-'}
            </div>
          </div>
          <div className="hr" />
        </>
      )}
      {productInfo.speed && (
        <>
          <div
            className="ProductInfoContainer KeyValRow"
            data-rf={productInfo.datarf + '-speed'}
          >
            <div className="ProductInfoContainer KeyValKey">Speed</div>

            <div className="ProductInfoContainer KeyValValue">
              {productInfo.speed !== 'undefined undefined'
                ? productInfo.speed
                : '-'}
            </div>
          </div>
          <div className="hr" />
        </>
      )}
      <div
        className="ProductInfoContainer Description"
        data-rf={productInfo.datarf + '-description'}
      >
        <strong>{productInfo.descriptionStr + ' '}</strong>
        {productInfo.description}
      </div>
      <div className="hr" />
    </div>
  );
};

const ProductInfoData = ({ fromReportModal }: ProductInfoDataModel) => {
  const { t } = useTranslate();
  const efficiencyData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank];
  });

  const pdsDetails =
    efficiencyData?.pdsEfficiencyDetails?.standardPoints.filter((a) => {
      if (a.speed === '90' && a.torque === '100') return a;
      else return null;
    });
  const pdsData = {
    title: t(I18.efficiency_losses_data_pds_heading),
    subText1: t(I18.efficiency_losses_data_pds_subtext1),
    subText2: t(I18.efficiency_losses_data_pds_subtext2),
    icon: './assets/icon/pdsFrame.svg',
    ieClass: efficiencyData?.pdsEfficiencyDetails?.iesClass.ies,
    ieClassLabel: t(I18.efficiency_losses_data_pds_ieclass_heading),
    efficiencyLabel: t(I18.efficiency_losses_data_pds_efficiency_label),
    efficiency:
      pdsDetails && pdsDetails?.length > 0 ? pdsDetails[0].efficiency : '0',
    description: t(I18.efficiency_losses_data_pds_description),
    descriptionStr: t(I18.efficiency_losses_data_pds_description_strong),
    absoluteLoss:
      pdsDetails && pdsDetails?.length > 0
        ? pdsDetails[0].absoluteLoss + ' ' + pdsDetails[0].absoluteLossUnit
        : '0',
    relativeLoss:
      pdsDetails && pdsDetails?.length > 0
        ? pdsDetails[0].relativeLoss + ' ' + pdsDetails[0].relativeLossUnit
        : '0',
    ratedPower:
      efficiencyData?.pdsEfficiencyDetails?.motorTechnicalData?.output +
      ' ' +
      efficiencyData?.pdsEfficiencyDetails?.motorTechnicalData?.outputUnit,
    datarf: 'pds-losses',
  };

  const cdmData = {
    title: t(I18.efficiency_losses_data_cdm_header),
    subText1: t(I18.efficiency_losses_data_cdm_subtext1),
    subText2: t(I18.efficiency_losses_data_cdm_subtext2),
    icon: './assets/icon/Drive-Frame.svg',
    ieClass: efficiencyData?.pdsEfficiencyDetails?.pdsDriveDetails.ieClass,
    ieClassLabel: t(I18.efficiency_losses_data_cdm_ieclass),
    efficiencyLabel: t(I18.efficiency_losses_data_cdm_efficiency_label),
    efficiency:
      efficiencyData?.pdsEfficiencyDetails?.pdsDriveDetails.efficiency,
    description: t(I18.efficiency_losses_data_cdm_description),
    descriptionStr: t(I18.efficiency_losses_data_cdm_description_strong),
    absoluteLoss:
      efficiencyData?.pdsEfficiencyDetails?.pdsDriveDetails.absoluteLoss,
    relativeLoss:
      efficiencyData?.pdsEfficiencyDetails?.pdsDriveDetails.relativeLoss,
    typecode:
      efficiencyData?.pdsEfficiencyDetails?.pdsDriveDetails.driveTypeCode,
    ratedApparentPower:
      efficiencyData?.pdsEfficiencyDetails?.pdsDriveDetails
        .nominalApparentPower,
    ratedCurrent:
      efficiencyData?.pdsEfficiencyDetails?.pdsDriveDetails.nominalCurrent,
    datarf: 'cdm-losses',
  };
  const motorData = {
    title: t(I18.efficiency_losses_data_motor_header),
    subText1: t(I18.efficiency_losses_data_motor_subtext1),
    subText2: t(I18.efficiency_losses_data_motor_subtext2),
    icon: './assets/icon/Motor-Frame.svg',
    ieClass:
      efficiencyData?.pdsEfficiencyDetails?.motorTechnicalData?.efficiencyClass,
    ieClassLabel: t(I18.efficiency_losses_data_motor_ieclass),
    efficiencyLabel: t(I18.efficiency_losses_data_motor_efficiency_label),
    efficiency:
      efficiencyData?.pdsEfficiencyDetails?.motorTechnicalData?.efficiency,
    description: t(I18.efficiency_losses_data_motor_description),
    descriptionStr: t(I18.efficiency_losses_data_motor_description_strong),
    speed:
      efficiencyData?.pdsEfficiencyDetails?.motorTechnicalData?.speed +
      ' ' +
      efficiencyData?.pdsEfficiencyDetails?.motorTechnicalData?.speedUnit,
    typeDesignation:
      efficiencyData?.pdsEfficiencyDetails?.motorTechnicalData?.typeDesignation,
    ratedPower:
      efficiencyData?.pdsEfficiencyDetails?.motorTechnicalData?.output +
      ' ' +
      efficiencyData?.pdsEfficiencyDetails?.motorTechnicalData?.outputUnit,
    datarf: 'motor-losses',
  };

  return (
    <div className="efficiency-losses-data">
      <div>
        {!fromReportModal
          ? t(I18.efficiency_losses_data_heading)
          : 'Efficiency Report'}
      </div>
      <div className="disp-flex">
        <ProductInfo productInfo={pdsData} />
        <ProductInfo productInfo={cdmData} />
        <ProductInfo productInfo={motorData} />
      </div>
    </div>
  );
};
export default ProductInfoData;
