import { I18 } from '../../../languages/I18';
import { useTranslate } from '../../../i18translate/Hooks';
import './styles/styles.scss';
import { RootState } from '../../../store/rootReducer';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import BOMDetails from './BOMDetails';
import ProjectInformation from './ProjectInformation';

const BOMReport = () => {
  const { t } = useTranslate();
  const [URLSearchParams] = useSearchParams();
  const reportData = useSelector((state: RootState) => {
    return state.workspace;
  });

  const projectData = useSelector((state: RootState) => state.home.projectData);

  const hasPrinted = useRef(false);
  useEffect(() => {
    if (URLSearchParams.get('mode') === 'print' && !hasPrinted.current) {
      hasPrinted.current = true;
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }, []);

  return (
    <div id="report-section" className="report-section">
      {/* branding section */}
      {/* ABB logo */}
      <div className="brand-logo">
        <img src="/assets/icon/abb-logo-default.svg"></img>
      </div>
      <div className="brand-color-bar"></div>
      <div className="report-header">{t(I18.report_header)}</div>
      <div className="report-project">
        {URLSearchParams.get('projectName') ?? reportData.reportName === ''
          ? projectData.projectName
          : reportData.reportName}
      </div>
      <div className="report-project-list">Bill of Materials</div>

      {/* user commentes section */}
      <div className="user-comments">
        {(URLSearchParams.get('projectComments') ||
          reportData.reportComments !== '') && (
          <div>
            <div className="user-comments-title">User comments</div>
            <div className="user-comments-content">
              {URLSearchParams.get('projectComments') ??
                reportData.reportComments}
            </div>
          </div>
        )}
      </div>

      {/* project information section */}
      <ProjectInformation />

      <BOMDetails />
    </div>
  );
};

export default BOMReport;
