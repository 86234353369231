/* ABB Oy, Drives CONFIDENTIAL
ABB Oy, Drives.
All Rights Reserved.
NOTICE: All information contained herein is, and remains the property of ABB Oy, Drives.
The intellectual and technical concepts contained herein are proprietary to ABB Oy, Drives and
its suppliers.
Dissemination of this information or reproduction of this material is strictly forbidden unless
prior written permission is obtained from ABB Oy, Drives.*/

import { useState, useEffect } from 'react';

const useDeviceDetect = () => {
  const checkForMobileDevice = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 767.98) {
      return true;
    } else {
      return false;
    }
  };

  const checkForTabletDevice = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth > 767.98 && windowWidth < 1199.98) {
      return true;
    } else {
      return false;
    }
  };

  const checkForDesktopDevice = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth > 1199.98) {
      return true;
    } else {
      return false;
    }
  };

  const [isMobile, setIsMobile] = useState(checkForMobileDevice());
  const [isTablet, setIsTablet] = useState(checkForTabletDevice());
  const [isDesktop, setIsDesktop] = useState(checkForDesktopDevice());

  useEffect(() => {
    const handlePageResized = () => {
      setIsMobile(checkForMobileDevice);
      setIsTablet(checkForTabletDevice);
      setIsDesktop(checkForDesktopDevice);
    };

    window.addEventListener('resize', handlePageResized);
    window.addEventListener('orientationchange', handlePageResized);
    window.addEventListener('load', handlePageResized);
    window.addEventListener('reload', handlePageResized);

    return () => {
      window.removeEventListener('resize', handlePageResized);
      window.removeEventListener('orientationchange', handlePageResized);
      window.removeEventListener('load', handlePageResized);
      window.removeEventListener('reload', handlePageResized);
    };
  }, []);

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};

export default useDeviceDetect;
