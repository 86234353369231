import { CommonuxAbbStripe } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';

interface AbbHeaderProps extends JSX.CommonuxAbbStripe {
  className?: string;
}

function AbbHeader(props: PropsWithChildren<AbbHeaderProps>) {
  return <CommonuxAbbStripe {...props}>{props?.children}</CommonuxAbbStripe>;
}

export default AbbHeader;
