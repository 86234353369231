import { CommonuxSlider } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';

interface SliderProps extends JSX.CommonuxSlider {
  className?: string;
  disabled?: boolean;
}

function Slider(props: SliderProps) {
  return (
    <CommonuxSlider
      className={
        !props.disabled ? 'primary-black' : 'slider-diabled primary-black'
      }
      {...props}
    ></CommonuxSlider>
  );
}

export default Slider;
