import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { getCountryForTimezone } from 'countries-and-timezones';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
TimeAgo.addDefaultLocale(en);

export const timeAgo = new TimeAgo('en-US');

export const generateKey = (pre: string | number) => {
  return `${pre}_${new Date().getTime()}`;
};

/**
 * Get Current Country Information
 */
export const GetCurrentCountry = () => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const country = getCountryForTimezone(tz);
  return {
    id: country?.id,
    idLowerCase: country?.id.toLocaleLowerCase(),
    country: country?.name,
  };
};

export type ErrorData = FetchBaseQueryError | SerializedError;

export function getDataAndStatusCode(errorData: ErrorData): {
  data: unknown;
  status:
    | number
    | 'FETCH_ERROR'
    | 'PARSING_ERROR'
    | 'TIMEOUT_ERROR'
    | 'CUSTOM_ERROR';
} {
  if ('errorMotorSelection' in errorData) {
    return { data: errorData.errorMotorSelection, status: 0 };
  } else if ('errorDriveSelection' in errorData) {
    return { data: errorData.errorDriveSelection, status: 0 };
  } else {
    return { data: null, status: 0 };
  }
}

export function roundDecimal(value: number): number {
  return value < 0.8 ? parseFloat(value.toFixed(3)) : Math.ceil(value);
}

export const GetNameConverted = (name: string) => {
  if (name.length <= 7) {
    return name;
  } else {
    return name.slice(0, 4) + '...' + name.slice(-3);
  }
};
