import '../Styles/styles.scss';
import Slider from '../../../../components/Slider';
import Input from '../../../../components/Form/Input';
import { useEffect, useState } from 'react';

interface SliderWithInputProps {
  minValue: number;
  maxValue: number;
  sliderVal: number;
  disabled?: boolean;
  inputValue:number;
  unit:string;
  unitChange:boolean;
  onChange: (event: number) => void;
}

const SliderWithInput = (props: SliderWithInputProps) => {
  const [value, setValue] = useState(props.sliderVal);
  const onSliderValChange =(e: CustomEvent<any>) => {
    if(e.detail.value !== props.inputValue){
      if(e.detail.value > props.maxValue){
          if(props.unitChange === false){
          setValue(props.inputValue);
        }
        else{
        props.onChange(props.maxValue);
        setValue(props.maxValue);
        }
      } 
      else if(e.detail.value < props.minValue){
        if(props.unitChange === false){
          setValue(props.inputValue);
        }
        else{
          props.onChange(props.minValue);
          setValue(props.minValue);
        }
      }     
      else{      
        props.onChange(e.detail.value);
        setValue(e.detail.value);
      }
    }
  };

  useEffect(()=>{
    setValue(props.inputValue);
  },[props.inputValue]);

  return (
    <div className="disp-flex">
      <div className="sliderWithInput-container">
        <Slider
          type="primary-black"
          minValue={props.minValue}
          maxValue={props.maxValue}
          doubleHandle={false}
          disabled={props.disabled}
          onLowerKnobChange={(e) => onSliderValChange(e)}
          lowerRange={value}
          unit={props.unit}
        />
      </div>

      <div className='custom-input-with-suffix'>
          <Input
            className="slider-input"
            inputLimit={props.maxValue}
            size="xsmall"
            type="primary-black"
            inputType="number"
            value={value.toString()}
            onInputChange={(e) => onSliderValChange(e)}
            disabled={props.disabled}
            valid={true}
            suffixText={props.unit}
          />
      </div>
      
    </div>
  );
};

export default SliderWithInput;
