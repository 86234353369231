import { useState, useEffect } from 'react';

const useInput = (
  validateValue: (value: string) => boolean,
  defaultValue: string
) => {
  const [enteredValue, setEnteredValue] = useState(defaultValue ?? '');
  const [isTouched, setIsTouched] = useState(false);

  const valueIsValid = validateValue(enteredValue);
  const hasError = !valueIsValid && isTouched;

  const valueChangedHandler = (newValue: string) => {
    setEnteredValue(newValue);
  };

  const inputBlurHandler = () => {
    setIsTouched(true);
  };
  useEffect(() => {
    setEnteredValue(defaultValue);
  }, [defaultValue]);

  return {
    value: enteredValue,
    isValid: valueIsValid,
    hasError,
    valueChangedHandler,
    inputBlurHandler,
  };
};

export default useInput;
