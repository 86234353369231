/* ABB Oy, Drives CONFIDENTIAL
ABB Oy, Drives.
All Rights Reserved.
NOTICE: All information contained herein is, and remains the property of ABB Oy, Drives.
The intellectual and technical concepts contained herein are proprietary to ABB Oy, Drives and
its suppliers.
Dissemination of this information or reproduction of this material is strictly forbidden unless
prior written permission is obtained from ABB Oy, Drives.*/

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
let appInsights: ApplicationInsights;
if (process.env.REACT_APP_AI_INSTRUMENTATIONKEY) {
  appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.REACT_APP_AI_INSTRUMENTATIONKEY,
      isCookieUseDisabled: true,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
      enableAutoRouteTracking: false,
      disableFetchTracking: true,
    },
  });
  appInsights.loadAppInsights(false);
}

export { reactPlugin, appInsights };
