import {
  LoadDataDriveSelection,
  LoadDataMotorSelection,
} from '../models/DutyCycleModels';

export const calculateCoordinates = (
  yValues: number[],
  timeValues: number[],
  rows: LoadDataMotorSelection[] | LoadDataDriveSelection[]
) => {
  const newCoordinates: number[][] = [];
  let totalTime = 0;
  let pulse = 1800 / timeValues?.reduce((sum, p) => sum + p);
  pulse = Math.ceil(pulse);
  totalTime = timeValues[0];
  newCoordinates.push([0, yValues[0]], [totalTime, yValues[0]]);
  for (let k = 1; k <= pulse; k++) {
    newCoordinates.push(
      [totalTime, yValues[1]],
      [totalTime + timeValues[1], yValues[1]]
    );
    if (rows.length > 2) {
      newCoordinates.push(
        [totalTime + timeValues[1], yValues[2]],
        [totalTime + timeValues[1] + timeValues[2], yValues[2]]
      );
      if (rows.length > 3) {
        totalTime = totalTime + timeValues[1] + timeValues[2];
        newCoordinates.push(
          [totalTime, yValues[3]],
          [totalTime + timeValues[3], yValues[3]]
        );
        if (rows.length > 4) {
          totalTime = totalTime + timeValues[3];
          newCoordinates.push(
            [totalTime, yValues[4]],
            [totalTime + timeValues[4], yValues[4]]
          );
          if (rows.length > 5) {
            totalTime = totalTime + timeValues[4];
            newCoordinates.push(
              [totalTime, yValues[5]],
              [totalTime + timeValues[5], yValues[5]]
            );
            if (rows.length > 6) {
              totalTime = totalTime + timeValues[5];
              newCoordinates.push(
                [totalTime, yValues[6]],
                [totalTime + timeValues[6], yValues[6]]
              );
              if (rows.length > 7) {
                totalTime = totalTime + timeValues[6];
                newCoordinates.push(
                  [totalTime, yValues[7]],
                  [totalTime + timeValues[7], yValues[7]]
                );
                if (rows.length > 8) {
                  totalTime = totalTime + timeValues[7];
                  newCoordinates.push(
                    [totalTime, yValues[8]],
                    [totalTime + timeValues[8], yValues[8]]
                  );
                  if (rows.length > 9) {
                    totalTime = totalTime + timeValues[8];
                    newCoordinates.push(
                      [totalTime, yValues[9]],
                      [totalTime + timeValues[9], yValues[9]]
                    );
                    if (rows.length > 10) {
                      totalTime = totalTime + timeValues[9];
                      newCoordinates.push(
                        [totalTime, yValues[10]],
                        [totalTime + timeValues[10], yValues[10]],
                        [totalTime + timeValues[10], yValues[0]],
                        [totalTime + timeValues[10] + timeValues[0], yValues[0]]
                      );
                      totalTime = totalTime + timeValues[10] + timeValues[0];
                    } else {
                      newCoordinates.push(
                        [totalTime + timeValues[9], yValues[0]],
                        [totalTime + timeValues[9] + timeValues[0], yValues[0]]
                      );
                      totalTime = totalTime + timeValues[9] + timeValues[0];
                    }
                  } else {
                    newCoordinates.push(
                      [totalTime + timeValues[8], yValues[0]],
                      [totalTime + timeValues[8] + timeValues[0], yValues[0]]
                    );
                    totalTime = totalTime + timeValues[8] + timeValues[0];
                  }
                } else {
                  newCoordinates.push(
                    [totalTime + timeValues[7], yValues[0]],
                    [totalTime + timeValues[7] + timeValues[0], yValues[0]]
                  );
                  totalTime = totalTime + timeValues[7] + timeValues[0];
                }
              } else {
                newCoordinates.push(
                  [totalTime + timeValues[6], yValues[0]],
                  [totalTime + timeValues[6] + timeValues[0], yValues[0]]
                );
                totalTime = totalTime + timeValues[6] + timeValues[0];
              }
            } else {
              newCoordinates.push(
                [totalTime + timeValues[5], yValues[0]],
                [totalTime + timeValues[5] + timeValues[0], yValues[0]]
              );
              totalTime = totalTime + timeValues[5] + timeValues[0];
            }
          } else {
            newCoordinates.push(
              [totalTime + timeValues[4], yValues[0]],
              [totalTime + timeValues[4] + timeValues[0], yValues[0]]
            );
            totalTime = totalTime + timeValues[4] + timeValues[0];
          }
        } else {
          newCoordinates.push(
            [totalTime + timeValues[3], yValues[0]],
            [totalTime + timeValues[3] + timeValues[0], yValues[0]]
          );
          totalTime = totalTime + timeValues[3] + timeValues[0];
        }
      } else {
        newCoordinates.push(
          [totalTime + timeValues[1] + timeValues[2], yValues[0]],
          [
            totalTime + timeValues[1] + timeValues[2] + timeValues[0],
            yValues[0],
          ]
        );
        totalTime = totalTime + timeValues[1] + timeValues[2] + timeValues[0];
      }
    } else {
      newCoordinates.push(
        [totalTime + timeValues[1], yValues[0]],
        [totalTime + timeValues[1] + timeValues[0], yValues[0]]
      );
      totalTime = totalTime + timeValues[1] + timeValues[0];
    }
  }
  return newCoordinates;
};
