import { CommonuxCard } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';

interface CardProps extends JSX.CommonuxCard {
  className?: string;
  slot?: string;
  onClick?: () => void;
}

function Card(props: PropsWithChildren<CardProps>) {
  const { onClick } = props;

  return (
    <CommonuxCard onCardClick={onClick} {...props}>
      {props?.children}
    </CommonuxCard>
  );
}

export default Card;
