import { CommonuxSegmentedControlItem } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';

interface CommonuxButtonGroupItemProps
  extends JSX.CommonuxSegmentedControlItem {
  className?: string;
  id?: string;
  buttonGroupItemId?: any;
}

const ButtonGroupItemCommonux = (
  props: PropsWithChildren<CommonuxButtonGroupItemProps>
) => {
  return (
    <CommonuxSegmentedControlItem {...props}>
      {props?.children}
    </CommonuxSegmentedControlItem>
  );
};

export default ButtonGroupItemCommonux;
