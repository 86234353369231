import { CommonuxTabContent } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';

interface TabContentProps extends JSX.CommonuxTabContent {
  className?: string;
  id?: string;
}

const TabContent = (props: PropsWithChildren<TabContentProps>) => {
  return <CommonuxTabContent {...props}>{props?.children}</CommonuxTabContent>;
};

export default TabContent;
