import { CommonuxSwitch } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';

interface ToggleSwitchProps extends JSX.CommonuxSwitch {
  className?: string;
}

function ToggleSwitch(props: PropsWithChildren<ToggleSwitchProps>) {
  return <CommonuxSwitch {...props}>{props?.children}</CommonuxSwitch>;
}

export default ToggleSwitch;
