import { MouseEventHandler, PropsWithChildren } from 'react';
import './../../styles/ButtonGroup.scss';

interface ButtonGroupProps {
  className?: string;
  onLeftClick?: (selectedOption: string) => void;
  onMiddleClick?: (selectedOption: string) => void;
  onRightClick?: MouseEventHandler<any> | undefined;
  leftButtonName?: string;
  middleButtonName?: string;
  rightButtonName?: string;
  isLeftBtnDisabled: boolean;
  isMiddleBtnDisabled: boolean;
  isRightBtnDisabled: boolean;
  isSidebarOpen?: boolean;
  isLoading?: boolean;
}

function ButtonGroup(props: PropsWithChildren<ButtonGroupProps>) {
  const handleLeftClick = () => {
    props.onLeftClick?.(props.leftButtonName ?? '');
  };
  const handleMiddleClick = () => {
    props.onMiddleClick?.(props.middleButtonName ?? '');
  };
  return (
    <div className="button-container-item">
      <div
        className={`button-group-item ${
          !props.isSidebarOpen
            ? 'button-group-item-sidebar-closed'
            : 'button-group-item-sidebar-open'
        }`}
      >
        <button
          className={`left-button button-group-item-common ${
            props.isLeftBtnDisabled || props.isLoading ? 'disabled' : ''
          }`}
          onClick={handleLeftClick}
          disabled={props.isLoading || props.isLeftBtnDisabled}
        >
          {props.leftButtonName}
        </button>
        <span className="dividers"></span>
        <button
          className={`middle-button button-group-item-common ${
            props.isMiddleBtnDisabled || props.isLoading ? 'disabled' : ''
          }`}
          onClick={handleMiddleClick}
          disabled={props.isLoading || props.isMiddleBtnDisabled}
        >
          {props.middleButtonName}
        </button>
        <span className="dividers"></span>
        <button
          className={`right-button button-group-item-common ${
            props.isRightBtnDisabled || props.isLoading ? 'disabled' : ''
          }`}
          onClick={props.onRightClick}
          disabled={props.isLoading || props.isRightBtnDisabled}
        >
          {props.rightButtonName}
        </button>
      </div>
    </div>
  );
}

export default ButtonGroup;
