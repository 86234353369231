export enum PDSAbsoluteLossRange {
    Min = 0,
    Max = Number.POSITIVE_INFINITY,
}

export enum PDSEfficiencyRange {
    Min = 0,
    Max = 100,
}
export const pdsPartloadMaxValues = {
    speed: 200,
    torque: 150,
};
export enum PDSEfficiencyPoint {
    Speed = 90,
    Torque = 100,
  }