export interface NarrowMenuItemProps {
  type?: 'large' | 'medium';
  value: string | number;
  label: string;
  data: any;
  disabled: boolean;
  onClick?: (value: string | number, data: any) => void;
  dataRf?: string;
}

function NarrowMenuItem(props: NarrowMenuItemProps) {
  const {
    type = 'medium',
    value,
    label,
    data,
    onClick,
    disabled,
    dataRf,
  } = props;
  const handleClick = () => {
    if (onClick && !disabled) onClick(value, data);
  };

  return (
    <li
      className={
        disabled
          ? ' body-1-regular menu-item menu-disabled'
          : `body-1-regular menu-item ${type}`
      }
      onClick={handleClick}
      data-rf={dataRf}
    >
      {label}
    </li>
  );
}

export default NarrowMenuItem;
