import { CommonuxSelectOption } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';

interface SelectOptionProps extends JSX.CommonuxSelectOption {
  className?: string;
  class?: string;
}

function SelectOption(props: PropsWithChildren<SelectOptionProps>) {
  return (
    <CommonuxSelectOption {...props}>{props?.children}</CommonuxSelectOption>
  );
}

export default SelectOption;
