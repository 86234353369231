import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import Button from "../../../../components/Form/Button";

interface ErrorPageProps{
  page:string,
  type:string,
  onClick: () => void;
}

const ErrorPage = (props: ErrorPageProps) => {  
  const { t } = useTranslate();

  return (
    <div className='efficiency-error-page'>
      {
        props.type === "no-data" &&
        <img src="./assets/icon/no-data.svg" alt="no data"/>
      }
      {
         props.type === "retry" &&
        <img src="./assets/icon/warning.svg" alt="no data"/>
      }
        <img />
        <div className='eff-error-no-data'>
          {props.type === "no-data" && t(I18.no_data_available)}
          {props.type === "retry" && t(I18.something_went_wrong)}
          </div>
        <div className='eff-error-no-data-msg'>
        {props.page ==="pds" && props.type === "no-data" &&  t(I18.pds_data_unavailable_message)}
        {props.page ==="motor" && props.type === "no-data" && t(I18.motor_data_unavailable_message)}
        {props.page ==="drive" && props.type === "no-data" && t(I18.drive_data_unavailable_message)}
        {props.type === "retry" && t(I18.retry_info)}
        </div>
        <Button
          type= "secondary"
          size="small"
          onClick={()=> props.onClick()}>
           {props.type === "no-data" && t(I18.goto_selection_button)}
           {props.type === "retry" && t(I18.retry)}
        </Button>
    </div>
  );
};

export default ErrorPage;
