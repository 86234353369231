import { useTranslate } from '../../../../i18translate/Hooks';
import StandardPointsTable from '../../Efficiency/Components/StandardPointsTable';
import { I18 } from '../../../../languages/I18';
import { useEffect } from 'react';
import { MotorInputs } from '../../models/EfficiencyModel';
import ProgressBar from '../../Efficiency/Components/ProgressBar';
import '../../Reports/styles/styles.scss';
import { MotorLossChart } from '../../Efficiency/Components/MotorLossesChart';
import '../../styles/style.scss';

export interface ErrorType {
  error: boolean;
  type: string;
}
const MotorEfficiency = ({ dimensionData, motorEfficiencyData, id }: any) => {
  const { t } = useTranslate();
  const motorData = motorEfficiencyData;

  useEffect(() => {
    let motor =
      dimensionData?.selectMotorResult?.dimension?.dimensionResult
        ?.supplyDriveLoads?.[0]?.motorUnit?.results;
    let mcalcid;

    if (motor && motor?.length > 0) {
      for (let mot of motor) {
        if (mot.name === 'CalcID' || mot.name === 'Calc ID') {
          mcalcid = mot.value;
        }
      }
    }

    if (dimensionData.dimensionDataLoading) {
    } else if (motorData?.motorTechnicalData?.calcID !== mcalcid) {
      let inputs = {} as MotorInputs;
      if (motor && motor?.length > 0) {
        for (let mot of motor) {
          if (mot.name === 'CatNoOriginal') {
            inputs.catno = mot.value;
          }
          if (mot.name === 'CalcID' || mot.name === 'Calc ID') {
            inputs.calcID = mot.value;
          }
          if (mot.name === 'MEPS') {
            inputs.mEPS = mot.value;
          }
          if (mot.name === 'RtdConnection') {
            inputs.rtdConnection = mot.value;
          }
          if (mot.name === 'Frequency ') {
            inputs.frequency = mot.value;
          }
          if (mot.name === 'Voltage ') {
            inputs.rtdVolt = mot.value;
          }
          if (mot.name === 'Power ') {
            inputs.rtdPower = mot.value;
          }
          if (mot.name === 'Ambient') {
            inputs.ambient = mot.value;
          }
          if (mot.name === 'Ambient') {
            inputs.ambient = mot.value;
          }
        }
      }
    }
  }, [dimensionData.dimensionOutputData, dimensionData.dimensionDataLoading]);

  return (
    <>
      {motorData?.motorTechnicalData &&
        motorData?.motorTechnicalData?.typeDesignation && (
          <>
            <div
              data-rf="motor-losses-motor-type-designation"
              className="motor-losses-motor-type-designation"
            >
              {motorData.motorTechnicalData.typeDesignation + ' '}
              {t(I18.efficiency_losses_motor_page_heading)}
            </div>
            <div className="disp-flex efficiency-info-section">
              <div className="half-flex" style={{ width: '50%' }}>
                <div
                  className="ProductInfoContainer KeyValRow "
                  data-rf="motor-losses-ieclass"
                >
                  <div className="ProductInfoContainer KeyValKey fs-16">
                    <img src="./assets/icon/motor-32.svg" />
                    {t(I18.efficiency_losses_data_motor_ieclass)}
                  </div>
                  <div className="ProductInfoContainer KeyValValue fs-16">
                    {motorData.motorTechnicalData.efficiencyClass}
                  </div>
                </div>

                <div className="hr" />

                <div data-rf="motor-losses-progress-bar">
                  <ProgressBar
                    bigText={true}
                    label={'Motor efficiency with VSD supply'}
                    progress={motorData.motorTechnicalData.efficiency}
                  />
                </div>
                <div className="hr mt-10" />
              </div>
              <div className="half-flex ml-10" style={{ width: '50%' }}>
                <strong>
                  {t(I18.efficiency_losses_motor_page_side_note_strong) + ' '}
                </strong>
                {t(I18.efficiency_losses_motor_page_side_note)}
              </div>
            </div>
            <div
              className="efficiency-page-headers operating-points-table"
              data-rf="motor-losses-table-heading"
            >
              {t(I18.efficiency_losses_motor_page_table_header)}
            </div>
            <StandardPointsTable data={motorData.motorStandardData} />

            <div className="pds-charts-container" id="pdf-section">
              <div
                className="efficiency-page-headers mt-40"
                data-rf="motor-losses-chart-heading"
              >
                {t(I18.efficiency_losses_motor_page_graph_header)}
              </div>
              <MotorLossChart
                height={220}
                loading={false}
                type={'large'}
                standardPoints={motorData.motorStandardData}
                id={id}
              />
            </div>
          </>
        )}
    </>
  );
};

export default MotorEfficiency;
