import { useSelector } from 'react-redux';
import '../../styles/style.scss';
import { CommonuxLoadingIndicator } from '@abb-motion-ux/commonux-web-components-react';
import { RootState } from '../../../../store/rootReducer';

const ProgressBar = (props: any) => {
  const isModalOpen = useSelector(
    (state: RootState) => state.workspace.isReportModalOpen
  );

  const progress = props?.progress?.toString().includes('%')
    ? props.progress
    : props.progress + '%';

  const widthofProgress = () =>
    props?.progress ? 100 - parseInt(props.progress) : '-';

  return (
    <div className="progressbar-container">
      <div className="progressbar-header">
        <span className={props.bigText && 'bigText'}>{props.label}</span>
        <span className={props.bigText && 'bigText'}>
          {props?.progress ? progress : '-'}
        </span>
      </div>
      {!isModalOpen ? (
        <div className="progressbar-content">
          <CommonuxLoadingIndicator
            indicatorType="linear"
            className="progress"
            type="primary-black"
            progressValue={progress}
            size="medium"
            progressType="determinate"
          ></CommonuxLoadingIndicator>
        </div>
      ) : (
        <div style={{ display: '-webkit-flex', width: '100%' }}>
          {props?.progress ? (
            <>
              <div
                style={{
                  width: `${parseInt(props?.progress)}%`,
                  background: '#0ca919',
                  height: '8px',
                }}
              />
              <div
                style={{
                  width: `${widthofProgress()}%`,
                  backgroundColor: '#dbdbdb',
                  height: '8px',
                }}
              />
            </>
          ) : (
            <div
              style={{
                width: `100%`,
                backgroundColor: '#dbdbdb',
                height: '8px',
              }}
            />
          )}
        </div>
      )}

      <div className="progressbar-footer" style={{ width: '100%' }}>
        <p
          className={props.bigText && 'bigText'}
          style={{ display: 'inline', width: '100%', margin: 0 }}
        >
          35%
        </p>
        <p
          className={props.bigText && 'bigText'}
          style={{
            width: '100%',
            textAlign: 'right',
            display: 'inline',
            margin: 0,
          }}
        >
          100%
        </p>
      </div>
    </div>
  );
};

export default ProgressBar;
